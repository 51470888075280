import './main.css';
import '../.tailwind-gen.css';
import { Elm } from './Main.elm';
import * as serviceWorker from './serviceWorker';


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


const node = document.getElementById('root');
const app = Elm.Main.init({ flags: 0, node: node });


window.addEventListener('load', function () {
  if (typeof window.ethereum === 'undefined') {
    console.log('No wallet detected');
    return;
  }

  const sendModel = () => {
    const address = ethereum.selectedAddress;
    console.log('Connected to ', address, ' on network ', window.ethereum.networkVersion);
    app.ports.connectionChanged.send(!address ? null : {
      netId: parseInt(window.ethereum.networkVersion),
      address: ethereum.selectedAddress,
    });
  }

  sendModel();
  ethereum.on('accountsChanged', sendModel);
  ethereum.on('chainChanged', () => window.location.reload());

  app.ports.txOut.subscribe(function (txData) {
    const err = e => {
      console.log(e);
      app.ports.txIn.send({ ref: txData.ref, txHash: null });
    }
    try {
      console.log('Sending tx', txData)
      ethereum.request({
        method: 'eth_sendTransaction',
        params: [{
          from: ethereum.selectedAddress,
          ...txData.txParams
        }],
      }).then(r => {
        app.ports.txIn.send({ ref: txData.ref, txHash: r });
      }, err);
    } catch (e) {
      err(e);
    }
  });

  ethereum.enable();
});
