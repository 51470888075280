(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.b$.ee === region.cp.ee)
	{
		return 'on line ' + region.b$.ee;
	}
	return 'on lines ' + region.b$.ee + ' through ' + region.cp.ee;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.d9,
		impl.eV,
		impl.eL,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_enqueueEffects(managers, result.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		cJ: func(record.cJ),
		b1: record.b1,
		bY: record.bY
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.cJ;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.b1;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.bY) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.d9,
		impl.eV,
		impl.eL,
		function(sendToApp, initialModel) {
			var view = impl.eX;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.d9,
		impl.eV,
		impl.eL,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.b_ && impl.b_(sendToApp)
			var view = impl.eX;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.dG);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.eN) && (_VirtualDom_doc.title = title = doc.eN);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.es;
	var onUrlRequest = impl.et;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		b_: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.c2 === next.c2
							&& curr.cA === next.cA
							&& curr.cZ.a === next.cZ.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		d9: function(flags)
		{
			return A3(impl.d9, flags, _Browser_getUrl(), key);
		},
		eX: impl.eX,
		eV: impl.eV,
		eL: impl.eL
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { d3: 'hidden', dK: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { d3: 'mozHidden', dK: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { d3: 'msHidden', dK: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { d3: 'webkitHidden', dK: 'webkitvisibilitychange' }
		: { d3: 'hidden', dK: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		dc: _Browser_getScene(),
		dx: {
			dz: _Browser_window.pageXOffset,
			dA: _Browser_window.pageYOffset,
			dy: _Browser_doc.documentElement.clientWidth,
			cy: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		dy: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		cy: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			dc: {
				dy: node.scrollWidth,
				cy: node.scrollHeight
			},
			dx: {
				dz: node.scrollLeft,
				dA: node.scrollTop,
				dy: node.clientWidth,
				cy: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			dc: _Browser_getScene(),
			dx: {
				dz: x,
				dA: y,
				dy: _Browser_doc.documentElement.clientWidth,
				cy: _Browser_doc.documentElement.clientHeight
			},
			dX: {
				dz: x + rect.left,
				dA: y + rect.top,
				dy: rect.width,
				cy: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}


// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.eo) { flags += 'm'; }
	if (options.dJ) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.an.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.an.b, xhr)); });
		$elm$core$Maybe$isJust(request.dr) && _Http_track(router, xhr, request.dr.a);

		try {
			xhr.open(request.ej, request.eW, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.eW));
		}

		_Http_configureRequest(xhr, request);

		request.dG.a && xhr.setRequestHeader('Content-Type', request.dG.a);
		xhr.send(request.dG.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.d2; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.eM.a || 0;
	xhr.responseType = request.an.d;
	xhr.withCredentials = request.dE;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		eW: xhr.responseURL,
		eI: xhr.status,
		eJ: xhr.statusText,
		d2: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			eE: event.loaded,
			eG: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			ey: event.loaded,
			eG: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}


var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});


// BYTES

function _Bytes_width(bytes)
{
	return bytes.byteLength;
}

var _Bytes_getHostEndianness = F2(function(le, be)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(new Uint8Array(new Uint32Array([1]))[0] === 1 ? le : be));
	});
});


// ENCODERS

function _Bytes_encode(encoder)
{
	var mutableBytes = new DataView(new ArrayBuffer($elm$bytes$Bytes$Encode$getWidth(encoder)));
	$elm$bytes$Bytes$Encode$write(encoder)(mutableBytes)(0);
	return mutableBytes;
}


// SIGNED INTEGERS

var _Bytes_write_i8  = F3(function(mb, i, n) { mb.setInt8(i, n); return i + 1; });
var _Bytes_write_i16 = F4(function(mb, i, n, isLE) { mb.setInt16(i, n, isLE); return i + 2; });
var _Bytes_write_i32 = F4(function(mb, i, n, isLE) { mb.setInt32(i, n, isLE); return i + 4; });


// UNSIGNED INTEGERS

var _Bytes_write_u8  = F3(function(mb, i, n) { mb.setUint8(i, n); return i + 1 ;});
var _Bytes_write_u16 = F4(function(mb, i, n, isLE) { mb.setUint16(i, n, isLE); return i + 2; });
var _Bytes_write_u32 = F4(function(mb, i, n, isLE) { mb.setUint32(i, n, isLE); return i + 4; });


// FLOATS

var _Bytes_write_f32 = F4(function(mb, i, n, isLE) { mb.setFloat32(i, n, isLE); return i + 4; });
var _Bytes_write_f64 = F4(function(mb, i, n, isLE) { mb.setFloat64(i, n, isLE); return i + 8; });


// BYTES

var _Bytes_write_bytes = F3(function(mb, offset, bytes)
{
	for (var i = 0, len = bytes.byteLength, limit = len - 4; i <= limit; i += 4)
	{
		mb.setUint32(offset + i, bytes.getUint32(i));
	}
	for (; i < len; i++)
	{
		mb.setUint8(offset + i, bytes.getUint8(i));
	}
	return offset + len;
});


// STRINGS

function _Bytes_getStringWidth(string)
{
	for (var width = 0, i = 0; i < string.length; i++)
	{
		var code = string.charCodeAt(i);
		width +=
			(code < 0x80) ? 1 :
			(code < 0x800) ? 2 :
			(code < 0xD800 || 0xDBFF < code) ? 3 : (i++, 4);
	}
	return width;
}

var _Bytes_write_string = F3(function(mb, offset, string)
{
	for (var i = 0; i < string.length; i++)
	{
		var code = string.charCodeAt(i);
		offset +=
			(code < 0x80)
				? (mb.setUint8(offset, code)
				, 1
				)
				:
			(code < 0x800)
				? (mb.setUint16(offset, 0xC080 /* 0b1100000010000000 */
					| (code >>> 6 & 0x1F /* 0b00011111 */) << 8
					| code & 0x3F /* 0b00111111 */)
				, 2
				)
				:
			(code < 0xD800 || 0xDBFF < code)
				? (mb.setUint16(offset, 0xE080 /* 0b1110000010000000 */
					| (code >>> 12 & 0xF /* 0b00001111 */) << 8
					| code >>> 6 & 0x3F /* 0b00111111 */)
				, mb.setUint8(offset + 2, 0x80 /* 0b10000000 */
					| code & 0x3F /* 0b00111111 */)
				, 3
				)
				:
			(code = (code - 0xD800) * 0x400 + string.charCodeAt(++i) - 0xDC00 + 0x10000
			, mb.setUint32(offset, 0xF0808080 /* 0b11110000100000001000000010000000 */
				| (code >>> 18 & 0x7 /* 0b00000111 */) << 24
				| (code >>> 12 & 0x3F /* 0b00111111 */) << 16
				| (code >>> 6 & 0x3F /* 0b00111111 */) << 8
				| code & 0x3F /* 0b00111111 */)
			, 4
			);
	}
	return offset;
});


// DECODER

var _Bytes_decode = F2(function(decoder, bytes)
{
	try {
		return $elm$core$Maybe$Just(A2(decoder, bytes, 0).b);
	} catch(e) {
		return $elm$core$Maybe$Nothing;
	}
});

var _Bytes_read_i8  = F2(function(      bytes, offset) { return _Utils_Tuple2(offset + 1, bytes.getInt8(offset)); });
var _Bytes_read_i16 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 2, bytes.getInt16(offset, isLE)); });
var _Bytes_read_i32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getInt32(offset, isLE)); });
var _Bytes_read_u8  = F2(function(      bytes, offset) { return _Utils_Tuple2(offset + 1, bytes.getUint8(offset)); });
var _Bytes_read_u16 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 2, bytes.getUint16(offset, isLE)); });
var _Bytes_read_u32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getUint32(offset, isLE)); });
var _Bytes_read_f32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getFloat32(offset, isLE)); });
var _Bytes_read_f64 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 8, bytes.getFloat64(offset, isLE)); });

var _Bytes_read_bytes = F3(function(len, bytes, offset)
{
	return _Utils_Tuple2(offset + len, new DataView(bytes.buffer, bytes.byteOffset + offset, len));
});

var _Bytes_read_string = F3(function(len, bytes, offset)
{
	var string = '';
	var end = offset + len;
	for (; offset < end;)
	{
		var byte = bytes.getUint8(offset++);
		string +=
			(byte < 128)
				? String.fromCharCode(byte)
				:
			((byte & 0xE0 /* 0b11100000 */) === 0xC0 /* 0b11000000 */)
				? String.fromCharCode((byte & 0x1F /* 0b00011111 */) << 6 | bytes.getUint8(offset++) & 0x3F /* 0b00111111 */)
				:
			((byte & 0xF0 /* 0b11110000 */) === 0xE0 /* 0b11100000 */)
				? String.fromCharCode(
					(byte & 0xF /* 0b00001111 */) << 12
					| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 6
					| bytes.getUint8(offset++) & 0x3F /* 0b00111111 */
				)
				:
				(byte =
					((byte & 0x7 /* 0b00000111 */) << 18
						| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 12
						| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 6
						| bytes.getUint8(offset++) & 0x3F /* 0b00111111 */
					) - 0x10000
				, String.fromCharCode(Math.floor(byte / 0x400) + 0xD800, byte % 0x400 + 0xDC00)
				);
	}
	return _Utils_Tuple2(offset, string);
});

var _Bytes_decodeFailure = F2(function() { throw 0; });


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}


function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$List$cons = _List_cons;
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.f) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.g),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.g);
		} else {
			var treeLen = builder.f * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.h) : builder.h;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.f);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.g) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.g);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{h: nodeList, f: (len / $elm$core$Array$branchFactor) | 0, g: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {cv: fragment, cA: host, cW: path, cZ: port_, c2: protocol, c3: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$element = _Browser_element;
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $author$project$Main$init = function (_v0) {
	return _Utils_Tuple2(
		{B: $elm$core$Maybe$Nothing},
		$elm$core$Platform$Cmd$none);
};
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $author$project$Main$ConnectedMsg = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Main$ConnectionChanged = function (a) {
	return {$: 0, a: a};
};
var $author$project$Contracts$LegoV1 = 1;
var $author$project$Contracts$Puzzle = 0;
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$field = _Json_decodeField;
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Ports$connectionChanged = _Platform_incomingPort(
	'connectionChanged',
	$elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2(
				$elm$json$Json$Decode$map,
				$elm$core$Maybe$Just,
				A2(
					$elm$json$Json$Decode$andThen,
					function (netId) {
						return A2(
							$elm$json$Json$Decode$andThen,
							function (address) {
								return $elm$json$Json$Decode$succeed(
									{dD: address, ep: netId});
							},
							A2($elm$json$Json$Decode$field, 'address', $elm$json$Json$Decode$string));
					},
					A2($elm$json$Json$Decode$field, 'netId', $elm$json$Json$Decode$int)))
			])));
var $elm$core$Platform$Sub$map = _Platform_map;
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $author$project$Connected$LiquidatorCmd = function (a) {
	return {$: 1, a: a};
};
var $cmditch$elm_ethereum$Eth$Sentry$Tx$ErrorDecoding = function (a) {
	return {$: 5, a: a};
};
var $cmditch$elm_ethereum$Eth$Sentry$Tx$TxSigned = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $elm$json$Json$Decode$fail = _Json_fail;
var $cmditch$elm_ethereum$Eth$Decode$resultToDecoder = function (strToResult) {
	var convert = function (n) {
		var _v0 = strToResult(n);
		if (!_v0.$) {
			var val = _v0.a;
			return $elm$json$Json$Decode$succeed(val);
		} else {
			var error = _v0.a;
			return $elm$json$Json$Decode$fail(error);
		}
	};
	return A2($elm$json$Json$Decode$andThen, convert, $elm$json$Json$Decode$string);
};
var $cmditch$elm_ethereum$Internal$Types$TxHash = $elm$core$Basics$identity;
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {d8: index, eh: match, cP: number, eK: submatches};
	});
var $elm$regex$Regex$contains = _Regex_contains;
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{dJ: false, eo: false},
		string);
};
var $elm$regex$Regex$never = _Regex_never;
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $cmditch$elm_ethereum$Eth$Utils$isSha256 = $elm$regex$Regex$contains(
	A2(
		$elm$core$Maybe$withDefault,
		$elm$regex$Regex$never,
		$elm$regex$Regex$fromString('^((0[Xx]){1})?[0-9a-fA-F]{64}$')));
var $cmditch$elm_ethereum$Eth$Utils$quote = function (str) {
	return '\"' + (str + '\"');
};
var $cmditch$elm_ethereum$Eth$Utils$remove0x = function (str) {
	return (A2($elm$core$String$startsWith, '0x', str) || A2($elm$core$String$startsWith, '0X', str)) ? A2($elm$core$String$dropLeft, 2, str) : str;
};
var $cmditch$elm_ethereum$Eth$Utils$toTxHash = function (str) {
	return $cmditch$elm_ethereum$Eth$Utils$isSha256(str) ? $elm$core$Result$Ok(
		$cmditch$elm_ethereum$Eth$Utils$remove0x(str)) : $elm$core$Result$Err(
		'Given txHash ' + ($cmditch$elm_ethereum$Eth$Utils$quote(str) + ' is not valid.'));
};
var $cmditch$elm_ethereum$Eth$Decode$txHash = $cmditch$elm_ethereum$Eth$Decode$resultToDecoder($cmditch$elm_ethereum$Eth$Utils$toTxHash);
var $cmditch$elm_ethereum$Eth$Sentry$Tx$txIdResponseDecoder = A3(
	$elm$json$Json$Decode$map2,
	F2(
		function (ref, txHash) {
			return {o: ref, be: txHash};
		}),
	A2($elm$json$Json$Decode$field, 'ref', $elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$field,
		'txHash',
		$elm$json$Json$Decode$maybe($cmditch$elm_ethereum$Eth$Decode$txHash)));
var $cmditch$elm_ethereum$Eth$Sentry$Tx$decodeTxData = function (val) {
	var _v0 = A2($elm$json$Json$Decode$decodeValue, $cmditch$elm_ethereum$Eth$Sentry$Tx$txIdResponseDecoder, val);
	if (!_v0.$) {
		var result = _v0.a;
		var _v1 = result.be;
		if (!_v1.$) {
			var txHash = _v1.a;
			return A2(
				$cmditch$elm_ethereum$Eth$Sentry$Tx$TxSigned,
				result.o,
				$elm$core$Result$Ok(txHash));
		} else {
			return A2(
				$cmditch$elm_ethereum$Eth$Sentry$Tx$TxSigned,
				result.o,
				$elm$core$Result$Err(
					'Problem signing/broadcasting Tx. Ref #' + $elm$core$String$fromInt(result.o)));
		}
	} else {
		var error = _v0.a;
		return $cmditch$elm_ethereum$Eth$Sentry$Tx$ErrorDecoding('Error decoding tx data');
	}
};
var $cmditch$elm_ethereum$Eth$Sentry$Tx$listen = function (_v0) {
	var sentry = _v0;
	return A2(
		$elm$core$Platform$Sub$map,
		sentry.y,
		sentry.cB($cmditch$elm_ethereum$Eth$Sentry$Tx$decodeTxData));
};
var $author$project$Liquidator$subscriptions = function (model) {
	return $cmditch$elm_ethereum$Eth$Sentry$Tx$listen(model.ad);
};
var $author$project$Connected$subscriptions = function (model) {
	return A2(
		$elm$core$Platform$Sub$map,
		$author$project$Connected$LiquidatorCmd,
		$author$project$Liquidator$subscriptions(model.ax));
};
var $author$project$Main$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				$author$project$Ports$connectionChanged($author$project$Main$ConnectionChanged),
				function () {
				var _v0 = model.B;
				if (!_v0.$) {
					var puzzle = _v0.a._;
					var legoV1 = _v0.a.W;
					return $elm$core$Platform$Sub$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Platform$Sub$map,
								$author$project$Main$ConnectedMsg(0),
								$author$project$Connected$subscriptions(puzzle)),
								A2(
								$elm$core$Platform$Sub$map,
								$author$project$Main$ConnectedMsg(1),
								$author$project$Connected$subscriptions(legoV1))
							]));
				} else {
					return $elm$core$Platform$Sub$none;
				}
			}()
			]));
};
var $cmditch$elm_ethereum$Eth$Net$Private = function (a) {
	return {$: 9, a: a};
};
var $author$project$Constants$Avax = 1;
var $author$project$Constants$Bsc = 0;
var $author$project$Constants$EthNode = F3(
	function (id, http, ws) {
		return {d5: http, d6: id, eZ: ws};
	});
var $author$project$Constants$Poly = 2;
var $author$project$Constants$ethNode = function (networkId) {
	_v0$3:
	while (true) {
		if (networkId.$ === 9) {
			switch (networkId.a) {
				case 56:
					return $elm$core$Maybe$Just(
						A3($author$project$Constants$EthNode, 0, 'https://bsc-dataseed.binance.org/', 'wss://bsc-dataseed.binance.org/'));
				case 43114:
					return $elm$core$Maybe$Just(
						A3($author$project$Constants$EthNode, 1, 'https://api.avax.network/ext/bc/C/rpc', 'wss://api.avax.network/ext/bc/C/rpc/'));
				case 137:
					return $elm$core$Maybe$Just(
						A3($author$project$Constants$EthNode, 2, 'https://polygon-rpc.com/', 'wss://polygon-rpc.com/'));
				default:
					break _v0$3;
			}
		} else {
			break _v0$3;
		}
	}
	return $elm$core$Maybe$Nothing;
};
var $author$project$Connected$NftListResponse = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			A2(
				$elm$core$Task$onError,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
					$elm$core$Result$Err),
				A2(
					$elm$core$Task$andThen,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Ok),
					task)));
	});
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $cmditch$elm_ethereum$Eth$Types$LatestBlock = {$: 2};
var $cmditch$elm_ethereum$Eth$Utils$add0x = function (str) {
	return (A2($elm$core$String$startsWith, '0x', str) || A2($elm$core$String$startsWith, '0X', str)) ? str : ('0x' + str);
};
var $elm$json$Json$Encode$string = _Json_wrap;
var $elm$core$String$fromList = _String_fromList;
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$core$Basics$modBy = _Basics_modBy;
var $rtfeldman$elm_hex$Hex$unsafeToDigit = function (num) {
	unsafeToDigit:
	while (true) {
		switch (num) {
			case 0:
				return '0';
			case 1:
				return '1';
			case 2:
				return '2';
			case 3:
				return '3';
			case 4:
				return '4';
			case 5:
				return '5';
			case 6:
				return '6';
			case 7:
				return '7';
			case 8:
				return '8';
			case 9:
				return '9';
			case 10:
				return 'a';
			case 11:
				return 'b';
			case 12:
				return 'c';
			case 13:
				return 'd';
			case 14:
				return 'e';
			case 15:
				return 'f';
			default:
				var $temp$num = num;
				num = $temp$num;
				continue unsafeToDigit;
		}
	}
};
var $rtfeldman$elm_hex$Hex$unsafePositiveToDigits = F2(
	function (digits, num) {
		unsafePositiveToDigits:
		while (true) {
			if (num < 16) {
				return A2(
					$elm$core$List$cons,
					$rtfeldman$elm_hex$Hex$unsafeToDigit(num),
					digits);
			} else {
				var $temp$digits = A2(
					$elm$core$List$cons,
					$rtfeldman$elm_hex$Hex$unsafeToDigit(
						A2($elm$core$Basics$modBy, 16, num)),
					digits),
					$temp$num = (num / 16) | 0;
				digits = $temp$digits;
				num = $temp$num;
				continue unsafePositiveToDigits;
			}
		}
	});
var $rtfeldman$elm_hex$Hex$toString = function (num) {
	return $elm$core$String$fromList(
		(num < 0) ? A2(
			$elm$core$List$cons,
			'-',
			A2($rtfeldman$elm_hex$Hex$unsafePositiveToDigits, _List_Nil, -num)) : A2($rtfeldman$elm_hex$Hex$unsafePositiveToDigits, _List_Nil, num));
};
var $cmditch$elm_ethereum$Eth$Encode$blockId = function (blockId_) {
	switch (blockId_.$) {
		case 0:
			var num = blockId_.a;
			return $elm$json$Json$Encode$string(
				$cmditch$elm_ethereum$Eth$Utils$add0x(
					$rtfeldman$elm_hex$Hex$toString(num)));
		case 1:
			return $elm$json$Json$Encode$string('earliest');
		case 2:
			return $elm$json$Json$Encode$string('latest');
		default:
			return $elm$json$Json$Encode$string('pending');
	}
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $cmditch$elm_ethereum$Eth$RPC$expectJson = F2(
	function (decoder, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				var body = response.b;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.eI));
			default:
				var metadata = response.a;
				var body = response.b;
				var _v1 = A2(
					$elm$json$Json$Decode$decodeString,
					A2($elm$json$Json$Decode$field, 'result', decoder),
					body);
				if (!_v1.$) {
					var value = _v1.a;
					return $elm$core$Result$Ok(value);
				} else {
					var err = _v1.a;
					return $elm$core$Result$Err(
						$elm$http$Http$BadBody(
							$elm$json$Json$Decode$errorToString(err)));
				}
		}
	});
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$http$Http$stringResolver = A2(_Http_expect, '', $elm$core$Basics$identity);
var $elm$core$Task$fail = _Scheduler_fail;
var $elm$http$Http$resultToTask = function (result) {
	if (!result.$) {
		var a = result.a;
		return $elm$core$Task$succeed(a);
	} else {
		var x = result.a;
		return $elm$core$Task$fail(x);
	}
};
var $elm$http$Http$task = function (r) {
	return A3(
		_Http_toTask,
		0,
		$elm$http$Http$resultToTask,
		{dE: false, dG: r.dG, an: r.ez, d2: r.d2, ej: r.ej, eM: r.eM, dr: $elm$core$Maybe$Nothing, eW: r.eW});
};
var $elm$json$Json$Encode$int = _Json_wrap;
var $cmditch$elm_ethereum$Eth$RPC$jsonRPCVersion = '2.0';
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $cmditch$elm_ethereum$Eth$RPC$encode = F3(
	function (id, method, params) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'id',
					$elm$json$Json$Encode$int(id)),
					_Utils_Tuple2(
					'jsonrpc',
					$elm$json$Json$Encode$string($cmditch$elm_ethereum$Eth$RPC$jsonRPCVersion)),
					_Utils_Tuple2(
					'method',
					$elm$json$Json$Encode$string(method)),
					_Utils_Tuple2(
					'params',
					A2($elm$json$Json$Encode$list, $elm$core$Basics$identity, params))
				]));
	});
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $cmditch$elm_ethereum$Eth$RPC$toHttpBody = F3(
	function (id, method, params) {
		return $elm$http$Http$jsonBody(
			A3($cmditch$elm_ethereum$Eth$RPC$encode, id, method, params));
	});
var $cmditch$elm_ethereum$Eth$RPC$toTask = function (_v0) {
	var url = _v0.eW;
	var method = _v0.ej;
	var params = _v0.b;
	var decoder = _v0.dV;
	return $elm$http$Http$task(
		{
			dG: A3($cmditch$elm_ethereum$Eth$RPC$toHttpBody, 1, method, params),
			d2: _List_Nil,
			ej: 'POST',
			ez: $elm$http$Http$stringResolver(
				$cmditch$elm_ethereum$Eth$RPC$expectJson(decoder)),
			eM: $elm$core$Maybe$Nothing,
			eW: url
		});
};
var $cmditch$elm_ethereum$Eth$Utils$addressToString = function (_v0) {
	var address = _v0;
	return $cmditch$elm_ethereum$Eth$Utils$add0x(address);
};
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $cmditch$elm_ethereum$Eth$Encode$address = A2($elm$core$Basics$composeR, $cmditch$elm_ethereum$Eth$Utils$addressToString, $elm$json$Json$Encode$string);
var $cmditch$elm_bigint$BigInt$Magnitude = $elm$core$Basics$identity;
var $cmditch$elm_bigint$BigInt$Pos = function (a) {
	return {$: 0, a: a};
};
var $cmditch$elm_bigint$BigInt$BigIntNotNormalised = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $cmditch$elm_bigint$BigInt$MagnitudeNotNormalised = $elm$core$Basics$identity;
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $elm_community$list_extra$List$Extra$last = function (items) {
	last:
	while (true) {
		if (!items.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!items.b.b) {
				var x = items.a;
				return $elm$core$Maybe$Just(x);
			} else {
				var rest = items.b;
				var $temp$items = rest;
				items = $temp$items;
				continue last;
			}
		}
	}
};
var $cmditch$elm_bigint$BigInt$isNegativeMagnitude = function (digits) {
	var _v0 = $elm_community$list_extra$List$Extra$last(digits);
	if (_v0.$ === 1) {
		return false;
	} else {
		var x = _v0.a;
		return x < 0;
	}
};
var $cmditch$elm_bigint$BigInt$Neg = function (a) {
	return {$: 1, a: a};
};
var $cmditch$elm_bigint$BigInt$Zer = {$: 2};
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $cmditch$elm_bigint$BigInt$mkBigInt = F2(
	function (s, mag) {
		var digits = mag;
		if ($elm$core$List$isEmpty(digits)) {
			return $cmditch$elm_bigint$BigInt$Zer;
		} else {
			switch (s) {
				case 2:
					return $cmditch$elm_bigint$BigInt$Zer;
				case 0:
					return $cmditch$elm_bigint$BigInt$Pos(mag);
				default:
					return $cmditch$elm_bigint$BigInt$Neg(mag);
			}
		}
	});
var $cmditch$elm_bigint$BigInt$mkBigIntNotNormalised = F2(
	function (s, digits) {
		return A2($cmditch$elm_bigint$BigInt$BigIntNotNormalised, s, digits);
	});
var $elm_community$list_extra$List$Extra$dropWhileRight = function (p) {
	return A2(
		$elm$core$List$foldr,
		F2(
			function (x, xs) {
				return (p(x) && $elm$core$List$isEmpty(xs)) ? _List_Nil : A2($elm$core$List$cons, x, xs);
			}),
		_List_Nil);
};
var $cmditch$elm_bigint$BigInt$dropZeroes = $elm_community$list_extra$List$Extra$dropWhileRight(
	$elm$core$Basics$eq(0));
var $cmditch$elm_bigint$Constants$maxDigitMagnitude = 7;
var $elm$core$Basics$pow = _Basics_pow;
var $cmditch$elm_bigint$Constants$maxDigitValue = (-1) + A2($elm$core$Basics$pow, 10, $cmditch$elm_bigint$Constants$maxDigitMagnitude);
var $cmditch$elm_bigint$BigInt$baseDigit = $cmditch$elm_bigint$Constants$maxDigitValue + 1;
var $elm$core$Tuple$mapFirst = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var $cmditch$elm_bigint$BigInt$normaliseDigit = function (x) {
	return (x < 0) ? A2(
		$elm$core$Tuple$mapFirst,
		$elm$core$Basics$add(-1),
		$cmditch$elm_bigint$BigInt$normaliseDigit(x + $cmditch$elm_bigint$BigInt$baseDigit)) : _Utils_Tuple2((x / $cmditch$elm_bigint$BigInt$baseDigit) | 0, x % $cmditch$elm_bigint$BigInt$baseDigit);
};
var $cmditch$elm_bigint$BigInt$normaliseDigitList = F2(
	function (carry, xs) {
		normaliseDigitList:
		while (true) {
			if (!xs.b) {
				if (_Utils_cmp(carry, $cmditch$elm_bigint$BigInt$baseDigit) > 0) {
					var $temp$carry = 0,
						$temp$xs = _List_fromArray(
						[carry]);
					carry = $temp$carry;
					xs = $temp$xs;
					continue normaliseDigitList;
				} else {
					return _List_fromArray(
						[carry]);
				}
			} else {
				var x = xs.a;
				var xs_ = xs.b;
				var _v1 = $cmditch$elm_bigint$BigInt$normaliseDigit(x + carry);
				var newCarry = _v1.a;
				var x_ = _v1.b;
				return A2(
					$elm$core$List$cons,
					x_,
					A2($cmditch$elm_bigint$BigInt$normaliseDigitList, newCarry, xs_));
			}
		}
	});
var $cmditch$elm_bigint$BigInt$normaliseMagnitude = function (_v0) {
	var xs = _v0;
	return $cmditch$elm_bigint$BigInt$dropZeroes(
		A2($cmditch$elm_bigint$BigInt$normaliseDigitList, 0, xs));
};
var $cmditch$elm_bigint$BigInt$reverseMagnitude = $elm$core$List$map($elm$core$Basics$negate);
var $cmditch$elm_bigint$BigInt$Negative = 1;
var $cmditch$elm_bigint$BigInt$Positive = 0;
var $cmditch$elm_bigint$BigInt$Zero = 2;
var $cmditch$elm_bigint$BigInt$signNegate = function (sign_) {
	switch (sign_) {
		case 0:
			return 1;
		case 1:
			return 0;
		default:
			return 2;
	}
};
var $cmditch$elm_bigint$BigInt$normalise = function (_v0) {
	normalise:
	while (true) {
		var s = _v0.a;
		var digits = _v0.b;
		var _v1 = $cmditch$elm_bigint$BigInt$normaliseMagnitude(digits);
		var normalisedMag = _v1;
		if ($cmditch$elm_bigint$BigInt$isNegativeMagnitude(normalisedMag)) {
			var $temp$_v0 = A2(
				$cmditch$elm_bigint$BigInt$mkBigIntNotNormalised,
				$cmditch$elm_bigint$BigInt$signNegate(s),
				$cmditch$elm_bigint$BigInt$reverseMagnitude(normalisedMag));
			_v0 = $temp$_v0;
			continue normalise;
		} else {
			return A2($cmditch$elm_bigint$BigInt$mkBigInt, s, normalisedMag);
		}
	}
};
var $cmditch$elm_bigint$BigInt$signFromInt = function (x) {
	var _v0 = A2($elm$core$Basics$compare, x, 0);
	switch (_v0) {
		case 0:
			return 1;
		case 2:
			return 0;
		default:
			return 2;
	}
};
var $cmditch$elm_bigint$BigInt$fromInt = function (x) {
	return $cmditch$elm_bigint$BigInt$normalise(
		A2(
			$cmditch$elm_bigint$BigInt$BigIntNotNormalised,
			$cmditch$elm_bigint$BigInt$signFromInt(x),
			_List_fromArray(
				[
					$elm$core$Basics$abs(x)
				])));
};
var $cmditch$elm_bigint$BigInt$bigIntToInt_ = function (bigInt) {
	_v0$3:
	while (true) {
		switch (bigInt.$) {
			case 2:
				return 0;
			case 0:
				if (bigInt.a.b) {
					if (!bigInt.a.b.b) {
						var _v1 = bigInt.a;
						var a = _v1.a;
						return a;
					} else {
						if (!bigInt.a.b.b.b) {
							var _v2 = bigInt.a;
							var a = _v2.a;
							var _v3 = _v2.b;
							var b = _v3.a;
							return (b * A2($elm$core$Basics$pow, 10, $cmditch$elm_bigint$Constants$maxDigitMagnitude)) + a;
						} else {
							break _v0$3;
						}
					}
				} else {
					break _v0$3;
				}
			default:
				break _v0$3;
		}
	}
	return 42;
};
var $cmditch$elm_bigint$BigInt$abs = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return $cmditch$elm_bigint$BigInt$Zer;
		case 1:
			var mag = bigInt.a;
			return $cmditch$elm_bigint$BigInt$Pos(mag);
		default:
			var i = bigInt;
			return i;
	}
};
var $cmditch$elm_bigint$BigInt$MagnitudePair = $elm$core$Basics$identity;
var $cmditch$elm_bigint$BigInt$sameSizeRaw = F2(
	function (xs, ys) {
		var _v0 = _Utils_Tuple2(xs, ys);
		if (!_v0.a.b) {
			if (!_v0.b.b) {
				return _List_Nil;
			} else {
				var _v2 = _v0.b;
				var y = _v2.a;
				var ys_ = _v2.b;
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(0, y),
					A2($cmditch$elm_bigint$BigInt$sameSizeRaw, _List_Nil, ys_));
			}
		} else {
			if (!_v0.b.b) {
				var _v1 = _v0.a;
				var x = _v1.a;
				var xs_ = _v1.b;
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(x, 0),
					A2($cmditch$elm_bigint$BigInt$sameSizeRaw, xs_, _List_Nil));
			} else {
				var _v3 = _v0.a;
				var x = _v3.a;
				var xs_ = _v3.b;
				var _v4 = _v0.b;
				var y = _v4.a;
				var ys_ = _v4.b;
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(x, y),
					A2($cmditch$elm_bigint$BigInt$sameSizeRaw, xs_, ys_));
			}
		}
	});
var $cmditch$elm_bigint$BigInt$sameSizeNotNormalized = F2(
	function (_v0, _v1) {
		var xs = _v0;
		var ys = _v1;
		return A2($cmditch$elm_bigint$BigInt$sameSizeRaw, xs, ys);
	});
var $cmditch$elm_bigint$BigInt$toPositiveSign = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return A2($cmditch$elm_bigint$BigInt$mkBigIntNotNormalised, 2, _List_Nil);
		case 1:
			var digits = bigInt.a;
			return A2(
				$cmditch$elm_bigint$BigInt$mkBigIntNotNormalised,
				0,
				$cmditch$elm_bigint$BigInt$reverseMagnitude(digits));
		default:
			var digits = bigInt.a;
			return A2($cmditch$elm_bigint$BigInt$mkBigIntNotNormalised, 0, digits);
	}
};
var $cmditch$elm_bigint$BigInt$add = F2(
	function (a, b) {
		var _v0 = $cmditch$elm_bigint$BigInt$toPositiveSign(b);
		var mb = _v0.b;
		var _v1 = $cmditch$elm_bigint$BigInt$toPositiveSign(a);
		var ma = _v1.b;
		var _v2 = A2($cmditch$elm_bigint$BigInt$sameSizeNotNormalized, ma, mb);
		var pairs = _v2;
		var added = A2(
			$elm$core$List$map,
			function (_v3) {
				var a_ = _v3.a;
				var b_ = _v3.b;
				return a_ + b_;
			},
			pairs);
		return $cmditch$elm_bigint$BigInt$normalise(
			A2($cmditch$elm_bigint$BigInt$BigIntNotNormalised, 0, added));
	});
var $cmditch$elm_bigint$BigInt$compareMagnitude = F4(
	function (x, y, xs, ys) {
		compareMagnitude:
		while (true) {
			var _v0 = _Utils_Tuple2(xs, ys);
			if (!_v0.a.b) {
				if (!_v0.b.b) {
					return A2($elm$core$Basics$compare, x, y);
				} else {
					return 0;
				}
			} else {
				if (!_v0.b.b) {
					return 2;
				} else {
					var _v1 = _v0.a;
					var x_ = _v1.a;
					var xss = _v1.b;
					var _v2 = _v0.b;
					var y_ = _v2.a;
					var yss = _v2.b;
					if (_Utils_eq(x_, y_)) {
						var $temp$x = x,
							$temp$y = y,
							$temp$xs = xss,
							$temp$ys = yss;
						x = $temp$x;
						y = $temp$y;
						xs = $temp$xs;
						ys = $temp$ys;
						continue compareMagnitude;
					} else {
						var $temp$x = x_,
							$temp$y = y_,
							$temp$xs = xss,
							$temp$ys = yss;
						x = $temp$x;
						y = $temp$y;
						xs = $temp$xs;
						ys = $temp$ys;
						continue compareMagnitude;
					}
				}
			}
		}
	});
var $cmditch$elm_bigint$BigInt$orderNegate = function (x) {
	switch (x) {
		case 0:
			return 2;
		case 1:
			return 1;
		default:
			return 0;
	}
};
var $cmditch$elm_bigint$BigInt$compare = F2(
	function (int1, int2) {
		var _v0 = _Utils_Tuple2(int1, int2);
		switch (_v0.a.$) {
			case 0:
				if (!_v0.b.$) {
					var mag1 = _v0.a.a;
					var mag2 = _v0.b.a;
					return A4($cmditch$elm_bigint$BigInt$compareMagnitude, 0, 0, mag1, mag2);
				} else {
					return 2;
				}
			case 1:
				if (_v0.b.$ === 1) {
					var mag1 = _v0.a.a;
					var mag2 = _v0.b.a;
					return $cmditch$elm_bigint$BigInt$orderNegate(
						A4($cmditch$elm_bigint$BigInt$compareMagnitude, 0, 0, mag1, mag2));
				} else {
					return 0;
				}
			default:
				switch (_v0.b.$) {
					case 0:
						var _v1 = _v0.a;
						return 0;
					case 2:
						var _v2 = _v0.a;
						var _v3 = _v0.b;
						return 1;
					default:
						var _v4 = _v0.a;
						return 2;
				}
		}
	});
var $cmditch$elm_bigint$BigInt$gt = F2(
	function (x, y) {
		return A2($cmditch$elm_bigint$BigInt$compare, x, y) === 2;
	});
var $elm$core$Basics$not = _Basics_not;
var $cmditch$elm_bigint$BigInt$lte = F2(
	function (x, y) {
		return !A2($cmditch$elm_bigint$BigInt$gt, x, y);
	});
var $cmditch$elm_bigint$BigInt$magnitude = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return _List_Nil;
		case 0:
			var mag = bigInt.a;
			return mag;
		default:
			var mag = bigInt.a;
			return mag;
	}
};
var $cmditch$elm_bigint$BigInt$mulSingleDigit = F2(
	function (_v0, d) {
		var xs = _v0;
		return $cmditch$elm_bigint$BigInt$normaliseMagnitude(
			A2(
				$elm$core$List$map,
				$elm$core$Basics$mul(d),
				xs));
	});
var $cmditch$elm_bigint$BigInt$mulMagnitudes = F2(
	function (_v0, _v1) {
		var mag1 = _v0;
		var mag2 = _v1;
		if (!mag1.b) {
			return _List_Nil;
		} else {
			if (!mag1.b.b) {
				var m = mag1.a;
				return A2($cmditch$elm_bigint$BigInt$mulSingleDigit, mag2, m);
			} else {
				var m = mag1.a;
				var mx = mag1.b;
				var accum = A2($cmditch$elm_bigint$BigInt$mulSingleDigit, mag2, m);
				var _v3 = A2($cmditch$elm_bigint$BigInt$mulMagnitudes, mx, mag2);
				var rest = _v3;
				var bigInt = A2(
					$cmditch$elm_bigint$BigInt$add,
					A2($cmditch$elm_bigint$BigInt$mkBigInt, 0, accum),
					A2(
						$cmditch$elm_bigint$BigInt$mkBigInt,
						0,
						A2($elm$core$List$cons, 0, rest)));
				return $cmditch$elm_bigint$BigInt$magnitude(bigInt);
			}
		}
	});
var $cmditch$elm_bigint$BigInt$sign = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return 2;
		case 0:
			return 0;
		default:
			return 1;
	}
};
var $cmditch$elm_bigint$BigInt$signProduct = F2(
	function (x, y) {
		return ((x === 2) || (y === 2)) ? 2 : (_Utils_eq(x, y) ? 0 : 1);
	});
var $cmditch$elm_bigint$BigInt$mul = F2(
	function (int1, int2) {
		return A2(
			$cmditch$elm_bigint$BigInt$mkBigInt,
			A2(
				$cmditch$elm_bigint$BigInt$signProduct,
				$cmditch$elm_bigint$BigInt$sign(int1),
				$cmditch$elm_bigint$BigInt$sign(int2)),
			A2(
				$cmditch$elm_bigint$BigInt$mulMagnitudes,
				$cmditch$elm_bigint$BigInt$magnitude(int1),
				$cmditch$elm_bigint$BigInt$magnitude(int2)));
	});
var $cmditch$elm_bigint$BigInt$negate = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return $cmditch$elm_bigint$BigInt$Zer;
		case 0:
			var mag = bigInt.a;
			return $cmditch$elm_bigint$BigInt$Neg(mag);
		default:
			var mag = bigInt.a;
			return $cmditch$elm_bigint$BigInt$Pos(mag);
	}
};
var $cmditch$elm_bigint$BigInt$sub = F2(
	function (a, b) {
		return A2(
			$cmditch$elm_bigint$BigInt$add,
			a,
			$cmditch$elm_bigint$BigInt$negate(b));
	});
var $cmditch$elm_bigint$BigInt$zero = $cmditch$elm_bigint$BigInt$fromInt(0);
var $cmditch$elm_bigint$BigInt$divmodDigit_ = F4(
	function (to_test, padding, num, den) {
		if (!to_test) {
			return _Utils_Tuple2($cmditch$elm_bigint$BigInt$zero, num);
		} else {
			var x = $cmditch$elm_bigint$BigInt$fromInt(to_test);
			var candidate = A2(
				$cmditch$elm_bigint$BigInt$mul,
				A2($cmditch$elm_bigint$BigInt$mul, x, den),
				padding);
			var _v0 = A2($cmditch$elm_bigint$BigInt$lte, candidate, num) ? _Utils_Tuple2(
				A2($cmditch$elm_bigint$BigInt$mul, x, padding),
				A2($cmditch$elm_bigint$BigInt$sub, num, candidate)) : _Utils_Tuple2($cmditch$elm_bigint$BigInt$zero, num);
			var newdiv = _v0.a;
			var newmod = _v0.b;
			var _v1 = A4($cmditch$elm_bigint$BigInt$divmodDigit_, (to_test / 2) | 0, padding, newmod, den);
			var restdiv = _v1.a;
			var restmod = _v1.b;
			return _Utils_Tuple2(
				A2($cmditch$elm_bigint$BigInt$add, newdiv, restdiv),
				restmod);
		}
	});
var $cmditch$elm_bigint$BigInt$maxDigitBits = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $cmditch$elm_bigint$Constants$maxDigitValue));
var $cmditch$elm_bigint$BigInt$divmodDigit = F3(
	function (padding, x, y) {
		return A4(
			$cmditch$elm_bigint$BigInt$divmodDigit_,
			A2($elm$core$Basics$pow, 2, $cmditch$elm_bigint$BigInt$maxDigitBits),
			padding,
			x,
			y);
	});
var $cmditch$elm_bigint$BigInt$one = $cmditch$elm_bigint$BigInt$fromInt(1);
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $cmditch$elm_bigint$BigInt$repeatedly = F3(
	function (f, x, n) {
		return A3(
			$elm$core$List$foldl,
			$elm$core$Basics$always(f),
			x,
			A2($elm$core$List$range, 1, n));
	});
var $cmditch$elm_bigint$BigInt$padDigits = function (n) {
	return A3(
		$cmditch$elm_bigint$BigInt$repeatedly,
		$cmditch$elm_bigint$BigInt$mul(
			$cmditch$elm_bigint$BigInt$fromInt($cmditch$elm_bigint$BigInt$baseDigit)),
		$cmditch$elm_bigint$BigInt$one,
		n);
};
var $cmditch$elm_bigint$BigInt$divMod_ = F3(
	function (n, num, den) {
		if (!n) {
			return A3(
				$cmditch$elm_bigint$BigInt$divmodDigit,
				$cmditch$elm_bigint$BigInt$padDigits(n),
				num,
				den);
		} else {
			var _v0 = A3(
				$cmditch$elm_bigint$BigInt$divmodDigit,
				$cmditch$elm_bigint$BigInt$padDigits(n),
				num,
				den);
			var cdiv = _v0.a;
			var cmod = _v0.b;
			var _v1 = A3($cmditch$elm_bigint$BigInt$divMod_, n - 1, cmod, den);
			var rdiv = _v1.a;
			var rmod = _v1.b;
			return _Utils_Tuple2(
				A2($cmditch$elm_bigint$BigInt$add, cdiv, rdiv),
				rmod);
		}
	});
var $cmditch$elm_bigint$BigInt$toDigits = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return _List_Nil;
		case 0:
			var ds = bigInt.a;
			return ds;
		default:
			var ds = bigInt.a;
			return ds;
	}
};
var $cmditch$elm_bigint$BigInt$divmod = F2(
	function (num, den) {
		if (_Utils_eq(den, $cmditch$elm_bigint$BigInt$zero)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var cand_l = ($elm$core$List$length(
				$cmditch$elm_bigint$BigInt$toDigits(num)) - $elm$core$List$length(
				$cmditch$elm_bigint$BigInt$toDigits(den))) + 1;
			var _v0 = A3(
				$cmditch$elm_bigint$BigInt$divMod_,
				A2($elm$core$Basics$max, 0, cand_l),
				$cmditch$elm_bigint$BigInt$abs(num),
				$cmditch$elm_bigint$BigInt$abs(den));
			var d = _v0.a;
			var m = _v0.b;
			return $elm$core$Maybe$Just(
				_Utils_Tuple2(
					A2(
						$cmditch$elm_bigint$BigInt$mkBigInt,
						A2(
							$cmditch$elm_bigint$BigInt$signProduct,
							$cmditch$elm_bigint$BigInt$sign(num),
							$cmditch$elm_bigint$BigInt$sign(den)),
						$cmditch$elm_bigint$BigInt$magnitude(d)),
					A2(
						$cmditch$elm_bigint$BigInt$mkBigInt,
						$cmditch$elm_bigint$BigInt$sign(num),
						$cmditch$elm_bigint$BigInt$magnitude(m))));
		}
	});
var $cmditch$elm_bigint$BigInt$eightHexDigits = A2(
	$cmditch$elm_bigint$BigInt$mul,
	$cmditch$elm_bigint$BigInt$fromInt(2),
	$cmditch$elm_bigint$BigInt$fromInt(2147483648));
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $cmditch$elm_bigint$BigInt$hexMagnitudeToString = function (bigInt) {
	var _v0 = A2($cmditch$elm_bigint$BigInt$divmod, bigInt, $cmditch$elm_bigint$BigInt$eightHexDigits);
	if (_v0.$ === 1) {
		return 'Failure converting BigInt to hex string. Should be impossible. Open up issue on the elm-bigint repo.';
	} else {
		var _v1 = _v0.a;
		var d = _v1.a;
		var r = _v1.b;
		var rString = $rtfeldman$elm_hex$Hex$toString(
			$cmditch$elm_bigint$BigInt$bigIntToInt_(r));
		return _Utils_eq(
			d,
			$cmditch$elm_bigint$BigInt$fromInt(0)) ? rString : _Utils_ap(
			$cmditch$elm_bigint$BigInt$hexMagnitudeToString(d),
			A3($elm$core$String$padLeft, 8, '0', rString));
	}
};
var $cmditch$elm_bigint$BigInt$toHexString = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return '0';
		case 0:
			var mag = bigInt.a;
			return _Utils_eq(mag, _List_Nil) ? '0' : $cmditch$elm_bigint$BigInt$hexMagnitudeToString(
				$cmditch$elm_bigint$BigInt$Pos(mag));
		default:
			var mag = bigInt.a;
			return '-' + $cmditch$elm_bigint$BigInt$toHexString(
				A2(
					$cmditch$elm_bigint$BigInt$mul,
					$cmditch$elm_bigint$BigInt$fromInt(-1),
					bigInt));
	}
};
var $cmditch$elm_ethereum$Eth$Encode$bigInt = A2(
	$elm$core$Basics$composeR,
	$cmditch$elm_bigint$BigInt$toHexString,
	A2($elm$core$Basics$composeR, $cmditch$elm_ethereum$Eth$Utils$add0x, $elm$json$Json$Encode$string));
var $cmditch$elm_ethereum$Eth$Utils$hexToString = function (_v0) {
	var hex = _v0;
	return $cmditch$elm_ethereum$Eth$Utils$add0x(hex);
};
var $cmditch$elm_ethereum$Eth$Encode$hex = A2($elm$core$Basics$composeR, $cmditch$elm_ethereum$Eth$Utils$hexToString, $elm$json$Json$Encode$string);
var $cmditch$elm_ethereum$Eth$Encode$hexInt = A2(
	$elm$core$Basics$composeR,
	$rtfeldman$elm_hex$Hex$toString,
	A2($elm$core$Basics$composeR, $cmditch$elm_ethereum$Eth$Utils$add0x, $elm$json$Json$Encode$string));
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$Basics$neq = _Utils_notEqual;
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $cmditch$elm_ethereum$Eth$Encode$listOfMaybesToVal = function (keyValueList) {
	return $elm$json$Json$Encode$object(
		A2(
			$elm$core$List$map,
			function (_v1) {
				var k = _v1.a;
				var v = _v1.b;
				return _Utils_Tuple2(
					k,
					A2($elm$core$Maybe$withDefault, $elm$json$Json$Encode$null, v));
			},
			A2(
				$elm$core$List$filter,
				function (_v0) {
					var k = _v0.a;
					var v = _v0.b;
					return !_Utils_eq(v, $elm$core$Maybe$Nothing);
				},
				keyValueList)));
};
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $cmditch$elm_ethereum$Eth$Encode$txCall = function (_v0) {
	var to = _v0.b5;
	var from = _v0.bK;
	var gas = _v0.bM;
	var gasPrice = _v0.bN;
	var value = _v0.b9;
	var data = _v0.bH;
	return $cmditch$elm_ethereum$Eth$Encode$listOfMaybesToVal(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'to',
				A2($elm$core$Maybe$map, $cmditch$elm_ethereum$Eth$Encode$address, to)),
				_Utils_Tuple2(
				'from',
				A2($elm$core$Maybe$map, $cmditch$elm_ethereum$Eth$Encode$address, from)),
				_Utils_Tuple2(
				'gas',
				A2($elm$core$Maybe$map, $cmditch$elm_ethereum$Eth$Encode$hexInt, gas)),
				_Utils_Tuple2(
				'gasPrice',
				A2($elm$core$Maybe$map, $cmditch$elm_ethereum$Eth$Encode$bigInt, gasPrice)),
				_Utils_Tuple2(
				'value',
				A2($elm$core$Maybe$map, $cmditch$elm_ethereum$Eth$Encode$bigInt, value)),
				_Utils_Tuple2(
				'data',
				A2($elm$core$Maybe$map, $cmditch$elm_ethereum$Eth$Encode$hex, data))
			]));
};
var $cmditch$elm_ethereum$Eth$callAtBlock = F3(
	function (ethNode, txParams, blockId) {
		return $cmditch$elm_ethereum$Eth$RPC$toTask(
			{
				dV: txParams.dV,
				ej: 'eth_call',
				b: _List_fromArray(
					[
						$cmditch$elm_ethereum$Eth$Encode$txCall(txParams),
						$cmditch$elm_ethereum$Eth$Encode$blockId(blockId)
					]),
				eW: ethNode
			});
	});
var $cmditch$elm_ethereum$Eth$call = F2(
	function (ethNode, txParams) {
		return A3($cmditch$elm_ethereum$Eth$callAtBlock, ethNode, txParams, $cmditch$elm_ethereum$Eth$Types$LatestBlock);
	});
var $author$project$Utils$mapErr = F2(
	function (fn, res) {
		if (!res.$) {
			var a = res.a;
			return $elm$core$Result$Ok(a);
		} else {
			var b = res.a;
			return $elm$core$Result$Err(
				fn(b));
		}
	});
var $cmditch$elm_ethereum$Eth$Abi$Encode$EValue = function (a) {
	return {$: 0, a: a};
};
var $cmditch$elm_ethereum$Eth$Utils$leftPadTo64 = function (str) {
	return A3($elm$core$String$padLeft, 64, '0', str);
};
var $cmditch$elm_ethereum$Eth$Abi$Encode$address = function (_v0) {
	var addr = _v0;
	return $cmditch$elm_ethereum$Eth$Abi$Encode$EValue(
		$cmditch$elm_ethereum$Eth$Utils$leftPadTo64(addr));
};
var $cmditch$elm_ethereum$Internal$Types$Address = $elm$core$Basics$identity;
var $elm$core$String$toLower = _String_toLower;
var $cmditch$elm_ethereum$Eth$Utils$unsafeToAddress = A2(
	$elm$core$Basics$composeR,
	$cmditch$elm_ethereum$Eth$Utils$remove0x,
	A2($elm$core$Basics$composeR, $elm$core$String$toLower, $elm$core$Basics$identity));
var $author$project$Contracts$batcherAddress = function (_v0) {
	var net = _v0.a;
	var v = _v0.b;
	switch (net) {
		case 0:
			if (v === 1) {
				return $cmditch$elm_ethereum$Eth$Utils$unsafeToAddress('0xD81b8221f57eB314AB9C6606F418653CCfC90eB5');
			} else {
				return $cmditch$elm_ethereum$Eth$Utils$unsafeToAddress('0x5eb0E26A66f0fa0E32A05585A2711c3B84cb53D3');
			}
		case 1:
			if (v === 1) {
				return $cmditch$elm_ethereum$Eth$Utils$unsafeToAddress('0xD81b8221f57eB314AB9C6606F418653CCfC90eB5');
			} else {
				return $cmditch$elm_ethereum$Eth$Utils$unsafeToAddress('0x5eb0E26A66f0fa0E32A05585A2711c3B84cb53D3');
			}
		default:
			if (v === 1) {
				return $cmditch$elm_ethereum$Eth$Utils$unsafeToAddress('0xA27ebBF168846c24Cc7DC1f4EBfF7d8495daE71d');
			} else {
				return $cmditch$elm_ethereum$Eth$Utils$unsafeToAddress('0x5eb0E26A66f0fa0E32A05585A2711c3B84cb53D3');
			}
	}
};
var $cmditch$elm_ethereum$Eth$Abi$Decode$AbiDecoder = $elm$core$Basics$identity;
var $cmditch$elm_ethereum$Eth$Abi$Decode$abiDecode = function (val) {
	return function (tape) {
		return $elm$core$Result$Ok(
			_Utils_Tuple2(tape, val));
	};
};
var $elm$core$Result$andThen = F2(
	function (callback, result) {
		if (!result.$) {
			var value = result.a;
			return callback(value);
		} else {
			var msg = result.a;
			return $elm$core$Result$Err(msg);
		}
	});
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $elm$core$Tuple$mapSecond = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			x,
			func(y));
	});
var $cmditch$elm_ethereum$Eth$Abi$Decode$map2 = F3(
	function (f, _v0, _v1) {
		var decA = _v0;
		var decB = _v1;
		return function (tape0) {
			return A2(
				$elm$core$Result$andThen,
				function (_v2) {
					var tape1 = _v2.a;
					var vA = _v2.b;
					return A2(
						$elm$core$Result$map,
						$elm$core$Tuple$mapSecond(
							f(vA)),
						decB(tape1));
				},
				decA(tape0));
		};
	});
var $cmditch$elm_ethereum$Eth$Abi$Decode$andMap = F2(
	function (dVal, dFunc) {
		return A3(
			$cmditch$elm_ethereum$Eth$Abi$Decode$map2,
			F2(
				function (f, v) {
					return f(v);
				}),
			dFunc,
			dVal);
	});
var $author$project$Contracts$Nft = F2(
	function (id, assets) {
		return {L: assets, d6: id};
	});
var $author$project$Contracts$buildNft = $author$project$Contracts$Nft;
var $author$project$Contracts$NftAsset = F2(
	function (token, qty) {
		return {ew: qty, dq: token};
	});
var $cmditch$elm_ethereum$Eth$Abi$Decode$Tape = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $cmditch$elm_ethereum$Eth$Utils$drop64 = $elm$core$String$dropLeft(64);
var $cmditch$elm_ethereum$Eth$Abi$Decode$newTape = F3(
	function (original, altered, val) {
		return _Utils_Tuple2(
			A2(
				$cmditch$elm_ethereum$Eth$Abi$Decode$Tape,
				original,
				$cmditch$elm_ethereum$Eth$Utils$drop64(altered)),
			val);
	});
var $cmditch$elm_ethereum$Eth$Utils$take64 = $elm$core$String$left(64);
var $cmditch$elm_ethereum$Eth$Utils$isAddress = $elm$regex$Regex$contains(
	A2(
		$elm$core$Maybe$withDefault,
		$elm$regex$Regex$never,
		$elm$regex$Regex$fromString('^((0[Xx]){1})?[0-9A-Fa-f]{40}$')));
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			$elm$core$List$any,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, isOkay),
			list);
	});
var $Chadtech$elm_bool_extra$Bool$Extra$all = $elm$core$List$all($elm$core$Basics$identity);
var $elm$core$Result$map2 = F3(
	function (func, ra, rb) {
		if (ra.$ === 1) {
			var x = ra.a;
			return $elm$core$Result$Err(x);
		} else {
			var a = ra.a;
			if (rb.$ === 1) {
				var x = rb.a;
				return $elm$core$Result$Err(x);
			} else {
				var b = rb.a;
				return $elm$core$Result$Ok(
					A2(func, a, b));
			}
		}
	});
var $elm_community$result_extra$Result$Extra$combine = A2(
	$elm$core$List$foldr,
	$elm$core$Result$map2($elm$core$List$cons),
	$elm$core$Result$Ok(_List_Nil));
var $prozacchiwawa$elm_keccak$Keccak$KC = $elm$core$Basics$identity;
var $prozacchiwawa$elm_keccak$Keccak$ethereum_keccak_256 = {R: 512, M: 1, N: 32, E: 1088};
var $elm$bytes$Bytes$Encode$getWidth = function (builder) {
	switch (builder.$) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 4;
		case 3:
			return 1;
		case 4:
			return 2;
		case 5:
			return 4;
		case 6:
			return 4;
		case 7:
			return 8;
		case 8:
			var w = builder.a;
			return w;
		case 9:
			var w = builder.a;
			return w;
		default:
			var bs = builder.a;
			return _Bytes_width(bs);
	}
};
var $elm$bytes$Bytes$LE = 0;
var $elm$bytes$Bytes$Encode$write = F3(
	function (builder, mb, offset) {
		switch (builder.$) {
			case 0:
				var n = builder.a;
				return A3(_Bytes_write_i8, mb, offset, n);
			case 1:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_i16, mb, offset, n, !e);
			case 2:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_i32, mb, offset, n, !e);
			case 3:
				var n = builder.a;
				return A3(_Bytes_write_u8, mb, offset, n);
			case 4:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_u16, mb, offset, n, !e);
			case 5:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_u32, mb, offset, n, !e);
			case 6:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_f32, mb, offset, n, !e);
			case 7:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_f64, mb, offset, n, !e);
			case 8:
				var bs = builder.b;
				return A3($elm$bytes$Bytes$Encode$writeSequence, bs, mb, offset);
			case 9:
				var s = builder.b;
				return A3(_Bytes_write_string, mb, offset, s);
			default:
				var bs = builder.a;
				return A3(_Bytes_write_bytes, mb, offset, bs);
		}
	});
var $elm$bytes$Bytes$Encode$writeSequence = F3(
	function (builders, mb, offset) {
		writeSequence:
		while (true) {
			if (!builders.b) {
				return offset;
			} else {
				var b = builders.a;
				var bs = builders.b;
				var $temp$builders = bs,
					$temp$mb = mb,
					$temp$offset = A3($elm$bytes$Bytes$Encode$write, b, mb, offset);
				builders = $temp$builders;
				mb = $temp$mb;
				offset = $temp$offset;
				continue writeSequence;
			}
		}
	});
var $elm$bytes$Bytes$Encode$encode = _Bytes_encode;
var $elm$bytes$Bytes$Encode$Bytes = function (a) {
	return {$: 10, a: a};
};
var $elm$bytes$Bytes$Encode$bytes = $elm$bytes$Bytes$Encode$Bytes;
var $elm$bytes$Bytes$Encode$Seq = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $elm$bytes$Bytes$Encode$getWidths = F2(
	function (width, builders) {
		getWidths:
		while (true) {
			if (!builders.b) {
				return width;
			} else {
				var b = builders.a;
				var bs = builders.b;
				var $temp$width = width + $elm$bytes$Bytes$Encode$getWidth(b),
					$temp$builders = bs;
				width = $temp$width;
				builders = $temp$builders;
				continue getWidths;
			}
		}
	});
var $elm$bytes$Bytes$Encode$sequence = function (builders) {
	return A2(
		$elm$bytes$Bytes$Encode$Seq,
		A2($elm$bytes$Bytes$Encode$getWidths, 0, builders),
		builders);
};
var $prozacchiwawa$elm_keccak$Keccak$concatBytesList = function (newPartial) {
	return $elm$bytes$Bytes$Encode$encode(
		$elm$bytes$Bytes$Encode$sequence(
			A2(
				$elm$core$List$map,
				$elm$bytes$Bytes$Encode$bytes,
				$elm$core$List$reverse(newPartial))));
};
var $elm$bytes$Bytes$Decode$decode = F2(
	function (_v0, bs) {
		var decoder = _v0;
		return A2(_Bytes_decode, decoder, bs);
	});
var $elm$bytes$Bytes$Decode$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$bytes$Bytes$Decode$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$bytes$Bytes$Decode$Decoder = $elm$core$Basics$identity;
var $elm$bytes$Bytes$Decode$map = F2(
	function (func, _v0) {
		var decodeA = _v0;
		return F2(
			function (bites, offset) {
				var _v1 = A2(decodeA, bites, offset);
				var aOffset = _v1.a;
				var a = _v1.b;
				return _Utils_Tuple2(
					aOffset,
					func(a));
			});
	});
var $elm$bytes$Bytes$Decode$succeed = function (a) {
	return F2(
		function (_v0, offset) {
			return _Utils_Tuple2(offset, a);
		});
};
var $prozacchiwawa$elm_keccak$Keccak$listStep = F2(
	function (decoder, _v0) {
		var n = _v0.a;
		var xs = _v0.b;
		return (n <= 0) ? $elm$bytes$Bytes$Decode$succeed(
			$elm$bytes$Bytes$Decode$Done(
				$elm$core$List$reverse(xs))) : A2(
			$elm$bytes$Bytes$Decode$map,
			function (x) {
				return $elm$bytes$Bytes$Decode$Loop(
					_Utils_Tuple2(
						n - 1,
						A2($elm$core$List$cons, x, xs)));
			},
			decoder);
	});
var $elm$bytes$Bytes$Decode$loopHelp = F4(
	function (state, callback, bites, offset) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var decoder = _v0;
			var _v1 = A2(decoder, bites, offset);
			var newOffset = _v1.a;
			var step = _v1.b;
			if (!step.$) {
				var newState = step.a;
				var $temp$state = newState,
					$temp$callback = callback,
					$temp$bites = bites,
					$temp$offset = newOffset;
				state = $temp$state;
				callback = $temp$callback;
				bites = $temp$bites;
				offset = $temp$offset;
				continue loopHelp;
			} else {
				var result = step.a;
				return _Utils_Tuple2(newOffset, result);
			}
		}
	});
var $elm$bytes$Bytes$Decode$loop = F2(
	function (state, callback) {
		return A2($elm$bytes$Bytes$Decode$loopHelp, state, callback);
	});
var $elm$bytes$Bytes$Decode$unsignedInt8 = _Bytes_read_u8;
var $elm$bytes$Bytes$width = _Bytes_width;
var $prozacchiwawa$elm_keccak$Keccak$intListOfBytes = function (b) {
	return A2(
		$elm$core$Maybe$withDefault,
		_List_Nil,
		A2(
			$elm$bytes$Bytes$Decode$decode,
			A2(
				$elm$bytes$Bytes$Decode$loop,
				_Utils_Tuple2(
					$elm$bytes$Bytes$width(b),
					_List_Nil),
				$prozacchiwawa$elm_keccak$Keccak$listStep($elm$bytes$Bytes$Decode$unsignedInt8)),
			b));
};
var $prozacchiwawa$elm_keccak$Keccak$tupleMap2 = F3(
	function (f, _v0, _v1) {
		var a1 = _v0.a;
		var b1 = _v0.b;
		var a2 = _v1.a;
		var b2 = _v1.b;
		return _Utils_Tuple2(
			A2(f, a1, a2),
			A2(f, b1, b2));
	});
var $prozacchiwawa$elm_keccak$Keccak$and64 = F2(
	function (a, b) {
		return A3($prozacchiwawa$elm_keccak$Keccak$tupleMap2, $elm$core$Bitwise$and, a, b);
	});
var $prozacchiwawa$elm_keccak$Keccak$five = A2($elm$core$List$range, 0, 4);
var $prozacchiwawa$elm_keccak$Keccak$gd = F2(
	function (n, _v0) {
		var d0 = _v0.bC;
		var d1 = _v0.bD;
		var d2 = _v0.bE;
		var d3 = _v0.bF;
		var d4 = _v0.bG;
		switch (n) {
			case 0:
				return d0;
			case 1:
				return d1;
			case 2:
				return d2;
			case 3:
				return d3;
			default:
				return d4;
		}
	});
var $elm$core$Bitwise$complement = _Bitwise_complement;
var $prozacchiwawa$elm_keccak$Keccak$elementMask = 4294967295;
var $prozacchiwawa$elm_keccak$Keccak$tupleMap = F2(
	function (f, _v0) {
		var a = _v0.a;
		var b = _v0.b;
		return _Utils_Tuple2(
			f(a),
			f(b));
	});
var $prozacchiwawa$elm_keccak$Keccak$inv64 = function (a) {
	return A2(
		$prozacchiwawa$elm_keccak$Keccak$tupleMap,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$Bitwise$complement,
			$elm$core$Bitwise$and($prozacchiwawa$elm_keccak$Keccak$elementMask)),
		a);
};
var $prozacchiwawa$elm_keccak$Keccak$iPerm = F2(
	function (x, y) {
		return (5 * y) + x;
	});
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $elm$core$Array$bitMask = 4294967295 >>> (32 - $elm$core$Array$shiftStep);
var $elm$core$Basics$ge = _Utils_ge;
var $elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var $elm$core$Array$getHelp = F3(
	function (shift, index, tree) {
		getHelp:
		while (true) {
			var pos = $elm$core$Array$bitMask & (index >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_v0.$) {
				var subTree = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$index = index,
					$temp$tree = subTree;
				shift = $temp$shift;
				index = $temp$index;
				tree = $temp$tree;
				continue getHelp;
			} else {
				var values = _v0.a;
				return A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, values);
			}
		}
	});
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var $elm$core$Array$get = F2(
	function (index, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? $elm$core$Maybe$Nothing : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? $elm$core$Maybe$Just(
			A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, tail)) : $elm$core$Maybe$Just(
			A3($elm$core$Array$getHelp, startShift, index, tree)));
	});
var $prozacchiwawa$elm_keccak$Keccak$load64 = F2(
	function (off, arr) {
		var _v0 = A2($elm$core$Array$get, off, arr);
		if (!_v0.$) {
			var a = _v0.a;
			return a;
		} else {
			return _Utils_Tuple2(0, 0);
		}
	});
var $prozacchiwawa$elm_keccak$Keccak$readLane = F3(
	function (x, y, state) {
		var off = A2($prozacchiwawa$elm_keccak$Keccak$iPerm, x, y);
		return A2($prozacchiwawa$elm_keccak$Keccak$load64, off, state);
	});
var $elm$core$Elm$JsArray$unsafeSet = _JsArray_unsafeSet;
var $elm$core$Array$setHelp = F4(
	function (shift, index, value, tree) {
		var pos = $elm$core$Array$bitMask & (index >>> shift);
		var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
		if (!_v0.$) {
			var subTree = _v0.a;
			var newSub = A4($elm$core$Array$setHelp, shift - $elm$core$Array$shiftStep, index, value, subTree);
			return A3(
				$elm$core$Elm$JsArray$unsafeSet,
				pos,
				$elm$core$Array$SubTree(newSub),
				tree);
		} else {
			var values = _v0.a;
			var newLeaf = A3($elm$core$Elm$JsArray$unsafeSet, $elm$core$Array$bitMask & index, value, values);
			return A3(
				$elm$core$Elm$JsArray$unsafeSet,
				pos,
				$elm$core$Array$Leaf(newLeaf),
				tree);
		}
	});
var $elm$core$Array$set = F3(
	function (index, value, array) {
		var len = array.a;
		var startShift = array.b;
		var tree = array.c;
		var tail = array.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? array : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? A4(
			$elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			tree,
			A3($elm$core$Elm$JsArray$unsafeSet, $elm$core$Array$bitMask & index, value, tail)) : A4(
			$elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			A4($elm$core$Array$setHelp, startShift, index, value, tree),
			tail));
	});
var $prozacchiwawa$elm_keccak$Keccak$store64 = F3(
	function (off, v, arr) {
		return A3($elm$core$Array$set, off, v, arr);
	});
var $prozacchiwawa$elm_keccak$Keccak$writeLane = F4(
	function (x, y, lane, state) {
		var off = A2($prozacchiwawa$elm_keccak$Keccak$iPerm, x, y);
		return A3($prozacchiwawa$elm_keccak$Keccak$store64, off, lane, state);
	});
var $elm$core$Bitwise$xor = _Bitwise_xor;
var $prozacchiwawa$elm_keccak$Keccak$xor64 = F2(
	function (v, arr) {
		return A3($prozacchiwawa$elm_keccak$Keccak$tupleMap2, $elm$core$Bitwise$xor, v, arr);
	});
var $prozacchiwawa$elm_keccak$Keccak$chi = function (ss) {
	var newState = A3(
		$elm$core$List$foldr,
		F2(
			function (y, state) {
				var temp = {
					bC: A3($prozacchiwawa$elm_keccak$Keccak$readLane, 0, y, ss.b0),
					bD: A3($prozacchiwawa$elm_keccak$Keccak$readLane, 1, y, ss.b0),
					bE: A3($prozacchiwawa$elm_keccak$Keccak$readLane, 2, y, ss.b0),
					bF: A3($prozacchiwawa$elm_keccak$Keccak$readLane, 3, y, ss.b0),
					bG: A3($prozacchiwawa$elm_keccak$Keccak$readLane, 4, y, ss.b0)
				};
				var yupdate = F2(
					function (x, state_) {
						return A4(
							$prozacchiwawa$elm_keccak$Keccak$writeLane,
							x,
							y,
							A2(
								$prozacchiwawa$elm_keccak$Keccak$xor64,
								A2($prozacchiwawa$elm_keccak$Keccak$gd, x, temp),
								A2(
									$prozacchiwawa$elm_keccak$Keccak$and64,
									$prozacchiwawa$elm_keccak$Keccak$inv64(
										A2(
											$prozacchiwawa$elm_keccak$Keccak$gd,
											A2($elm$core$Basics$modBy, 5, x + 1),
											temp)),
									A2(
										$prozacchiwawa$elm_keccak$Keccak$gd,
										A2($elm$core$Basics$modBy, 5, x + 2),
										temp))),
							state_);
					});
				return A2(
					yupdate,
					4,
					A2(
						yupdate,
						3,
						A2(
							yupdate,
							2,
							A2(
								yupdate,
								1,
								A2(yupdate, 0, state)))));
			}),
		ss.b0,
		$prozacchiwawa$elm_keccak$Keccak$five);
	return _Utils_update(
		ss,
		{b0: newState});
};
var $prozacchiwawa$elm_keccak$Keccak$zero = _Utils_Tuple2(0, 0);
var $prozacchiwawa$elm_keccak$Keccak$initRound = function (state) {
	return {a_: $prozacchiwawa$elm_keccak$Keccak$zero, a3: 1, b0: state, dz: 1, dA: 0};
};
var $prozacchiwawa$elm_keccak$Keccak$lfsr86540 = function (lfsr) {
	var result = !(!(lfsr & 1));
	return (!(!(lfsr & 128))) ? _Utils_Tuple2(result, (lfsr << 1) ^ 113) : _Utils_Tuple2(result, lfsr << 1);
};
var $prozacchiwawa$elm_keccak$Keccak$one = _Utils_Tuple2(1, 0);
var $prozacchiwawa$elm_keccak$Keccak$bitsPerElement = 32;
var $elm$core$Bitwise$or = _Bitwise_or;
var $prozacchiwawa$elm_keccak$Keccak$rolbytes = F2(
	function (n, _v0) {
		var va = _v0.a;
		var vb = _v0.b;
		return (!n) ? _Utils_Tuple2(va, vb) : _Utils_Tuple2(vb, va);
	});
var $prozacchiwawa$elm_keccak$Keccak$rolbits = F2(
	function (n, v) {
		if (!n) {
			return v;
		} else {
			var oneRotated = A2($prozacchiwawa$elm_keccak$Keccak$rolbytes, 1, v);
			return A3(
				$prozacchiwawa$elm_keccak$Keccak$tupleMap2,
				F2(
					function (a, b) {
						return $prozacchiwawa$elm_keccak$Keccak$elementMask & ((a << n) | (b >>> ($prozacchiwawa$elm_keccak$Keccak$bitsPerElement - n)));
					}),
				v,
				oneRotated);
		}
	});
var $prozacchiwawa$elm_keccak$Keccak$rol64 = F2(
	function (n, v) {
		var rby = A2($elm$core$Basics$modBy, $prozacchiwawa$elm_keccak$Keccak$bitsPerElement, (n / $prozacchiwawa$elm_keccak$Keccak$bitsPerElement) | 0);
		var rotated = A2($prozacchiwawa$elm_keccak$Keccak$rolbytes, rby, v);
		var rbi = A2($elm$core$Basics$modBy, $prozacchiwawa$elm_keccak$Keccak$bitsPerElement, n);
		return A2($prozacchiwawa$elm_keccak$Keccak$rolbits, rbi, rotated);
	});
var $prozacchiwawa$elm_keccak$Keccak$updateArray = F3(
	function (n, f, a) {
		var element = A2($elm$core$Array$get, n, a);
		if (element.$ === 1) {
			return a;
		} else {
			var element_ = element.a;
			return A3(
				$elm$core$Array$set,
				n,
				f(element_),
				a);
		}
	});
var $prozacchiwawa$elm_keccak$Keccak$storexor64 = F3(
	function (off, v, arr) {
		return A3(
			$prozacchiwawa$elm_keccak$Keccak$updateArray,
			off,
			$prozacchiwawa$elm_keccak$Keccak$xor64(v),
			arr);
	});
var $prozacchiwawa$elm_keccak$Keccak$xorLane = F4(
	function (x, y, lane, state) {
		var off = A2($prozacchiwawa$elm_keccak$Keccak$iPerm, x, y);
		return A3($prozacchiwawa$elm_keccak$Keccak$storexor64, off, lane, state);
	});
var $prozacchiwawa$elm_keccak$Keccak$iota = function (ss) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (j, ss_) {
				var bitPosition = (1 << j) - 1;
				var _v0 = $prozacchiwawa$elm_keccak$Keccak$lfsr86540(ss_.a3);
				var o = _v0.a;
				var lfsr = _v0.b;
				return o ? _Utils_update(
					ss_,
					{
						a3: lfsr,
						b0: A4(
							$prozacchiwawa$elm_keccak$Keccak$xorLane,
							0,
							0,
							A2($prozacchiwawa$elm_keccak$Keccak$rol64, bitPosition, $prozacchiwawa$elm_keccak$Keccak$one),
							ss_.b0)
					}) : _Utils_update(
					ss_,
					{a3: lfsr});
			}),
		ss,
		A2($elm$core$List$range, 0, 6));
};
var $prozacchiwawa$elm_keccak$Keccak$twentyThree = A2($elm$core$List$range, 0, 23);
var $prozacchiwawa$elm_keccak$Keccak$rhoPi = function (ss) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (t, ss_) {
				var yy = A2($elm$core$Basics$modBy, 5, (2 * ss_.dz) + (3 * ss_.dA));
				var r = A2($elm$core$Basics$modBy, 64, (((t + 1) * (t + 2)) / 2) | 0);
				return _Utils_update(
					ss_,
					{
						a_: A3($prozacchiwawa$elm_keccak$Keccak$readLane, ss_.dA, yy, ss_.b0),
						b0: A4(
							$prozacchiwawa$elm_keccak$Keccak$writeLane,
							ss_.dA,
							yy,
							A2($prozacchiwawa$elm_keccak$Keccak$rol64, r, ss_.a_),
							ss_.b0),
						dz: ss_.dA,
						dA: yy
					});
			}),
		_Utils_update(
			ss,
			{
				a_: A3($prozacchiwawa$elm_keccak$Keccak$readLane, 1, 0, ss.b0),
				dz: 1,
				dA: 0
			}),
		$prozacchiwawa$elm_keccak$Keccak$twentyThree);
};
var $prozacchiwawa$elm_keccak$Keccak$cInitX = F2(
	function (x, state) {
		return A2(
			$prozacchiwawa$elm_keccak$Keccak$xor64,
			A3($prozacchiwawa$elm_keccak$Keccak$readLane, x, 0, state),
			A2(
				$prozacchiwawa$elm_keccak$Keccak$xor64,
				A3($prozacchiwawa$elm_keccak$Keccak$readLane, x, 1, state),
				A2(
					$prozacchiwawa$elm_keccak$Keccak$xor64,
					A3($prozacchiwawa$elm_keccak$Keccak$readLane, x, 2, state),
					A2(
						$prozacchiwawa$elm_keccak$Keccak$xor64,
						A3($prozacchiwawa$elm_keccak$Keccak$readLane, x, 3, state),
						A3($prozacchiwawa$elm_keccak$Keccak$readLane, x, 4, state)))));
	});
var $prozacchiwawa$elm_keccak$Keccak$twentyFive = A2($elm$core$List$range, 0, 25);
var $prozacchiwawa$elm_keccak$Keccak$theta = function (ss) {
	var d = function (x) {
		var c4 = A2(
			$prozacchiwawa$elm_keccak$Keccak$cInitX,
			A2($elm$core$Basics$modBy, 5, x + 4),
			ss.b0);
		var c1 = A2(
			$prozacchiwawa$elm_keccak$Keccak$cInitX,
			A2($elm$core$Basics$modBy, 5, x + 1),
			ss.b0);
		return A2(
			$prozacchiwawa$elm_keccak$Keccak$xor64,
			c4,
			A2($prozacchiwawa$elm_keccak$Keccak$rol64, 1, c1));
	};
	var dx = {
		bC: d(0),
		bD: d(1),
		bE: d(2),
		bF: d(3),
		bG: d(4)
	};
	var sd = A3(
		$elm$core$List$foldl,
		F2(
			function (n, state) {
				var x = A2($elm$core$Basics$modBy, 5, n);
				var y = (n / 5) | 0;
				return A4(
					$prozacchiwawa$elm_keccak$Keccak$xorLane,
					x,
					y,
					A2($prozacchiwawa$elm_keccak$Keccak$gd, x, dx),
					state);
			}),
		ss.b0,
		$prozacchiwawa$elm_keccak$Keccak$twentyFive);
	return _Utils_update(
		ss,
		{b0: sd});
};
var $prozacchiwawa$elm_keccak$Keccak$keccakF1600_StatePermute = function (state) {
	var res = A3(
		$elm$core$List$foldr,
		function (_v0) {
			return A2(
				$elm$core$Basics$composeR,
				$prozacchiwawa$elm_keccak$Keccak$theta,
				A2(
					$elm$core$Basics$composeR,
					$prozacchiwawa$elm_keccak$Keccak$rhoPi,
					A2($elm$core$Basics$composeR, $prozacchiwawa$elm_keccak$Keccak$chi, $prozacchiwawa$elm_keccak$Keccak$iota)));
		},
		$prozacchiwawa$elm_keccak$Keccak$initRound(state),
		$prozacchiwawa$elm_keccak$Keccak$twentyThree);
	return res.b0;
};
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $prozacchiwawa$elm_keccak$Keccak$bytesPerElement = ($prozacchiwawa$elm_keccak$Keccak$bitsPerElement / 8) | 0;
var $prozacchiwawa$elm_keccak$Keccak$retrieveOutputByte = F2(
	function (i, arr) {
		var shift = 8 * A2($elm$core$Basics$modBy, $prozacchiwawa$elm_keccak$Keccak$bytesPerElement, i);
		var e = A2($elm$core$Basics$modBy, 2, (i / $prozacchiwawa$elm_keccak$Keccak$bytesPerElement) | 0);
		var _v0 = A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2(0, 0),
			A2($elm$core$Array$get, (i / 8) | 0, arr));
		var ea = _v0.a;
		var eb = _v0.b;
		var byi = (!e) ? ea : eb;
		return 255 & (byi >> shift);
	});
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $prozacchiwawa$elm_keccak$Keccak$xorFromByte = F3(
	function (shift, sel, by) {
		return (!sel) ? _Utils_Tuple2(by << shift, 0) : _Utils_Tuple2(0, by << shift);
	});
var $prozacchiwawa$elm_keccak$Keccak$xorByteIntoState = F3(
	function (i, v, state) {
		var shift = 8 * A2($elm$core$Basics$modBy, $prozacchiwawa$elm_keccak$Keccak$bytesPerElement, i);
		var e = A2($elm$core$Basics$modBy, 2, (i / $prozacchiwawa$elm_keccak$Keccak$bytesPerElement) | 0);
		var newElt = A3($prozacchiwawa$elm_keccak$Keccak$xorFromByte, shift, e, v);
		return A3($prozacchiwawa$elm_keccak$Keccak$storexor64, (i / 8) | 0, newElt, state);
	});
var $prozacchiwawa$elm_keccak$Keccak$xorIntoState = F2(
	function (block, state) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, s) {
					var i = _v0.a;
					var e = _v0.b;
					return A3($prozacchiwawa$elm_keccak$Keccak$xorByteIntoState, i, e, s);
				}),
			state,
			A2(
				$elm$core$List$indexedMap,
				F2(
					function (i, e) {
						return _Utils_Tuple2(i, e);
					}),
				block));
	});
var $prozacchiwawa$elm_keccak$Keccak$finish = function (_v0) {
	var state = _v0;
	var rateInBytes = (state.bi.E / 8) | 0;
	var inputLength = state.au;
	var config = state.bi;
	var concat = $prozacchiwawa$elm_keccak$Keccak$concatBytesList(state.aK);
	var inb = $prozacchiwawa$elm_keccak$Keccak$intListOfBytes(concat);
	var s1 = A2($prozacchiwawa$elm_keccak$Keccak$xorIntoState, inb, state.b0);
	var blockSize = (!inputLength) ? 0 : ((!A2($elm$core$Basics$modBy, rateInBytes, inputLength)) ? rateInBytes : A2($elm$core$Basics$modBy, rateInBytes, inputLength));
	if (((config.E + config.R) !== 1600) || (!(!A2($elm$core$Basics$modBy, 8, config.E)))) {
		return _List_Nil;
	} else {
		var state1 = A3($prozacchiwawa$elm_keccak$Keccak$xorByteIntoState, blockSize, config.M, s1);
		var state2 = ((!(!(config.M & 128))) && _Utils_eq(blockSize, rateInBytes - 1)) ? $prozacchiwawa$elm_keccak$Keccak$keccakF1600_StatePermute(state1) : state1;
		var state3 = A3($prozacchiwawa$elm_keccak$Keccak$xorByteIntoState, rateInBytes - 1, 128, state2);
		var state4 = $prozacchiwawa$elm_keccak$Keccak$keccakF1600_StatePermute(state3);
		var processRemainingOutput = F3(
			function (state_, output_, outputByteLen) {
				processRemainingOutput:
				while (true) {
					if (outputByteLen > 0) {
						var blockSize_ = A2($elm$core$Basics$min, outputByteLen, rateInBytes);
						var outputBytes = A2(
							$elm$core$List$map,
							function (i) {
								return A2($prozacchiwawa$elm_keccak$Keccak$retrieveOutputByte, i, state4);
							},
							A2($elm$core$List$range, 0, blockSize_));
						var $temp$state_ = $prozacchiwawa$elm_keccak$Keccak$keccakF1600_StatePermute(state_),
							$temp$output_ = _Utils_ap(output_, outputBytes),
							$temp$outputByteLen = outputByteLen - blockSize_;
						state_ = $temp$state_;
						output_ = $temp$output_;
						outputByteLen = $temp$outputByteLen;
						continue processRemainingOutput;
					} else {
						return output_;
					}
				}
			});
		return A2(
			$elm$core$List$take,
			config.N,
			A3(processRemainingOutput, state4, _List_Nil, config.N));
	}
};
var $prozacchiwawa$elm_keccak$Keccak$KS = $elm$core$Basics$identity;
var $prozacchiwawa$elm_keccak$Keccak$init = function (_v0) {
	var config = _v0;
	return {
		bi: config,
		au: 0,
		aK: _List_Nil,
		b0: A2(
			$elm$core$Array$initialize,
			25,
			$elm$core$Basics$always($prozacchiwawa$elm_keccak$Keccak$zero))
	};
};
var $elm$bytes$Bytes$Encode$U8 = function (a) {
	return {$: 3, a: a};
};
var $elm$bytes$Bytes$Encode$unsignedInt8 = $elm$bytes$Bytes$Encode$U8;
var $elm$bytes$Bytes$Decode$bytes = function (n) {
	return _Bytes_read_bytes(n);
};
var $prozacchiwawa$elm_keccak$Keccak$emptyBytes = $elm$bytes$Bytes$Encode$encode(
	$elm$bytes$Bytes$Encode$sequence(_List_Nil));
var $elm$bytes$Bytes$Decode$andThen = F2(
	function (callback, _v0) {
		var decodeA = _v0;
		return F2(
			function (bites, offset) {
				var _v1 = A2(decodeA, bites, offset);
				var newOffset = _v1.a;
				var a = _v1.b;
				var _v2 = callback(a);
				var decodeB = _v2;
				return A2(decodeB, bites, newOffset);
			});
	});
var $prozacchiwawa$elm_keccak$Keccak$restOfBytesDecoder = F2(
	function (n, m) {
		return A2(
			$elm$bytes$Bytes$Decode$andThen,
			function (x) {
				return $elm$bytes$Bytes$Decode$bytes(m);
			},
			$elm$bytes$Bytes$Decode$bytes(n));
	});
var $prozacchiwawa$elm_keccak$Keccak$restOfBytes = F2(
	function (n, b) {
		return A2(
			$elm$core$Maybe$withDefault,
			$prozacchiwawa$elm_keccak$Keccak$emptyBytes,
			A2(
				$elm$bytes$Bytes$Decode$decode,
				A2(
					$prozacchiwawa$elm_keccak$Keccak$restOfBytesDecoder,
					n,
					$elm$bytes$Bytes$width(b) - n),
				b));
	});
var $prozacchiwawa$elm_keccak$Keccak$update = F2(
	function (b, _v0) {
		update:
		while (true) {
			var state = _v0;
			var rateInBytes = (state.bi.E / 8) | 0;
			var newPartial = (!$elm$bytes$Bytes$width(b)) ? state.aK : A2($elm$core$List$cons, b, state.aK);
			var storedBytes = A3(
				$elm$core$List$foldl,
				F2(
					function (bs, s) {
						return s + $elm$bytes$Bytes$width(bs);
					}),
				0,
				newPartial);
			if (_Utils_cmp(storedBytes, rateInBytes) > -1) {
				var concat = $prozacchiwawa$elm_keccak$Keccak$concatBytesList(newPartial);
				var first = A2(
					$elm$core$Maybe$withDefault,
					$prozacchiwawa$elm_keccak$Keccak$emptyBytes,
					A2(
						$elm$bytes$Bytes$Decode$decode,
						$elm$bytes$Bytes$Decode$bytes(rateInBytes),
						concat));
				var inb = $prozacchiwawa$elm_keccak$Keccak$intListOfBytes(first);
				var s1 = A2($prozacchiwawa$elm_keccak$Keccak$xorIntoState, inb, state.b0);
				var s2 = $prozacchiwawa$elm_keccak$Keccak$keccakF1600_StatePermute(s1);
				var rest = A2($prozacchiwawa$elm_keccak$Keccak$restOfBytes, rateInBytes, concat);
				var $temp$b = $prozacchiwawa$elm_keccak$Keccak$emptyBytes,
					$temp$_v0 = _Utils_update(
					state,
					{
						au: $elm$bytes$Bytes$width(b) + state.au,
						aK: _List_fromArray(
							[rest]),
						b0: s2
					});
				b = $temp$b;
				_v0 = $temp$_v0;
				continue update;
			} else {
				return _Utils_update(
					state,
					{
						au: $elm$bytes$Bytes$width(b) + state.au,
						aK: newPartial
					});
			}
		}
	});
var $prozacchiwawa$elm_keccak$Keccak$Int$keccak = F2(
	function (config, ints) {
		return $prozacchiwawa$elm_keccak$Keccak$finish(
			A2(
				$prozacchiwawa$elm_keccak$Keccak$update,
				$elm$bytes$Bytes$Encode$encode(
					$elm$bytes$Bytes$Encode$sequence(
						A2($elm$core$List$map, $elm$bytes$Bytes$Encode$unsignedInt8, ints))),
				$prozacchiwawa$elm_keccak$Keccak$init(config)));
	});
var $prozacchiwawa$elm_keccak$Keccak$Int$ethereum_keccak_256 = $prozacchiwawa$elm_keccak$Keccak$Int$keccak($prozacchiwawa$elm_keccak$Keccak$ethereum_keccak_256);
var $rtfeldman$elm_hex$Hex$fromStringHelp = F3(
	function (position, chars, accumulated) {
		fromStringHelp:
		while (true) {
			if (!chars.b) {
				return $elm$core$Result$Ok(accumulated);
			} else {
				var _char = chars.a;
				var rest = chars.b;
				switch (_char) {
					case '0':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated;
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '1':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + A2($elm$core$Basics$pow, 16, position);
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '2':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (2 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '3':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (3 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '4':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (4 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '5':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (5 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '6':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (6 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '7':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (7 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '8':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (8 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '9':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (9 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'a':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (10 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'b':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (11 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'c':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (12 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'd':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (13 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'e':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (14 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'f':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (15 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					default:
						var nonHex = _char;
						return $elm$core$Result$Err(
							$elm$core$String$fromChar(nonHex) + ' is not a valid hexadecimal character.');
				}
			}
		}
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(xs);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $rtfeldman$elm_hex$Hex$fromString = function (str) {
	if ($elm$core$String$isEmpty(str)) {
		return $elm$core$Result$Err('Empty strings are not valid hexadecimal strings.');
	} else {
		var result = function () {
			if (A2($elm$core$String$startsWith, '-', str)) {
				var list = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$elm$core$List$tail(
						$elm$core$String$toList(str)));
				return A2(
					$elm$core$Result$map,
					$elm$core$Basics$negate,
					A3(
						$rtfeldman$elm_hex$Hex$fromStringHelp,
						$elm$core$List$length(list) - 1,
						list,
						0));
			} else {
				return A3(
					$rtfeldman$elm_hex$Hex$fromStringHelp,
					$elm$core$String$length(str) - 1,
					$elm$core$String$toList(str),
					0);
			}
		}();
		var formatError = function (err) {
			return A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					['\"' + (str + '\"'), 'is not a valid hexadecimal string because', err]));
		};
		return A2($elm$core$Result$mapError, formatError, result);
	}
};
var $elm$core$String$append = _String_append;
var $cmditch$elm_ethereum$Eth$Utils$toByteLength = function (str) {
	return (A2(
		$elm$core$Basics$modBy,
		2,
		$elm$core$String$length(str)) === 1) ? A2($elm$core$String$append, '0', str) : str;
};
var $elm$core$Char$toLower = _Char_toLower;
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (!result.$) {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $cmditch$elm_ethereum$Eth$Utils$checksumHelper = function (zeroLessAddress) {
	var addressChars = $elm$core$String$toList(zeroLessAddress);
	return function (b) {
		return _Utils_Tuple2(addressChars, b);
	}(
		A2(
			$elm$core$Result$withDefault,
			_List_Nil,
			$elm_community$result_extra$Result$Extra$combine(
				A2(
					$elm$core$List$map,
					$rtfeldman$elm_hex$Hex$fromString,
					A2(
						$elm$core$String$split,
						'',
						A2(
							$elm$core$String$join,
							'',
							A2(
								$elm$core$List$map,
								A2($elm$core$Basics$composeR, $rtfeldman$elm_hex$Hex$toString, $cmditch$elm_ethereum$Eth$Utils$toByteLength),
								A2(
									$elm$core$List$take,
									20,
									$prozacchiwawa$elm_keccak$Keccak$Int$ethereum_keccak_256(
										A2(
											$elm$core$List$map,
											A2($elm$core$Basics$composeR, $elm$core$Char$toLower, $elm$core$Char$toCode),
											addressChars))))))))));
};
var $cmditch$elm_ethereum$Eth$Utils$isChecksumAddress = function (str) {
	var checksumTestChar = F2(
		function (addrChar, hashInt) {
			return (((hashInt >= 8) && $elm$core$Char$isLower(addrChar)) || ((hashInt < 8) && $elm$core$Char$isUpper(addrChar))) ? false : true;
		});
	var _v0 = $cmditch$elm_ethereum$Eth$Utils$checksumHelper(
		$cmditch$elm_ethereum$Eth$Utils$remove0x(str));
	var addrChars = _v0.a;
	var hashInts = _v0.b;
	var checksumCorrect = A3($elm$core$List$map2, checksumTestChar, addrChars, hashInts);
	return $cmditch$elm_ethereum$Eth$Utils$isAddress(str) ? $Chadtech$elm_bool_extra$Bool$Extra$all(checksumCorrect) : false;
};
var $cmditch$elm_ethereum$Eth$Utils$isLowerCaseAddress = $elm$regex$Regex$contains(
	A2(
		$elm$core$Maybe$withDefault,
		$elm$regex$Regex$never,
		$elm$regex$Regex$fromString('^((0[Xx]){1})?[0-9a-f]{40}$')));
var $cmditch$elm_ethereum$Eth$Utils$isUpperCaseAddress = $elm$regex$Regex$contains(
	A2(
		$elm$core$Maybe$withDefault,
		$elm$regex$Regex$never,
		$elm$regex$Regex$fromString('^((0[Xx]){1})?[0-9A-F]{40}$')));
var $elm$core$String$right = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(
			$elm$core$String$slice,
			-n,
			$elm$core$String$length(string),
			string);
	});
var $cmditch$elm_ethereum$Eth$Utils$toAddress = function (str) {
	var normalize = A2(
		$elm$core$Basics$composeR,
		$elm$core$String$toLower,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$core$Result$Ok));
	var noZeroX = $cmditch$elm_ethereum$Eth$Utils$remove0x(str);
	var emptyZerosInBytes32 = A2($elm$core$String$left, 24, noZeroX);
	var bytes32Address = A2($elm$core$String$right, 40, str);
	return (($elm$core$String$length(noZeroX) === 64) && A2(
		$elm$core$String$all,
		$elm$core$Basics$eq('0'),
		emptyZerosInBytes32)) ? (($cmditch$elm_ethereum$Eth$Utils$isUpperCaseAddress(bytes32Address) || $cmditch$elm_ethereum$Eth$Utils$isLowerCaseAddress(bytes32Address)) ? normalize(bytes32Address) : ($cmditch$elm_ethereum$Eth$Utils$isChecksumAddress(bytes32Address) ? normalize(bytes32Address) : $elm$core$Result$Err(
		'Given address ' + ($cmditch$elm_ethereum$Eth$Utils$quote(str) + ' failed the EIP-55 checksum test.')))) : (($elm$core$String$length(noZeroX) !== 40) ? $elm$core$Result$Err(
		'Given address ' + ($cmditch$elm_ethereum$Eth$Utils$quote(str) + ' is not the correct length.')) : ((!$cmditch$elm_ethereum$Eth$Utils$isAddress(noZeroX)) ? $elm$core$Result$Err(
		'Given address ' + ($cmditch$elm_ethereum$Eth$Utils$quote(str) + ' contains invalid hex characters.')) : (($cmditch$elm_ethereum$Eth$Utils$isUpperCaseAddress(noZeroX) || $cmditch$elm_ethereum$Eth$Utils$isLowerCaseAddress(noZeroX)) ? normalize(noZeroX) : ($cmditch$elm_ethereum$Eth$Utils$isChecksumAddress(noZeroX) ? normalize(noZeroX) : $elm$core$Result$Err(
		'Given address ' + ($cmditch$elm_ethereum$Eth$Utils$quote(str) + ' failed the EIP-55 checksum test.'))))));
};
var $cmditch$elm_ethereum$Eth$Abi$Decode$address = function (_v0) {
	var original = _v0.a;
	var altered = _v0.b;
	return A2(
		$elm$core$Result$map,
		A2($cmditch$elm_ethereum$Eth$Abi$Decode$newTape, original, altered),
		$cmditch$elm_ethereum$Eth$Utils$toAddress(
			$cmditch$elm_ethereum$Eth$Utils$take64(altered)));
};
var $elm_community$maybe_extra$Maybe$Extra$combineHelp = F2(
	function (list, acc) {
		combineHelp:
		while (true) {
			if (list.b) {
				var head = list.a;
				var tail = list.b;
				if (!head.$) {
					var a = head.a;
					var $temp$list = tail,
						$temp$acc = A2($elm$core$List$cons, a, acc);
					list = $temp$list;
					acc = $temp$acc;
					continue combineHelp;
				} else {
					return $elm$core$Maybe$Nothing;
				}
			} else {
				return $elm$core$Maybe$Just(
					$elm$core$List$reverse(acc));
			}
		}
	});
var $elm_community$maybe_extra$Maybe$Extra$combine = function (list) {
	return A2($elm_community$maybe_extra$Maybe$Extra$combineHelp, list, _List_Nil);
};
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$greedyGroupsOfWithStep = F3(
	function (size, step, list) {
		if ((size <= 0) || (step <= 0)) {
			return _List_Nil;
		} else {
			var go = F2(
				function (xs, acc) {
					go:
					while (true) {
						if ($elm$core$List$isEmpty(xs)) {
							return $elm$core$List$reverse(acc);
						} else {
							var $temp$xs = A2($elm$core$List$drop, step, xs),
								$temp$acc = A2(
								$elm$core$List$cons,
								A2($elm$core$List$take, size, xs),
								acc);
							xs = $temp$xs;
							acc = $temp$acc;
							continue go;
						}
					}
				});
			return A2(go, list, _List_Nil);
		}
	});
var $elm_community$list_extra$List$Extra$greedyGroupsOf = F2(
	function (size, xs) {
		return A3($elm_community$list_extra$List$Extra$greedyGroupsOfWithStep, size, size, xs);
	});
var $cmditch$elm_bigint$Constants$hexDigitMagnitude = 8;
var $elm$core$Result$toMaybe = function (result) {
	if (!result.$) {
		var v = result.a;
		return $elm$core$Maybe$Just(v);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $cmditch$elm_bigint$BigInt$fromHexString_ = function (x) {
	var _v0 = A2(
		$elm$regex$Regex$contains,
		A2(
			$elm$core$Maybe$withDefault,
			$elm$regex$Regex$never,
			$elm$regex$Regex$fromString('^[0-9A-Fa-f]')),
		$elm$core$String$fromList(x));
	if (_v0) {
		return A2(
			$elm$core$Maybe$map,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$List$reverse,
				A2(
					$elm$core$List$foldl,
					F2(
						function (e, s) {
							return A2(
								$cmditch$elm_bigint$BigInt$add,
								$cmditch$elm_bigint$BigInt$fromInt(e),
								A2($cmditch$elm_bigint$BigInt$mul, s, $cmditch$elm_bigint$BigInt$eightHexDigits));
						}),
					$cmditch$elm_bigint$BigInt$zero)),
			$elm_community$maybe_extra$Maybe$Extra$combine(
				A2(
					$elm$core$List$map,
					A2(
						$elm$core$Basics$composeR,
						$elm$core$List$reverse,
						A2(
							$elm$core$Basics$composeR,
							$elm$core$String$fromList,
							A2($elm$core$Basics$composeR, $rtfeldman$elm_hex$Hex$fromString, $elm$core$Result$toMaybe))),
					A2(
						$elm_community$list_extra$List$Extra$greedyGroupsOf,
						$cmditch$elm_bigint$Constants$hexDigitMagnitude,
						$elm$core$List$reverse(x)))));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $cmditch$elm_bigint$BigInt$fromHexString = function (x) {
	var _v0 = $elm$core$String$toList(
		$elm$core$String$toLower(x));
	_v0$9:
	while (true) {
		if (!_v0.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			switch (_v0.a) {
				case '-':
					if (_v0.b.b) {
						if ((('0' === _v0.b.a) && _v0.b.b.b) && ('x' === _v0.b.b.a)) {
							if (!_v0.b.b.b.b) {
								var _v1 = _v0.b;
								var _v2 = _v1.b;
								return $elm$core$Maybe$Nothing;
							} else {
								var _v3 = _v0.b;
								var _v4 = _v3.b;
								var xs = _v4.b;
								return A2(
									$elm$core$Maybe$map,
									$cmditch$elm_bigint$BigInt$mul(
										$cmditch$elm_bigint$BigInt$fromInt(-1)),
									$cmditch$elm_bigint$BigInt$fromHexString_(xs));
							}
						} else {
							var xs = _v0.b;
							return A2(
								$elm$core$Maybe$map,
								$cmditch$elm_bigint$BigInt$mul(
									$cmditch$elm_bigint$BigInt$fromInt(-1)),
								$cmditch$elm_bigint$BigInt$fromHexString_(xs));
						}
					} else {
						return $elm$core$Maybe$Nothing;
					}
				case '+':
					if (!_v0.b.b) {
						return $elm$core$Maybe$Nothing;
					} else {
						var xs = _v0.b;
						return $cmditch$elm_bigint$BigInt$fromHexString_(xs);
					}
				case '0':
					if (_v0.b.b && ('x' === _v0.b.a)) {
						if (!_v0.b.b.b) {
							var _v5 = _v0.b;
							return $elm$core$Maybe$Nothing;
						} else {
							var _v6 = _v0.b;
							var xs = _v6.b;
							return $cmditch$elm_bigint$BigInt$fromHexString_(xs);
						}
					} else {
						break _v0$9;
					}
				default:
					break _v0$9;
			}
		}
	}
	var xs = _v0;
	return $cmditch$elm_bigint$BigInt$fromHexString_(xs);
};
var $elm$core$Result$fromMaybe = F2(
	function (err, maybe) {
		if (!maybe.$) {
			var v = maybe.a;
			return $elm$core$Result$Ok(v);
		} else {
			return $elm$core$Result$Err(err);
		}
	});
var $cmditch$elm_ethereum$Eth$Abi$Decode$uint = function (_v0) {
	var original = _v0.a;
	var altered = _v0.b;
	return A2(
		$elm$core$Result$map,
		A2($cmditch$elm_ethereum$Eth$Abi$Decode$newTape, original, altered),
		A2(
			$elm$core$Result$fromMaybe,
			'Error Decoding Uint into BigInt',
			$cmditch$elm_bigint$BigInt$fromHexString(
				$cmditch$elm_ethereum$Eth$Utils$add0x(
					$cmditch$elm_ethereum$Eth$Utils$take64(altered)))));
};
var $author$project$Contracts$decodeAsset = A2(
	$cmditch$elm_ethereum$Eth$Abi$Decode$andMap,
	$cmditch$elm_ethereum$Eth$Abi$Decode$uint,
	A2(
		$cmditch$elm_ethereum$Eth$Abi$Decode$andMap,
		$cmditch$elm_ethereum$Eth$Abi$Decode$address,
		$cmditch$elm_ethereum$Eth$Abi$Decode$abiDecode($author$project$Contracts$NftAsset)));
var $cmditch$elm_ethereum$Eth$Abi$Decode$arrayHelp = F4(
	function (accum, len, _v0, tape) {
		var decoder = _v0;
		if (!len) {
			return $elm$core$Result$Ok(
				_Utils_Tuple2(
					tape,
					$elm$core$List$reverse(accum)));
		} else {
			var n = len;
			return A2(
				$elm$core$Result$andThen,
				function (_v2) {
					var tape_ = _v2.a;
					var val = _v2.b;
					return A4(
						$cmditch$elm_ethereum$Eth$Abi$Decode$arrayHelp,
						A2($elm$core$List$cons, val, accum),
						n - 1,
						decoder,
						tape_);
				},
				decoder(tape));
		}
	});
var $cmditch$elm_ethereum$Eth$Abi$Decode$parsePointer = function (_v0) {
	var altered = _v0.b;
	return A2(
		$elm$core$Result$map,
		$elm$core$Basics$mul(2),
		$rtfeldman$elm_hex$Hex$fromString(
			$cmditch$elm_ethereum$Eth$Utils$take64(altered)));
};
var $cmditch$elm_ethereum$Eth$Abi$Decode$dynamicArray = function (valDecoder) {
	return function (tape) {
		var original = tape.a;
		var altered = tape.b;
		var getArrayData = function (lengthIndex) {
			return A2(
				$elm$core$Result$map,
				function (arrayLength) {
					return _Utils_Tuple2(
						arrayLength,
						A2($elm$core$String$dropLeft, lengthIndex + 64, original));
				},
				$rtfeldman$elm_hex$Hex$fromString(
					A3($elm$core$String$slice, lengthIndex, lengthIndex + 64, original)));
		};
		return A2(
			$elm$core$Result$andThen,
			function (_v0) {
				var arrayLength = _v0.a;
				var rawArrayData = _v0.b;
				return A2(
					$elm$core$Result$map,
					function (_v1) {
						var _v2 = _v1.a;
						var arrayData = _v1.b;
						return _Utils_Tuple2(
							A2(
								$cmditch$elm_ethereum$Eth$Abi$Decode$Tape,
								original,
								$cmditch$elm_ethereum$Eth$Utils$drop64(altered)),
							arrayData);
					},
					A4(
						$cmditch$elm_ethereum$Eth$Abi$Decode$arrayHelp,
						_List_Nil,
						arrayLength,
						valDecoder,
						A2($cmditch$elm_ethereum$Eth$Abi$Decode$Tape, rawArrayData, rawArrayData)));
			},
			A2(
				$elm$core$Result$andThen,
				getArrayData,
				$cmditch$elm_ethereum$Eth$Abi$Decode$parsePointer(tape)));
	};
};
var $author$project$Contracts$decodeNft = A2(
	$cmditch$elm_ethereum$Eth$Abi$Decode$andMap,
	$cmditch$elm_ethereum$Eth$Abi$Decode$dynamicArray($author$project$Contracts$decodeAsset),
	A2(
		$cmditch$elm_ethereum$Eth$Abi$Decode$andMap,
		$cmditch$elm_ethereum$Eth$Abi$Decode$uint,
		$cmditch$elm_ethereum$Eth$Abi$Decode$abiDecode($author$project$Contracts$buildNft)));
var $cmditch$elm_ethereum$Internal$Types$Hex = $elm$core$Basics$identity;
var $cmditch$elm_ethereum$Eth$Abi$Encode$DPointerTo = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $cmditch$elm_ethereum$Eth$Abi$Encode$DValue = function (a) {
	return {$: 0, a: a};
};
var $cmditch$elm_ethereum$Eth$Abi$Encode$tillMod64 = function (n) {
	var _v0 = A2($elm$core$Basics$modBy, 64, n);
	if (!_v0) {
		return 0;
	} else {
		var n_ = _v0;
		return 64 - n_;
	}
};
var $cmditch$elm_ethereum$Eth$Abi$Encode$rightPadMod64 = function (str) {
	return _Utils_ap(
		str,
		A2(
			$elm$core$String$repeat,
			$cmditch$elm_ethereum$Eth$Abi$Encode$tillMod64(
				$elm$core$String$length(str)),
			'0'));
};
var $cmditch$elm_ethereum$Eth$Abi$Encode$uint = A2(
	$elm$core$Basics$composeR,
	$cmditch$elm_bigint$BigInt$toHexString,
	A2($elm$core$Basics$composeR, $cmditch$elm_ethereum$Eth$Utils$leftPadTo64, $cmditch$elm_ethereum$Eth$Abi$Encode$EValue));
var $cmditch$elm_ethereum$Eth$Abi$Encode$computeOneLayout = F3(
	function (blockId, toLayout, cnt) {
		computeOneLayout:
		while (true) {
			if (!toLayout.b) {
				return _Utils_Tuple3(cnt, _List_Nil, _List_Nil);
			} else {
				var i = toLayout.a;
				var is = toLayout.b;
				switch (i.$) {
					case 0:
						var x = i.a;
						var paddedValue = $cmditch$elm_ethereum$Eth$Abi$Encode$rightPadMod64(x);
						var _this = $cmditch$elm_ethereum$Eth$Abi$Encode$DValue(paddedValue);
						var _v2 = A3($cmditch$elm_ethereum$Eth$Abi$Encode$computeOneLayout, blockId, is, cnt);
						var newCnt = _v2.a;
						var nexts = _v2.b;
						var queue = _v2.c;
						return _Utils_Tuple3(
							newCnt,
							A2($elm$core$List$cons, _this, nexts),
							queue);
					case 4:
						var toData = i.a;
						var pointedId = cnt;
						var _this = A2($cmditch$elm_ethereum$Eth$Abi$Encode$DPointerTo, pointedId, blockId);
						var dataBlock = {
							bH: _List_fromArray(
								[toData]),
							d6: pointedId,
							aG: 0
						};
						var _v3 = A3($cmditch$elm_ethereum$Eth$Abi$Encode$computeOneLayout, blockId, is, cnt + 1);
						var newCnt = _v3.a;
						var nexts = _v3.b;
						var queue = _v3.c;
						return _Utils_Tuple3(
							newCnt,
							A2($elm$core$List$cons, _this, nexts),
							A2($elm$core$List$cons, dataBlock, queue));
					case 1:
						var hex = i.a;
						var bytesLen = $cmditch$elm_bigint$BigInt$fromInt(
							($elm$core$String$length(hex) / 2) | 0);
						var $temp$blockId = blockId,
							$temp$toLayout = A2(
							$elm$core$List$cons,
							$cmditch$elm_ethereum$Eth$Abi$Encode$uint(bytesLen),
							A2(
								$elm$core$List$cons,
								$cmditch$elm_ethereum$Eth$Abi$Encode$EValue(hex),
								is)),
							$temp$cnt = cnt;
						blockId = $temp$blockId;
						toLayout = $temp$toLayout;
						cnt = $temp$cnt;
						continue computeOneLayout;
					case 2:
						var listVals = i.a;
						var _this = A2($cmditch$elm_ethereum$Eth$Abi$Encode$DPointerTo, cnt, blockId);
						var listLen = $cmditch$elm_ethereum$Eth$Abi$Encode$uint(
							$cmditch$elm_bigint$BigInt$fromInt(
								$elm$core$List$length(listVals)));
						var listBody = {
							bH: A2($elm$core$List$cons, listLen, listVals),
							d6: cnt,
							aG: 32
						};
						var _v4 = A3($cmditch$elm_ethereum$Eth$Abi$Encode$computeOneLayout, blockId, is, cnt + 1);
						var newCnt = _v4.a;
						var nexts = _v4.b;
						var queue = _v4.c;
						return _Utils_Tuple3(
							newCnt,
							A2($elm$core$List$cons, _this, nexts),
							A2($elm$core$List$cons, listBody, queue));
					default:
						var vals = i.a;
						var $temp$blockId = blockId,
							$temp$toLayout = _Utils_ap(vals, is),
							$temp$cnt = cnt;
						blockId = $temp$blockId;
						toLayout = $temp$toLayout;
						cnt = $temp$cnt;
						continue computeOneLayout;
				}
			}
		}
	});
var $cmditch$elm_ethereum$Eth$Abi$Encode$computeLayout = F2(
	function (cnt, toLayout) {
		if (!toLayout.b) {
			return _List_Nil;
		} else {
			var b = toLayout.a;
			var bs = toLayout.b;
			var _v1 = A3($cmditch$elm_ethereum$Eth$Abi$Encode$computeOneLayout, b.d6, b.bH, cnt);
			var newCnt = _v1.a;
			var blockLayout = _v1.b;
			var queuedBlocks = _v1.c;
			var block = {bH: blockLayout, d6: b.d6, aG: b.aG};
			return A2(
				$elm$core$List$cons,
				block,
				A2(
					$cmditch$elm_ethereum$Eth$Abi$Encode$computeLayout,
					newCnt,
					_Utils_ap(bs, queuedBlocks)));
		}
	});
var $elm$core$Debug$log = _Debug_log;
var $cmditch$elm_ethereum$Eth$Abi$Encode$measureBlock = A2(
	$elm$core$List$foldl,
	F2(
		function (val, acc) {
			if (!val.$) {
				var str = val.a;
				return acc + (($elm$core$String$length(str) / 2) | 0);
			} else {
				return acc + 32;
			}
		}),
	0);
var $cmditch$elm_ethereum$Eth$Abi$Encode$measureLayout = function (blocks) {
	var _v0 = A3(
		$elm$core$List$foldl,
		F2(
			function (_v1, _v2) {
				var id = _v1.d6;
				var offset = _v1.aG;
				var data = _v1.bH;
				var thisPos = _v2.a;
				var prev = _v2.b;
				var posById = _v2.c;
				var newPos = thisPos + $cmditch$elm_ethereum$Eth$Abi$Encode$measureBlock(data);
				return _Utils_Tuple3(
					newPos,
					_Utils_ap(prev, data),
					A3(
						$elm$core$Dict$insert,
						id,
						_Utils_Tuple2(thisPos, thisPos + offset),
						posById));
			}),
		_Utils_Tuple3(0, _List_Nil, $elm$core$Dict$empty),
		blocks);
	var lst = _v0.b;
	var retPos = _v0.c;
	return _Utils_Tuple2(lst, retPos);
};
var $cmditch$elm_ethereum$Eth$Abi$Encode$layoutToHex = function (stack) {
	var _v0 = $cmditch$elm_ethereum$Eth$Abi$Encode$measureLayout(stack);
	var lst = _v0.a;
	var pos = _v0.b;
	var _v1 = A2($elm$core$Debug$log, 'Layout to hex', stack);
	return A2(
		$elm$core$Result$map,
		$elm$core$String$join(''),
		A2(
			$elm$core$Result$map,
			$elm$core$List$reverse,
			A3(
				$elm$core$List$foldl,
				F2(
					function (v, acc) {
						if (acc.$ === 1) {
							var e = acc.a;
							return $elm$core$Result$Err(e);
						} else {
							var prev = acc.a;
							if (!v.$) {
								var str = v.a;
								return $elm$core$Result$Ok(
									A2($elm$core$List$cons, str, prev));
							} else {
								var to = v.a;
								var from = v.b;
								var _v4 = _Utils_Tuple2(
									A2($elm$core$Dict$get, to, pos),
									A2($elm$core$Dict$get, from, pos));
								if ((!_v4.a.$) && (!_v4.b.$)) {
									var _v5 = _v4.a.a;
									var toPos = _v5.a;
									var _v6 = _v4.b.a;
									var fromPos = _v6.b;
									var encodedPtr = $cmditch$elm_ethereum$Eth$Utils$leftPadTo64(
										$cmditch$elm_bigint$BigInt$toHexString(
											$cmditch$elm_bigint$BigInt$fromInt(toPos - fromPos)));
									return $elm$core$Result$Ok(
										A2($elm$core$List$cons, encodedPtr, prev));
								} else {
									return $elm$core$Result$Err('LayoutToHex: Pointer not found');
								}
							}
						}
					}),
				$elm$core$Result$Ok(_List_Nil),
				lst)));
};
var $cmditch$elm_ethereum$Eth$Abi$Encode$abiEncodeList_ = function (data) {
	return $cmditch$elm_ethereum$Eth$Abi$Encode$layoutToHex(
		A2(
			$cmditch$elm_ethereum$Eth$Abi$Encode$computeLayout,
			2,
			_List_fromArray(
				[
					{bH: data, d6: 1, aG: 0}
				])));
};
var $cmditch$elm_ethereum$Eth$Abi$Encode$functionCall = F2(
	function (abiSig, args) {
		return A2(
			$elm$core$Result$map,
			function (calldata) {
				return _Utils_ap(
					$cmditch$elm_ethereum$Eth$Utils$remove0x(abiSig),
					calldata);
			},
			$cmditch$elm_ethereum$Eth$Abi$Encode$abiEncodeList_(args));
	});
var $cmditch$elm_ethereum$Eth$Utils$functionSig = function (fSig) {
	return A2(
		$elm$core$String$join,
		'',
		A2(
			$elm$core$List$map,
			A2($elm$core$Basics$composeR, $rtfeldman$elm_hex$Hex$toString, $cmditch$elm_ethereum$Eth$Utils$toByteLength),
			A2(
				$elm$core$List$take,
				4,
				$prozacchiwawa$elm_keccak$Keccak$Int$ethereum_keccak_256(
					A2(
						$elm$core$List$map,
						$elm$core$Char$toCode,
						$elm$core$String$toList(fSig))))));
};
var $author$project$Contracts$sig = function (fn) {
	return $cmditch$elm_ethereum$Eth$Utils$hexToString(
		$cmditch$elm_ethereum$Eth$Utils$functionSig(fn));
};
var $cmditch$elm_ethereum$Eth$Abi$Decode$struct = function (_v0) {
	var decoder = _v0;
	return function (tape) {
		var original = tape.a;
		var altered = tape.b;
		return A2(
			$elm$core$Result$map,
			function (_v1) {
				var _v2 = _v1.a;
				var structData = _v1.b;
				return _Utils_Tuple2(
					A2(
						$cmditch$elm_ethereum$Eth$Abi$Decode$Tape,
						original,
						$cmditch$elm_ethereum$Eth$Utils$drop64(altered)),
					structData);
			},
			A2(
				$elm$core$Result$andThen,
				function (rawStructData) {
					return decoder(
						A2($cmditch$elm_ethereum$Eth$Abi$Decode$Tape, rawStructData, rawStructData));
				},
				A2(
					$elm$core$Result$map,
					function (dataIndex) {
						return A2($elm$core$String$dropLeft, dataIndex, original);
					},
					$cmditch$elm_ethereum$Eth$Abi$Decode$parsePointer(tape))));
	};
};
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $cmditch$elm_ethereum$Eth$Abi$Decode$fromString = F2(
	function (_v0, abiString) {
		var abiDecoder = _v0;
		return A2(
			$elm$core$Result$map,
			$elm$core$Tuple$second,
			abiDecoder(
				function (a) {
					return A2($cmditch$elm_ethereum$Eth$Abi$Decode$Tape, a, a);
				}(
					$cmditch$elm_ethereum$Eth$Utils$remove0x(abiString))));
	});
var $cmditch$elm_ethereum$Eth$Abi$Decode$toElmDecoder = A2($elm$core$Basics$composeR, $cmditch$elm_ethereum$Eth$Abi$Decode$fromString, $cmditch$elm_ethereum$Eth$Decode$resultToDecoder);
var $author$project$Contracts$nftListCall_ = F2(
	function (net, owner) {
		return A2(
			$elm$core$Result$map,
			function (callData) {
				return {
					bH: $elm$core$Maybe$Just(callData),
					dV: $cmditch$elm_ethereum$Eth$Abi$Decode$toElmDecoder(
						$cmditch$elm_ethereum$Eth$Abi$Decode$dynamicArray(
							$cmditch$elm_ethereum$Eth$Abi$Decode$struct($author$project$Contracts$decodeNft))),
					bK: $elm$core$Maybe$Nothing,
					bM: $elm$core$Maybe$Nothing,
					bN: $elm$core$Maybe$Nothing,
					bU: $elm$core$Maybe$Nothing,
					b5: $elm$core$Maybe$Just(
						$author$project$Contracts$batcherAddress(net)),
					b9: $elm$core$Maybe$Nothing
				};
			},
			A2(
				$cmditch$elm_ethereum$Eth$Abi$Encode$functionCall,
				$author$project$Contracts$sig('getNfts(address)'),
				_List_fromArray(
					[
						$cmditch$elm_ethereum$Eth$Abi$Encode$address(owner)
					])));
	});
var $author$project$Utils$toTask = function (result) {
	if (!result.$) {
		var a = result.a;
		return $elm$core$Task$succeed(a);
	} else {
		var b = result.a;
		return $elm$core$Task$fail(b);
	}
};
var $author$project$Contracts$getNftList = F2(
	function (_v0, owner) {
		var http = _v0.a.d5;
		var id = _v0.a.d6;
		var v = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			$cmditch$elm_ethereum$Eth$call(http),
			$author$project$Utils$toTask(
				A2(
					$author$project$Utils$mapErr,
					$elm$http$Http$BadBody,
					A2(
						$author$project$Contracts$nftListCall_,
						_Utils_Tuple2(id, v),
						owner))));
	});
var $author$project$Liquidator$TxSentryMsg = function (a) {
	return {$: 4, a: a};
};
var $cmditch$elm_ethereum$Eth$Sentry$Tx$TxSentry = $elm$core$Basics$identity;
var $cmditch$elm_ethereum$Eth$Sentry$Tx$init = F3(
	function (_v0, tagger, nodePath) {
		var outPort = _v0.a;
		var inPort = _v0.b;
		return {cB: inPort, Y: nodePath, cU: outPort, o: 1, y: tagger, m: $elm$core$Dict$empty};
	});
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $author$project$Ports$txIn = _Platform_incomingPort('txIn', $elm$json$Json$Decode$value);
var $author$project$Ports$txOut = _Platform_outgoingPort('txOut', $elm$core$Basics$identity);
var $author$project$Liquidator$init = function (n) {
	return {
		a4: n,
		a5: $elm$core$Dict$empty,
		ad: A3(
			$cmditch$elm_ethereum$Eth$Sentry$Tx$init,
			_Utils_Tuple2($author$project$Ports$txOut, $author$project$Ports$txIn),
			$author$project$Liquidator$TxSentryMsg,
			n.a.d5)
	};
};
var $author$project$Connected$initFromNode = F3(
	function (con, v, node) {
		var address = $cmditch$elm_ethereum$Eth$Utils$unsafeToAddress(con.dD);
		return _Utils_Tuple2(
			{
				bw: address,
				ax: $author$project$Liquidator$init(
					_Utils_Tuple2(node, v)),
				X: $elm$core$Maybe$Nothing,
				aO: node,
				b8: v
			},
			A2(
				$elm$core$Task$attempt,
				$author$project$Connected$NftListResponse,
				A2(
					$author$project$Contracts$getNftList,
					_Utils_Tuple2(node, v),
					address)));
	});
var $author$project$Connected$init = F2(
	function (con, v) {
		return A2(
			$elm$core$Maybe$map,
			A2($author$project$Connected$initFromNode, con, v),
			$author$project$Constants$ethNode(
				$cmditch$elm_ethereum$Eth$Net$Private(con.ep)));
	});
var $elm$core$Platform$Cmd$map = _Platform_map;
var $author$project$Connected$AssetMsg = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var $author$project$Connected$NftModel = F2(
	function (raw, assets) {
		return {L: assets, bt: raw};
	});
var $author$project$Utils$coalesce = F2(
	function (a, ma) {
		if (ma.$ === 1) {
			return a;
		} else {
			var v = ma.a;
			return v;
		}
	});
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $author$project$Utils$Working = {$: 0};
var $author$project$Asset$LoadedToken = function (a) {
	return {$: 1, a: a};
};
var $author$project$Constants$apiUrl = 'https://api.nested.finance/graphql';
var $krisajenkins$remotedata$RemoteData$Failure = function (a) {
	return {$: 2, a: a};
};
var $krisajenkins$remotedata$RemoteData$Success = function (a) {
	return {$: 3, a: a};
};
var $krisajenkins$remotedata$RemoteData$fromResult = function (result) {
	if (result.$ === 1) {
		var e = result.a;
		return $krisajenkins$remotedata$RemoteData$Failure(e);
	} else {
		var x = result.a;
		return $krisajenkins$remotedata$RemoteData$Success(x);
	}
};
var $author$project$Constants$prefixOf = function (networkId) {
	switch (networkId) {
		case 0:
			return 'bsc';
		case 1:
			return 'avax';
		default:
			return 'poly';
	}
};
var $author$project$Nested$Scalar$ChainAddress = $elm$core$Basics$identity;
var $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present = function (a) {
	return {$: 0, a: a};
};
var $author$project$Asset$TokenData = F5(
	function (name, symbol, logo, quote, decimals) {
		return {cn: decimals, cI: logo, cN: name, ex: quote, dl: symbol};
	});
var $dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $dillonkearns$elm_graphql$Graphql$Document$Hash$HashData = F4(
	function (shift, seed, hash, charsProcessed) {
		return {ai: charsProcessed, d1: hash, ac: seed, aQ: shift};
	});
var $dillonkearns$elm_graphql$Graphql$Document$Hash$c1 = 3432918353;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$c2 = 461845907;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy = F2(
	function (b, a) {
		return ((a & 65535) * b) + ((((a >>> 16) * b) & 65535) << 16);
	});
var $dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy = F2(
	function (b, a) {
		return (a << b) | (a >>> (32 - b));
	});
var $dillonkearns$elm_graphql$Graphql$Document$Hash$finalize = function (data) {
	var acc = (!(!data.d1)) ? (data.ac ^ A2(
		$dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy,
		$dillonkearns$elm_graphql$Graphql$Document$Hash$c2,
		A2(
			$dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy,
			15,
			A2($dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy, $dillonkearns$elm_graphql$Graphql$Document$Hash$c1, data.d1)))) : data.ac;
	var h0 = acc ^ data.ai;
	var h1 = A2($dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy, 2246822507, h0 ^ (h0 >>> 16));
	var h2 = A2($dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy, 3266489909, h1 ^ (h1 >>> 13));
	return (h2 ^ (h2 >>> 16)) >>> 0;
};
var $elm$core$String$foldl = _String_foldl;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$mix = F2(
	function (h1, k1) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy,
			5,
			A2(
				$dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy,
				13,
				h1 ^ A2(
					$dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy,
					$dillonkearns$elm_graphql$Graphql$Document$Hash$c2,
					A2(
						$dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy,
						15,
						A2($dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy, $dillonkearns$elm_graphql$Graphql$Document$Hash$c1, k1))))) + 3864292196;
	});
var $dillonkearns$elm_graphql$Graphql$Document$Hash$hashFold = F2(
	function (c, data) {
		var res = data.d1 | ((255 & $elm$core$Char$toCode(c)) << data.aQ);
		var _v0 = data.aQ;
		if (_v0 === 24) {
			return {
				ai: data.ai + 1,
				d1: 0,
				ac: A2($dillonkearns$elm_graphql$Graphql$Document$Hash$mix, data.ac, res),
				aQ: 0
			};
		} else {
			return {ai: data.ai + 1, d1: res, ac: data.ac, aQ: data.aQ + 8};
		}
	});
var $dillonkearns$elm_graphql$Graphql$Document$Hash$hashString = F2(
	function (seed, str) {
		return $dillonkearns$elm_graphql$Graphql$Document$Hash$finalize(
			A3(
				$elm$core$String$foldl,
				$dillonkearns$elm_graphql$Graphql$Document$Hash$hashFold,
				A4($dillonkearns$elm_graphql$Graphql$Document$Hash$HashData, 0, seed, 0, 0),
				str));
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize = function (value) {
	switch (value.$) {
		case 0:
			var enumValue = value.a;
			return enumValue;
		case 1:
			var json = value.a;
			return A2($elm$json$Json$Encode$encode, 0, json);
		case 2:
			var values = value.a;
			return '[' + (A2(
				$elm$core$String$join,
				', ',
				A2($elm$core$List$map, $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize, values)) + ']');
		default:
			var keyValuePairs = value.a;
			return '{' + (A2(
				$elm$core$String$join,
				', ',
				A2(
					$elm$core$List$map,
					function (_v1) {
						var key = _v1.a;
						var objectValue = _v1.b;
						return key + (': ' + $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize(objectValue));
					},
					keyValuePairs)) + '}');
	}
};
var $dillonkearns$elm_graphql$Graphql$Document$Argument$argToString = function (_v0) {
	var name = _v0.a;
	var value = _v0.b;
	return name + (': ' + $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize(value));
};
var $dillonkearns$elm_graphql$Graphql$Document$Argument$serialize = function (args) {
	if (!args.b) {
		return '';
	} else {
		var nonemptyArgs = args;
		return '(' + (A2(
			$elm$core$String$join,
			', ',
			A2($elm$core$List$map, $dillonkearns$elm_graphql$Graphql$Document$Argument$argToString, nonemptyArgs)) + ')');
	}
};
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$maybeAliasHash = function (field) {
	return A2(
		$elm$core$Maybe$map,
		$dillonkearns$elm_graphql$Graphql$Document$Hash$hashString(0),
		function () {
			if (!field.$) {
				var name = field.a;
				var _arguments = field.b;
				var children = field.c;
				return $elm$core$List$isEmpty(_arguments) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(_arguments));
			} else {
				var typeString = field.a.eT;
				var fieldName = field.a.ct;
				var _arguments = field.b;
				return (fieldName === '__typename') ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
					$elm$core$String$concat(
						A2(
							$elm$core$List$append,
							_List_fromArray(
								[typeString]),
							$elm$core$List$singleton(
								$dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(_arguments)))));
			}
		}());
};
var $dillonkearns$elm_graphql$Graphql$RawField$name = function (field) {
	if (!field.$) {
		var fieldName = field.a;
		var argumentList = field.b;
		var fieldList = field.c;
		return fieldName;
	} else {
		var typeString = field.a.eT;
		var fieldName = field.a.ct;
		var argumentList = field.b;
		return fieldName;
	}
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$alias = function (field) {
	return A2(
		$elm$core$Maybe$map,
		function (aliasHash) {
			return _Utils_ap(
				$dillonkearns$elm_graphql$Graphql$RawField$name(field),
				$elm$core$String$fromInt(aliasHash));
		},
		$dillonkearns$elm_graphql$Graphql$Document$Field$maybeAliasHash(field));
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName = function (field) {
	return A2(
		$elm$core$Maybe$withDefault,
		$dillonkearns$elm_graphql$Graphql$RawField$name(field),
		$dillonkearns$elm_graphql$Graphql$Document$Field$alias(field));
};
var $dillonkearns$elm_graphql$Graphql$RawField$Leaf = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$leaf = F2(
	function (details, args) {
		return A2($dillonkearns$elm_graphql$Graphql$RawField$Leaf, details, args);
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField = F4(
	function (typeString, fieldName, args, decoder) {
		var newLeaf = A2(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$leaf,
			{ct: fieldName, eT: typeString},
			args);
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			_List_fromArray(
				[newLeaf]),
			A2(
				$elm$json$Json$Decode$field,
				$dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName(newLeaf),
				decoder));
	});
var $author$project$Nested$Object$TokenInfo$decimals = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Int', 'decimals', _List_Nil, $elm$json$Json$Decode$int);
var $elm$json$Json$Decode$nullable = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder)
			]));
};
var $author$project$Nested$Object$TokenInfo$logo = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'logo',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$json$Json$Decode$map5 = _Json_map5;
var $dillonkearns$elm_graphql$Graphql$SelectionSet$map5 = F6(
	function (combine, _v0, _v1, _v2, _v3, _v4) {
		var selectionFields1 = _v0.a;
		var selectionDecoder1 = _v0.b;
		var selectionFields2 = _v1.a;
		var selectionDecoder2 = _v1.b;
		var selectionFields3 = _v2.a;
		var selectionDecoder3 = _v2.b;
		var selectionFields4 = _v3.a;
		var selectionDecoder4 = _v3.b;
		var selectionFields5 = _v4.a;
		var selectionDecoder5 = _v4.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			$elm$core$List$concat(
				_List_fromArray(
					[selectionFields1, selectionFields2, selectionFields3, selectionFields4, selectionFields5])),
			A6($elm$json$Json$Decode$map5, combine, selectionDecoder1, selectionDecoder2, selectionDecoder3, selectionDecoder4, selectionDecoder5));
	});
var $author$project$Nested$Object$TokenInfo$name = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'name', _List_Nil, $elm$json$Json$Decode$string);
var $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent = {$: 1};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Encode$bool = _Json_wrap;
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool = function (value) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json(
		$elm$json$Json$Encode$bool(value));
};
var $author$project$Nested$Scalar$DateTime = $elm$core$Basics$identity;
var $author$project$Nested$Scalar$HexNumber = $elm$core$Basics$identity;
var $author$project$Nested$Scalar$HexString = $elm$core$Basics$identity;
var $author$project$Nested$Scalar$LocalizedString = $elm$core$Basics$identity;
var $author$project$Nested$Scalar$NftId = $elm$core$Basics$identity;
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $elm$core$String$fromFloat = _String_fromNumber;
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			$elm$json$Json$Decode$string,
			A2($elm$json$Json$Decode$map, $elm$core$String$fromFloat, $elm$json$Json$Decode$float),
			A2($elm$json$Json$Decode$map, $elm$core$String$fromInt, $elm$json$Json$Decode$int),
			A2(
			$elm$json$Json$Decode$map,
			function (bool) {
				if (bool) {
					return 'true';
				} else {
					return 'false';
				}
			},
			$elm$json$Json$Decode$bool)
		]));
var $author$project$Nested$Scalar$defaultCodecs = {
	bz: {
		dV: A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		S: function (_v0) {
			var raw = _v0;
			return $elm$json$Json$Encode$string(raw);
		}
	},
	ci: {
		dV: A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		S: function (_v1) {
			var raw = _v1;
			return $elm$json$Json$Encode$string(raw);
		}
	},
	cj: {
		dV: A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		S: function (_v2) {
			var raw = _v2;
			return $elm$json$Json$Encode$string(raw);
		}
	},
	ck: {
		dV: A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		S: function (_v3) {
			var raw = _v3;
			return $elm$json$Json$Encode$string(raw);
		}
	},
	dL: {
		dV: A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		S: function (_v4) {
			var raw = _v4;
			return $elm$json$Json$Encode$string(raw);
		}
	},
	dM: {
		dV: A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		S: function (_v5) {
			var raw = _v5;
			return $elm$json$Json$Encode$string(raw);
		}
	}
};
var $author$project$Nested$Scalar$Codecs = $elm$core$Basics$identity;
var $author$project$Nested$Scalar$defineCodecs = function (definitions) {
	return definitions;
};
var $author$project$Nested$ScalarCodecs$codecs = $author$project$Nested$Scalar$defineCodecs(
	{bz: $author$project$Nested$Scalar$defaultCodecs.bz, ci: $author$project$Nested$Scalar$defaultCodecs.ci, cj: $author$project$Nested$Scalar$defaultCodecs.cj, ck: $author$project$Nested$Scalar$defaultCodecs.ck, dL: $author$project$Nested$Scalar$defaultCodecs.dL, dM: $author$project$Nested$Scalar$defaultCodecs.dM});
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$EnumValue = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$enum = F2(
	function (enumToString, value) {
		return $dillonkearns$elm_graphql$Graphql$Internal$Encode$EnumValue(
			enumToString(value));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$Argument = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$null = $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json($elm$json$Json$Encode$null);
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional = F3(
	function (fieldName, maybeValue, toValue) {
		switch (maybeValue.$) {
			case 0:
				var value = maybeValue.a;
				return $elm$core$Maybe$Just(
					A2(
						$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$Argument,
						fieldName,
						toValue(value)));
			case 1:
				return $elm$core$Maybe$Nothing;
			default:
				return $elm$core$Maybe$Just(
					A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$Argument, fieldName, $dillonkearns$elm_graphql$Graphql$Internal$Encode$null));
		}
	});
var $author$project$Nested$Enum$Currency$toString = function (enum____) {
	if (!enum____) {
		return 'eur';
	} else {
		return 'usd';
	}
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$fromJson = function (jsonValue) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json(jsonValue);
};
var $author$project$Nested$Scalar$unwrapEncoder = F2(
	function (getter, _v0) {
		var unwrappedCodecs = _v0;
		return A2(
			$elm$core$Basics$composeR,
			getter(unwrappedCodecs).S,
			$dillonkearns$elm_graphql$Graphql$Internal$Encode$fromJson);
	});
var $author$project$Nested$Object$TokenInfo$quote = function (fillInOptionals____) {
	var filledInOptionals____ = fillInOptionals____(
		{bx: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, al: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, bL: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
	var optionalArgs____ = A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		_List_fromArray(
			[
				A3(
				$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
				'at',
				filledInOptionals____.bx,
				A2(
					$author$project$Nested$Scalar$unwrapEncoder,
					function ($) {
						return $.ci;
					},
					$author$project$Nested$ScalarCodecs$codecs)),
				A3(
				$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
				'currency',
				filledInOptionals____.al,
				$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Nested$Enum$Currency$toString)),
				A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'fromSpot', filledInOptionals____.bL, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)
			]));
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Float', 'quote', optionalArgs____, $elm$json$Json$Decode$float);
};
var $author$project$Nested$Object$TokenInfo$symbol = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'symbol', _List_Nil, $elm$json$Json$Decode$string);
var $dillonkearns$elm_graphql$Graphql$RawField$Composite = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$composite = F3(
	function (fieldName, args, fields) {
		return A3($dillonkearns$elm_graphql$Graphql$RawField$Composite, fieldName, args, fields);
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField = F4(
	function (fieldName, args, _v0, decoderTransform) {
		var fields = _v0.a;
		var decoder = _v0.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$composite, fieldName, args, fields)
				]),
			A2(
				$elm$json$Json$Decode$field,
				$dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName(
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$composite, fieldName, args, fields)),
				decoderTransform(decoder)));
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$string = function (value) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json(
		$elm$json$Json$Encode$string(value));
};
var $author$project$Nested$Enum$Chain$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'avax';
		case 1:
			return 'bsc';
		case 2:
			return 'eth';
		case 3:
			return 'poly';
		default:
			return 'rop';
	}
};
var $author$project$Nested$Query$token = F2(
	function (fillInOptionals____, object____) {
		var filledInOptionals____ = fillInOptionals____(
			{ah: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, d6: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, dl: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
		var optionalArgs____ = A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'chain',
					filledInOptionals____.ah,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Nested$Enum$Chain$toString)),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'id',
					filledInOptionals____.d6,
					A2(
						$author$project$Nested$Scalar$unwrapEncoder,
						function ($) {
							return $.bz;
						},
						$author$project$Nested$ScalarCodecs$codecs)),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'symbol', filledInOptionals____.dl, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)
				]));
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'token',
			optionalArgs____,
			object____,
			A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
	});
var $author$project$Asset$query = function (token) {
	return A2(
		$author$project$Nested$Query$token,
		function (t) {
			return _Utils_update(
				t,
				{
					d6: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(token)
				});
		},
		A6(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$map5,
			$author$project$Asset$TokenData,
			$author$project$Nested$Object$TokenInfo$name,
			$author$project$Nested$Object$TokenInfo$symbol,
			$author$project$Nested$Object$TokenInfo$logo,
			$author$project$Nested$Object$TokenInfo$quote($elm$core$Basics$identity),
			$author$project$Nested$Object$TokenInfo$decimals));
};
var $dillonkearns$elm_graphql$Graphql$Http$Query = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Http$Request = $elm$core$Basics$identity;
var $dillonkearns$elm_graphql$Graphql$Document$decoder = function (_v0) {
	var fields = _v0.a;
	var decoder_ = _v0.b;
	return A2($elm$json$Json$Decode$field, 'data', decoder_);
};
var $dillonkearns$elm_graphql$Graphql$Http$queryRequest = F2(
	function (baseUrl, query) {
		return {
			aZ: baseUrl,
			bk: A2($dillonkearns$elm_graphql$Graphql$Http$Query, $elm$core$Maybe$Nothing, query),
			an: $dillonkearns$elm_graphql$Graphql$Document$decoder(query),
			d2: _List_Nil,
			Z: $elm$core$Maybe$Nothing,
			aa: _List_Nil,
			eM: $elm$core$Maybe$Nothing,
			af: false
		};
	});
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {c7: reqs, dk: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.dr;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.c7));
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.dk)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					dE: r.dE,
					dG: r.dG,
					an: A2(_Http_mapExpect, func, r.an),
					d2: r.d2,
					ej: r.ej,
					eM: r.eM,
					dr: r.dr,
					eW: r.eW
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{dE: false, dG: r.dG, an: r.an, d2: r.d2, ej: r.ej, eM: r.eM, dr: r.dr, eW: r.eW}));
};
var $elm$http$Http$riskyRequest = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{dE: true, dG: r.dG, an: r.an, d2: r.d2, ej: r.ej, eM: r.eM, dr: r.dr, eW: r.eW}));
};
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Http$HttpError = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$convertResult = function (httpResult) {
	if (!httpResult.$) {
		var successOrError = httpResult.a;
		if (!successOrError.$) {
			var value = successOrError.a;
			return $elm$core$Result$Ok(value);
		} else {
			var _v2 = successOrError.a;
			var possiblyParsedData = _v2.a;
			var error = _v2.b;
			return $elm$core$Result$Err(
				A2($dillonkearns$elm_graphql$Graphql$Http$GraphqlError, possiblyParsedData, error));
		}
	} else {
		var httpError = httpResult.a;
		return $elm$core$Result$Err(
			$dillonkearns$elm_graphql$Graphql$Http$HttpError(httpError));
	}
};
var $dillonkearns$elm_graphql$Graphql$Http$BadPayload = function (a) {
	return {$: 4, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$BadStatus = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$NetworkError = {$: 2};
var $dillonkearns$elm_graphql$Graphql$Http$Timeout = {$: 1};
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $dillonkearns$elm_graphql$Graphql$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			function (response) {
				switch (response.$) {
					case 0:
						var url = response.a;
						return $elm$core$Result$Err(
							$dillonkearns$elm_graphql$Graphql$Http$BadUrl(url));
					case 1:
						return $elm$core$Result$Err($dillonkearns$elm_graphql$Graphql$Http$Timeout);
					case 2:
						return $elm$core$Result$Err($dillonkearns$elm_graphql$Graphql$Http$NetworkError);
					case 3:
						var metadata = response.a;
						var body = response.b;
						return $elm$core$Result$Err(
							A2($dillonkearns$elm_graphql$Graphql$Http$BadStatus, metadata, body));
					default:
						var metadata = response.a;
						var body = response.b;
						var _v1 = A2($elm$json$Json$Decode$decodeString, decoder, body);
						if (!_v1.$) {
							var value = _v1.a;
							return $elm$core$Result$Ok(value);
						} else {
							var err = _v1.a;
							return $elm$core$Result$Err(
								$dillonkearns$elm_graphql$Graphql$Http$BadPayload(err));
						}
				}
			});
	});
var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$Get = 0;
var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$Post = 1;
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$maxLength = 2000;
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$String$dropRight = F2(
	function (n, string) {
		return (n < 1) ? string : A3($elm$core$String$slice, 0, -n, string);
	});
var $lukewestby$elm_string_interpolate$String$Interpolate$applyInterpolation = F2(
	function (replacements, _v0) {
		var match = _v0.eh;
		var ordinalString = A2(
			$elm$core$Basics$composeL,
			$elm$core$String$dropLeft(1),
			$elm$core$String$dropRight(1))(match);
		return A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$andThen,
				function (value) {
					return A2($elm$core$Array$get, value, replacements);
				},
				$elm$core$String$toInt(ordinalString)));
	});
var $elm$core$Array$fromListHelp = F3(
	function (list, nodeList, nodeListSize) {
		fromListHelp:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, list);
			var jsArray = _v0.a;
			var remainingItems = _v0.b;
			if (_Utils_cmp(
				$elm$core$Elm$JsArray$length(jsArray),
				$elm$core$Array$branchFactor) < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					true,
					{h: nodeList, f: nodeListSize, g: jsArray});
			} else {
				var $temp$list = remainingItems,
					$temp$nodeList = A2(
					$elm$core$List$cons,
					$elm$core$Array$Leaf(jsArray),
					nodeList),
					$temp$nodeListSize = nodeListSize + 1;
				list = $temp$list;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue fromListHelp;
			}
		}
	});
var $elm$core$Array$fromList = function (list) {
	if (!list.b) {
		return $elm$core$Array$empty;
	} else {
		return A3($elm$core$Array$fromListHelp, list, _List_Nil, 0);
	}
};
var $lukewestby$elm_string_interpolate$String$Interpolate$interpolationRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('\\{\\d+\\}'));
var $elm$regex$Regex$replace = _Regex_replaceAtMost(_Regex_infinity);
var $lukewestby$elm_string_interpolate$String$Interpolate$interpolate = F2(
	function (string, args) {
		var asArray = $elm$core$Array$fromList(args);
		return A3(
			$elm$regex$Regex$replace,
			$lukewestby$elm_string_interpolate$String$Interpolate$interpolationRegex,
			$lukewestby$elm_string_interpolate$String$Interpolate$applyInterpolation(asArray),
			string);
	});
var $dillonkearns$elm_graphql$Graphql$Document$Indent$spaces = function (n) {
	return (n > 0) ? (' ' + $dillonkearns$elm_graphql$Graphql$Document$Indent$spaces(n - 1)) : '';
};
var $dillonkearns$elm_graphql$Graphql$Document$Indent$generate = function (indentationLevel) {
	return $dillonkearns$elm_graphql$Graphql$Document$Indent$spaces(indentationLevel * 2);
};
var $j_maas$elm_ordered_containers$OrderedDict$OrderedDict = $elm$core$Basics$identity;
var $j_maas$elm_ordered_containers$OrderedDict$empty = {e: $elm$core$Dict$empty, aI: _List_Nil};
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $j_maas$elm_ordered_containers$OrderedDict$insert = F3(
	function (key, value, _v0) {
		var orderedDict = _v0;
		var filteredOrder = A2($elm$core$Dict$member, key, orderedDict.e) ? A2(
			$elm$core$List$filter,
			function (k) {
				return !_Utils_eq(k, key);
			},
			orderedDict.aI) : orderedDict.aI;
		var newOrder = _Utils_ap(
			filteredOrder,
			_List_fromArray(
				[key]));
		return {
			e: A3($elm$core$Dict$insert, key, value, orderedDict.e),
			aI: newOrder
		};
	});
var $j_maas$elm_ordered_containers$OrderedDict$remove = F2(
	function (key, _v0) {
		var orderedDict = _v0;
		return {
			e: A2($elm$core$Dict$remove, key, orderedDict.e),
			aI: A2($elm$core$Dict$member, key, orderedDict.e) ? A2(
				$elm$core$List$filter,
				function (k) {
					return !_Utils_eq(k, key);
				},
				orderedDict.aI) : orderedDict.aI
		};
	});
var $j_maas$elm_ordered_containers$OrderedDict$update = F3(
	function (key, alter, original) {
		var orderedDict = original;
		var _v0 = A2($elm$core$Dict$get, key, orderedDict.e);
		if (!_v0.$) {
			var oldItem = _v0.a;
			var _v1 = alter(
				$elm$core$Maybe$Just(oldItem));
			if (!_v1.$) {
				var newItem = _v1.a;
				return {
					e: A3(
						$elm$core$Dict$update,
						key,
						$elm$core$Basics$always(
							$elm$core$Maybe$Just(newItem)),
						orderedDict.e),
					aI: orderedDict.aI
				};
			} else {
				return A2($j_maas$elm_ordered_containers$OrderedDict$remove, key, original);
			}
		} else {
			var _v2 = alter($elm$core$Maybe$Nothing);
			if (!_v2.$) {
				var newItem = _v2.a;
				return A3($j_maas$elm_ordered_containers$OrderedDict$insert, key, newItem, original);
			} else {
				return original;
			}
		}
	});
var $dillonkearns$elm_graphql$Graphql$Document$Field$mergeFields = function (rawFields) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (field, mergedSoFar) {
				if (!field.$) {
					var newChildren = field.c;
					return A3(
						$j_maas$elm_ordered_containers$OrderedDict$update,
						$dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName(field),
						function (maybeChildrenSoFar) {
							if (maybeChildrenSoFar.$ === 1) {
								return $elm$core$Maybe$Just(field);
							} else {
								if (!maybeChildrenSoFar.a.$) {
									var _v2 = maybeChildrenSoFar.a;
									var existingFieldName = _v2.a;
									var existingArgs = _v2.b;
									var existingChildren = _v2.c;
									return $elm$core$Maybe$Just(
										A3(
											$dillonkearns$elm_graphql$Graphql$RawField$Composite,
											existingFieldName,
											existingArgs,
											_Utils_ap(existingChildren, newChildren)));
								} else {
									return $elm$core$Maybe$Just(field);
								}
							}
						},
						mergedSoFar);
				} else {
					return A3(
						$j_maas$elm_ordered_containers$OrderedDict$update,
						$dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName(field),
						function (maybeChildrenSoFar) {
							return $elm$core$Maybe$Just(
								A2($elm$core$Maybe$withDefault, field, maybeChildrenSoFar));
						},
						mergedSoFar);
				}
			}),
		$j_maas$elm_ordered_containers$OrderedDict$empty,
		rawFields);
};
var $j_maas$elm_ordered_containers$OrderedDict$values = function (_v0) {
	var orderedDict = _v0;
	return A2(
		$elm$core$List$filterMap,
		function (key) {
			return A2($elm$core$Dict$get, key, orderedDict.e);
		},
		orderedDict.aI);
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$mergedFields = function (children) {
	return $j_maas$elm_ordered_containers$OrderedDict$values(
		$dillonkearns$elm_graphql$Graphql$Document$Field$mergeFields(children));
};
var $dillonkearns$elm_graphql$Graphql$RawField$typename = A2(
	$dillonkearns$elm_graphql$Graphql$RawField$Leaf,
	{ct: '__typename', eT: ''},
	_List_Nil);
var $dillonkearns$elm_graphql$Graphql$Document$Field$nonemptyChildren = function (children) {
	return $elm$core$List$isEmpty(children) ? A2($elm$core$List$cons, $dillonkearns$elm_graphql$Graphql$RawField$typename, children) : children;
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$serialize = F3(
	function (aliasName, mIndentationLevel, field) {
		var prefix = function () {
			if (!aliasName.$) {
				var aliasName_ = aliasName.a;
				return _Utils_ap(
					aliasName_,
					function () {
						if (!mIndentationLevel.$) {
							return ': ';
						} else {
							return ':';
						}
					}());
			} else {
				return '';
			}
		}();
		return A2(
			$elm$core$Maybe$map,
			function (string) {
				return _Utils_ap(
					$dillonkearns$elm_graphql$Graphql$Document$Indent$generate(
						A2($elm$core$Maybe$withDefault, 0, mIndentationLevel)),
					_Utils_ap(prefix, string));
			},
			function () {
				if (!field.$) {
					var fieldName = field.a;
					var args = field.b;
					var children = field.c;
					if (mIndentationLevel.$ === 1) {
						return $elm$core$Maybe$Just(
							(fieldName + ($dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(args) + ('{' + A2($dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren, $elm$core$Maybe$Nothing, children)))) + '}');
					} else {
						var indentationLevel = mIndentationLevel.a;
						return $elm$core$Maybe$Just(
							(fieldName + ($dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(args) + (' {\n' + A2(
								$dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren,
								$elm$core$Maybe$Just(indentationLevel),
								children)))) + ('\n' + ($dillonkearns$elm_graphql$Graphql$Document$Indent$generate(indentationLevel) + '}')));
					}
				} else {
					var fieldName = field.a.ct;
					var args = field.b;
					return $elm$core$Maybe$Just(
						_Utils_ap(
							fieldName,
							$dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(args)));
				}
			}());
	});
var $dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren = F2(
	function (indentationLevel, children) {
		return A2(
			$elm$core$String$join,
			function () {
				if (!indentationLevel.$) {
					return '\n';
				} else {
					return ' ';
				}
			}(),
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				A2(
					$elm$core$List$map,
					function (field) {
						return A3(
							$dillonkearns$elm_graphql$Graphql$Document$Field$serialize,
							$dillonkearns$elm_graphql$Graphql$Document$Field$alias(field),
							A2(
								$elm$core$Maybe$map,
								$elm$core$Basics$add(1),
								indentationLevel),
							field);
					},
					$dillonkearns$elm_graphql$Graphql$Document$Field$nonemptyChildren(
						$dillonkearns$elm_graphql$Graphql$Document$Field$mergedFields(children)))));
	});
var $dillonkearns$elm_graphql$Graphql$Document$serialize = F2(
	function (operationType, queries) {
		return A2(
			$lukewestby$elm_string_interpolate$String$Interpolate$interpolate,
			'{0} {\n{1}\n}',
			_List_fromArray(
				[
					operationType,
					A2(
					$dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren,
					$elm$core$Maybe$Just(0),
					queries)
				]));
	});
var $dillonkearns$elm_graphql$Graphql$Document$serializeQuery = function (_v0) {
	var fields = _v0.a;
	var decoder_ = _v0.b;
	return A2($dillonkearns$elm_graphql$Graphql$Document$serialize, 'query', fields);
};
var $dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrl = function (_v0) {
	var fields = _v0.a;
	var decoder_ = _v0.b;
	return '{' + (A2($dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren, $elm$core$Maybe$Nothing, fields) + '}');
};
var $dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrlWithOperationName = F2(
	function (operationName, _v0) {
		var fields = _v0.a;
		var decoder_ = _v0.b;
		return 'query ' + (operationName + (' {' + (A2($dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren, $elm$core$Maybe$Nothing, fields) + '}')));
	});
var $dillonkearns$elm_graphql$Graphql$Document$serializeWithOperationName = F3(
	function (operationType, operationName, queries) {
		return A2(
			$lukewestby$elm_string_interpolate$String$Interpolate$interpolate,
			'{0} {1} {\n{2}\n}',
			_List_fromArray(
				[
					operationType,
					operationName,
					A2(
					$dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren,
					$elm$core$Maybe$Just(0),
					queries)
				]));
	});
var $dillonkearns$elm_graphql$Graphql$Document$serializeQueryWithOperationName = F2(
	function (operationName, _v0) {
		var fields = _v0.a;
		var decoder_ = _v0.b;
		return A3($dillonkearns$elm_graphql$Graphql$Document$serializeWithOperationName, 'query', operationName, fields);
	});
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$replace = F2(
	function (old, _new) {
		return A2(
			$elm$core$Basics$composeR,
			$elm$core$String$split(old),
			$elm$core$String$join(_new));
	});
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryEscape = A2(
	$elm$core$Basics$composeR,
	$elm$url$Url$percentEncode,
	A2($dillonkearns$elm_graphql$Graphql$Http$QueryParams$replace, '%20', '+'));
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryEscape(key) + ('=' + $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryEscape(value));
};
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$joinUrlEncoded = function (args) {
	return A2(
		$elm$core$String$join,
		'&',
		A2($elm$core$List$map, $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryPair, args));
};
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams = F2(
	function (queryParams, url) {
		return $elm$core$List$isEmpty(queryParams) ? url : (url + ('?' + $dillonkearns$elm_graphql$Graphql$Http$QueryParams$joinUrlEncoded(queryParams)));
	});
var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$build = F5(
	function (forceMethod, url, queryParams, maybeOperationName, queryDocument) {
		var _v0 = function () {
			if (!maybeOperationName.$) {
				var operationName = maybeOperationName.a;
				return _Utils_Tuple2(
					A2($dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrlWithOperationName, operationName, queryDocument),
					_List_fromArray(
						[
							_Utils_Tuple2('operationName', operationName)
						]));
			} else {
				return _Utils_Tuple2(
					$dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrl(queryDocument),
					_List_Nil);
			}
		}();
		var serializedQueryForGetRequest = _v0.a;
		var operationNameParamForGetRequest = _v0.b;
		var urlForGetRequest = A2(
			$dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams,
			_Utils_ap(
				queryParams,
				_Utils_ap(
					_List_fromArray(
						[
							_Utils_Tuple2('query', serializedQueryForGetRequest)
						]),
					operationNameParamForGetRequest)),
			url);
		if (_Utils_eq(
			forceMethod,
			$elm$core$Maybe$Just(1)) || ((_Utils_cmp(
			$elm$core$String$length(urlForGetRequest),
			$dillonkearns$elm_graphql$Graphql$Http$QueryHelper$maxLength) > -1) && (!_Utils_eq(
			forceMethod,
			$elm$core$Maybe$Just(0))))) {
			var _v2 = function () {
				if (!maybeOperationName.$) {
					var operationName = maybeOperationName.a;
					return _Utils_Tuple2(
						A2($dillonkearns$elm_graphql$Graphql$Document$serializeQueryWithOperationName, operationName, queryDocument),
						_List_fromArray(
							[
								_Utils_Tuple2(
								'operationName',
								$elm$json$Json$Encode$string(operationName))
							]));
				} else {
					return _Utils_Tuple2(
						$dillonkearns$elm_graphql$Graphql$Document$serializeQuery(queryDocument),
						_List_Nil);
				}
			}();
			var serializedQuery = _v2.a;
			var operationNameParamForPostRequest = _v2.b;
			return {
				dG: $elm$http$Http$jsonBody(
					$elm$json$Json$Encode$object(
						_Utils_ap(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'query',
									$elm$json$Json$Encode$string(serializedQuery))
								]),
							operationNameParamForPostRequest))),
				ej: 1,
				eW: A2($dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams, queryParams, url)
			};
		} else {
			return {dG: $elm$http$Http$emptyBody, ej: 0, eW: urlForGetRequest};
		}
	});
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$ParsedData = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$UnparsedData = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$GraphqlError = F3(
	function (message, locations, details) {
		return {bk: details, ef: locations, cJ: message};
	});
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $elm$json$Json$Decode$dict = function (decoder) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Dict$fromList,
		$elm$json$Json$Decode$keyValuePairs(decoder));
};
var $elm$json$Json$Decode$list = _Json_decodeList;
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$Location = F2(
	function (line, column) {
		return {dN: column, ee: line};
	});
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$locationDecoder = A3(
	$elm$json$Json$Decode$map2,
	$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$Location,
	A2($elm$json$Json$Decode$field, 'line', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'column', $elm$json$Json$Decode$int));
var $elm$json$Json$Decode$map3 = _Json_map3;
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$decoder = A2(
	$elm$json$Json$Decode$field,
	'errors',
	$elm$json$Json$Decode$list(
		A4(
			$elm$json$Json$Decode$map3,
			$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$GraphqlError,
			A2($elm$json$Json$Decode$field, 'message', $elm$json$Json$Decode$string),
			$elm$json$Json$Decode$maybe(
				A2(
					$elm$json$Json$Decode$field,
					'locations',
					$elm$json$Json$Decode$list($dillonkearns$elm_graphql$Graphql$Http$GraphqlError$locationDecoder))),
			A2(
				$elm$json$Json$Decode$map,
				$elm$core$Dict$remove('locations'),
				A2(
					$elm$json$Json$Decode$map,
					$elm$core$Dict$remove('message'),
					$elm$json$Json$Decode$dict($elm$json$Json$Decode$value))))));
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData = function (data) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Result$Err,
		A2(
			$elm$json$Json$Decode$map,
			$elm$core$Tuple$pair(data),
			$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$decoder));
};
var $dillonkearns$elm_graphql$Graphql$Http$nullJsonValue = function (a) {
	nullJsonValue:
	while (true) {
		var _v0 = A2($elm$json$Json$Decode$decodeString, $elm$json$Json$Decode$value, 'null');
		if (!_v0.$) {
			var value = _v0.a;
			return value;
		} else {
			var $temp$a = 0;
			a = $temp$a;
			continue nullJsonValue;
		}
	}
};
var $dillonkearns$elm_graphql$Graphql$Http$errorDecoder = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$json$Json$Decode$andThen,
				$dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData,
				A2($elm$json$Json$Decode$map, $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$ParsedData, decoder)),
				A2(
				$elm$json$Json$Decode$andThen,
				$dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData,
				A2(
					$elm$json$Json$Decode$map,
					$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$UnparsedData,
					A2($elm$json$Json$Decode$field, 'data', $elm$json$Json$Decode$value))),
				A2(
				$elm$json$Json$Decode$andThen,
				$dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData,
				$elm$json$Json$Decode$succeed(
					$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$UnparsedData(
						$dillonkearns$elm_graphql$Graphql$Http$nullJsonValue(0))))
			]));
};
var $dillonkearns$elm_graphql$Graphql$Http$decoderOrError = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$dillonkearns$elm_graphql$Graphql$Http$errorDecoder(decoder),
				A2($elm$json$Json$Decode$map, $elm$core$Result$Ok, decoder)
			]));
};
var $dillonkearns$elm_graphql$Graphql$Document$serializeMutation = function (_v0) {
	var fields = _v0.a;
	var decoder_ = _v0.b;
	return A2($dillonkearns$elm_graphql$Graphql$Document$serialize, 'mutation', fields);
};
var $dillonkearns$elm_graphql$Graphql$Document$serializeMutationWithOperationName = F2(
	function (operationName, _v0) {
		var fields = _v0.a;
		var decoder_ = _v0.b;
		return A3($dillonkearns$elm_graphql$Graphql$Document$serializeWithOperationName, 'mutation', operationName, fields);
	});
var $dillonkearns$elm_graphql$Graphql$Http$toReadyRequest = function (_v0) {
	var request = _v0;
	var _v1 = request.bk;
	if (!_v1.$) {
		var forcedRequestMethod = _v1.a;
		var querySelectionSet = _v1.b;
		var queryRequestDetails = A5(
			$dillonkearns$elm_graphql$Graphql$Http$QueryHelper$build,
			function () {
				if (!forcedRequestMethod.$) {
					if (!forcedRequestMethod.a) {
						var _v4 = forcedRequestMethod.a;
						return $elm$core$Maybe$Just(0);
					} else {
						var _v5 = forcedRequestMethod.a;
						return $elm$core$Maybe$Nothing;
					}
				} else {
					return $elm$core$Maybe$Just(1);
				}
			}(),
			request.aZ,
			request.aa,
			request.Z,
			querySelectionSet);
		return {
			dG: queryRequestDetails.dG,
			dV: $dillonkearns$elm_graphql$Graphql$Http$decoderOrError(request.an),
			d2: request.d2,
			ej: function () {
				var _v2 = queryRequestDetails.ej;
				if (!_v2) {
					return 'GET';
				} else {
					return 'Post';
				}
			}(),
			eM: request.eM,
			eW: queryRequestDetails.eW
		};
	} else {
		var mutationSelectionSet = _v1.a;
		var serializedMutation = function () {
			var _v7 = request.Z;
			if (!_v7.$) {
				var operationName = _v7.a;
				return A2($dillonkearns$elm_graphql$Graphql$Document$serializeMutationWithOperationName, operationName, mutationSelectionSet);
			} else {
				return $dillonkearns$elm_graphql$Graphql$Document$serializeMutation(mutationSelectionSet);
			}
		}();
		return {
			dG: $elm$http$Http$jsonBody(
				$elm$json$Json$Encode$object(
					A2(
						$elm$core$List$append,
						_List_fromArray(
							[
								_Utils_Tuple2(
								'query',
								$elm$json$Json$Encode$string(serializedMutation))
							]),
						function () {
							var _v6 = request.Z;
							if (!_v6.$) {
								var operationName = _v6.a;
								return _List_fromArray(
									[
										_Utils_Tuple2(
										'operationName',
										$elm$json$Json$Encode$string(operationName))
									]);
							} else {
								return _List_Nil;
							}
						}()))),
			dV: $dillonkearns$elm_graphql$Graphql$Http$decoderOrError(request.an),
			d2: request.d2,
			ej: 'POST',
			eM: request.eM,
			eW: A2($dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams, request.aa, request.aZ)
		};
	}
};
var $dillonkearns$elm_graphql$Graphql$Http$toHttpRequestRecord = F2(
	function (resultToMessage, fullRequest) {
		var request = fullRequest;
		return function (readyRequest) {
			return {
				dG: readyRequest.dG,
				an: A2(
					$dillonkearns$elm_graphql$Graphql$Http$expectJson,
					A2($elm$core$Basics$composeR, $dillonkearns$elm_graphql$Graphql$Http$convertResult, resultToMessage),
					readyRequest.dV),
				d2: readyRequest.d2,
				ej: readyRequest.ej,
				eM: readyRequest.eM,
				dr: $elm$core$Maybe$Nothing,
				eW: readyRequest.eW
			};
		}(
			$dillonkearns$elm_graphql$Graphql$Http$toReadyRequest(fullRequest));
	});
var $dillonkearns$elm_graphql$Graphql$Http$send = F2(
	function (resultToMessage, fullRequest) {
		var request = fullRequest;
		return (request.af ? $elm$http$Http$riskyRequest : $elm$http$Http$request)(
			A2($dillonkearns$elm_graphql$Graphql$Http$toHttpRequestRecord, resultToMessage, fullRequest));
	});
var $author$project$Asset$fetchToken = F2(
	function (net, token) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$Http$send,
			A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Asset$LoadedToken),
			A2(
				$dillonkearns$elm_graphql$Graphql$Http$queryRequest,
				$author$project$Constants$apiUrl,
				$author$project$Asset$query(
					$author$project$Constants$prefixOf(net) + (':' + $cmditch$elm_ethereum$Eth$Utils$addressToString(token)))));
	});
var $author$project$Asset$init = F2(
	function (net, raw) {
		return _Utils_Tuple2(
			{bt: raw, bc: $author$project$Utils$Working},
			A2($author$project$Asset$fetchToken, net, raw.dq));
	});
var $author$project$Liquidator$Liquidated = function (a) {
	return {$: 3, a: a};
};
var $author$project$Liquidator$LiquidationError = function (a) {
	return {$: 1, a: a};
};
var $author$project$Liquidator$LiquidationMined = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Liquidator$LiquidationSentToBlockchain = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Liquidator$SendLiquidationToBlockchain = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Liquidator$Started = {$: 0};
var $author$project$Liquidator$TxMining = function (a) {
	return {$: 2, a: a};
};
var $cmditch$elm_ethereum$Eth$Abi$Encode$bool = function (v) {
	return $cmditch$elm_ethereum$Eth$Abi$Encode$EValue(
		$cmditch$elm_ethereum$Eth$Utils$leftPadTo64(
			v ? '1' : '0'));
};
var $cmditch$elm_ethereum$Eth$Abi$Encode$EDynamicBytes = function (a) {
	return {$: 1, a: a};
};
var $cmditch$elm_ethereum$Eth$Abi$Encode$EPointerTo = function (a) {
	return {$: 4, a: a};
};
var $cmditch$elm_ethereum$Eth$Abi$Encode$bytes = A2($elm$core$Basics$composeR, $cmditch$elm_ethereum$Eth$Abi$Encode$EDynamicBytes, $cmditch$elm_ethereum$Eth$Abi$Encode$EPointerTo);
var $cmditch$elm_ethereum$Eth$Abi$Encode$staticBytes = function (_v0) {
	var hex = _v0;
	return $cmditch$elm_ethereum$Eth$Abi$Encode$EValue(
		$cmditch$elm_ethereum$Eth$Utils$remove0x(hex));
};
var $zwilias$elm_utf_tools$String$UTF8$utf32ToUtf8 = F3(
	function (add, _char, acc) {
		return (_char < 128) ? A2(add, _char, acc) : ((_char < 2048) ? A2(
			add,
			128 | (63 & _char),
			A2(add, 192 | (_char >>> 6), acc)) : ((_char < 65536) ? A2(
			add,
			128 | (63 & _char),
			A2(
				add,
				128 | (63 & (_char >>> 6)),
				A2(add, 224 | (_char >>> 12), acc))) : A2(
			add,
			128 | (63 & _char),
			A2(
				add,
				128 | (63 & (_char >>> 6)),
				A2(
					add,
					128 | (63 & (_char >>> 12)),
					A2(add, 240 | (_char >>> 18), acc))))));
	});
var $zwilias$elm_utf_tools$String$UTF8$foldl = F3(
	function (op, initialAcc, input) {
		return A3(
			$elm$core$String$foldl,
			F2(
				function (_char, acc) {
					return A3(
						$zwilias$elm_utf_tools$String$UTF8$utf32ToUtf8,
						op,
						$elm$core$Char$toCode(_char),
						acc);
				}),
			initialAcc,
			input);
	});
var $zwilias$elm_utf_tools$String$UTF8$toBytes = function (input) {
	return $elm$core$List$reverse(
		A3($zwilias$elm_utf_tools$String$UTF8$foldl, $elm$core$List$cons, _List_Nil, input));
};
var $cmditch$elm_ethereum$Eth$Abi$Encode$stringToHex = function (strVal) {
	return A2(
		$elm$core$String$join,
		'',
		A2(
			$elm$core$List$map,
			A2(
				$elm$core$Basics$composeR,
				$rtfeldman$elm_hex$Hex$toString,
				A2($elm$core$String$padLeft, 2, '0')),
			$zwilias$elm_utf_tools$String$UTF8$toBytes(strVal)));
};
var $cmditch$elm_ethereum$Eth$Abi$Encode$EInline = function (a) {
	return {$: 3, a: a};
};
var $cmditch$elm_ethereum$Eth$Abi$Encode$isDynamic = function (e) {
	switch (e.$) {
		case 1:
			return true;
		case 2:
			return true;
		case 4:
			return true;
		default:
			return false;
	}
};
var $cmditch$elm_ethereum$Eth$Abi$Encode$tuple = function (props) {
	return A2($elm$core$List$any, $cmditch$elm_ethereum$Eth$Abi$Encode$isDynamic, props) ? $cmditch$elm_ethereum$Eth$Abi$Encode$EPointerTo(
		$cmditch$elm_ethereum$Eth$Abi$Encode$EInline(props)) : $cmditch$elm_ethereum$Eth$Abi$Encode$EInline(props);
};
var $cmditch$elm_ethereum$Eth$Utils$unsafeToHex = A2(
	$elm$core$Basics$composeR,
	$cmditch$elm_ethereum$Eth$Utils$remove0x,
	A2($elm$core$Basics$composeR, $elm$core$String$toLower, $elm$core$Basics$identity));
var $author$project$Contracts$encodeOrderLegoV1 = function (o) {
	var name = function () {
		var _v0 = o.bW;
		if (!_v0) {
			return 'ZeroEx';
		} else {
			return 'Flat';
		}
	}();
	var nameEncoded = $cmditch$elm_ethereum$Eth$Abi$Encode$staticBytes(
		$cmditch$elm_ethereum$Eth$Utils$unsafeToHex(
			$cmditch$elm_ethereum$Eth$Abi$Encode$stringToHex(name)));
	return $cmditch$elm_ethereum$Eth$Abi$Encode$tuple(
		_List_fromArray(
			[
				nameEncoded,
				$cmditch$elm_ethereum$Eth$Abi$Encode$address(o.dq),
				$cmditch$elm_ethereum$Eth$Abi$Encode$bytes(o.bh),
				$cmditch$elm_ethereum$Eth$Abi$Encode$bool(o.bA)
			]));
};
var $author$project$Contracts$encodeOrderPuzzle = function (o) {
	return $cmditch$elm_ethereum$Eth$Abi$Encode$tuple(
		_List_fromArray(
			[
				$cmditch$elm_ethereum$Eth$Abi$Encode$address(o.dq),
				$cmditch$elm_ethereum$Eth$Abi$Encode$bytes(o.bh)
			]));
};
var $author$project$Contracts$factoryAddress = function (_v0) {
	var net = _v0.a;
	var v = _v0.b;
	switch (net) {
		case 0:
			if (v === 1) {
				return $cmditch$elm_ethereum$Eth$Utils$unsafeToAddress('0x1dB81116467789b7dCC3B070ee8f5aA4d90D6940');
			} else {
				return $cmditch$elm_ethereum$Eth$Utils$unsafeToAddress('0x77a509c78b7d6148b89bc653a7b64c95f99b3510');
			}
		case 1:
			if (v === 1) {
				return $cmditch$elm_ethereum$Eth$Utils$unsafeToAddress('0x1dB81116467789b7dCC3B070ee8f5aA4d90D6940');
			} else {
				return $cmditch$elm_ethereum$Eth$Utils$unsafeToAddress('0xb61bca6f8973455335664e1d27ab4e3f099d54ab');
			}
		default:
			if (v === 1) {
				return $cmditch$elm_ethereum$Eth$Utils$unsafeToAddress('0xD6D813e31558b45769B83E33Fe10cDef76128Ffc');
			} else {
				return $cmditch$elm_ethereum$Eth$Utils$unsafeToAddress('0x77a509c78b7d6148b89bc653a7b64c95f99b3510');
			}
	}
};
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $cmditch$elm_ethereum$Eth$Abi$Encode$EDynamicList = function (a) {
	return {$: 2, a: a};
};
var $cmditch$elm_ethereum$Eth$Abi$Encode$list = $cmditch$elm_ethereum$Eth$Abi$Encode$EDynamicList;
var $author$project$Contracts$burnCall_ = F4(
	function (net, id, toToken, orders) {
		var _v0 = net.b;
		if (_v0 === 1) {
			var ordersEncoded = $cmditch$elm_ethereum$Eth$Abi$Encode$list(
				A2($elm$core$List$map, $author$project$Contracts$encodeOrderLegoV1, orders));
			var args = _List_fromArray(
				[
					$cmditch$elm_ethereum$Eth$Abi$Encode$uint(id),
					$cmditch$elm_ethereum$Eth$Abi$Encode$address(toToken),
					ordersEncoded
				]);
			return A2(
				$elm$core$Result$map,
				function (callData) {
					return {
						bH: $elm$core$Maybe$Just(callData),
						bK: $elm$core$Maybe$Nothing,
						bM: $elm$core$Maybe$Nothing,
						bN: $elm$core$Maybe$Nothing,
						bU: $elm$core$Maybe$Nothing,
						b5: $elm$core$Maybe$Just(
							$author$project$Contracts$factoryAddress(net)),
						b9: $elm$core$Maybe$Nothing
					};
				},
				A2($cmditch$elm_ethereum$Eth$Abi$Encode$functionCall, 'fa7acdd4', args));
		} else {
			var _v1 = $elm$core$List$head(orders);
			if (_v1.$ === 1) {
				return $elm$core$Result$Err('No orders');
			} else {
				var swapTarget = _v1.a.b3;
				var ordersEncoded = $cmditch$elm_ethereum$Eth$Abi$Encode$list(
					A2($elm$core$List$map, $author$project$Contracts$encodeOrderPuzzle, orders));
				var args = _List_fromArray(
					[
						$cmditch$elm_ethereum$Eth$Abi$Encode$uint(id),
						$cmditch$elm_ethereum$Eth$Abi$Encode$address(swapTarget),
						ordersEncoded
					]);
				return A2(
					$elm$core$Result$map,
					function (callData) {
						return {
							bH: $elm$core$Maybe$Just(callData),
							bK: $elm$core$Maybe$Nothing,
							bM: $elm$core$Maybe$Nothing,
							bN: $elm$core$Maybe$Nothing,
							bU: $elm$core$Maybe$Nothing,
							b5: $elm$core$Maybe$Just(
								$author$project$Contracts$factoryAddress(net)),
							b9: $elm$core$Maybe$Nothing
						};
					},
					A2($cmditch$elm_ethereum$Eth$Abi$Encode$functionCall, '41cd1009', args));
			}
		}
	});
var $cmditch$elm_ethereum$Eth$encodeSend = function (callData) {
	return $cmditch$elm_ethereum$Eth$Encode$listOfMaybesToVal(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'to',
				A2($elm$core$Maybe$map, $cmditch$elm_ethereum$Eth$Encode$address, callData.b5)),
				_Utils_Tuple2(
				'from',
				A2($elm$core$Maybe$map, $cmditch$elm_ethereum$Eth$Encode$address, callData.bK)),
				_Utils_Tuple2(
				'gas',
				A2($elm$core$Maybe$map, $cmditch$elm_ethereum$Eth$Encode$hexInt, callData.bM)),
				_Utils_Tuple2(
				'gasPrice',
				A2($elm$core$Maybe$map, $cmditch$elm_ethereum$Eth$Encode$bigInt, callData.bN)),
				_Utils_Tuple2(
				'value',
				A2($elm$core$Maybe$map, $cmditch$elm_ethereum$Eth$Encode$bigInt, callData.b9)),
				_Utils_Tuple2(
				'data',
				A2($elm$core$Maybe$map, $cmditch$elm_ethereum$Eth$Encode$hex, callData.bH)),
				_Utils_Tuple2(
				'nonce',
				A2($elm$core$Maybe$map, $cmditch$elm_ethereum$Eth$Encode$hexInt, callData.bU))
			]));
};
var $cmditch$elm_ethereum$Eth$Sentry$Tx$encodeTxData = F2(
	function (ref, txParamVal) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'ref',
					$elm$json$Json$Encode$int(ref)),
					_Utils_Tuple2('txParams', txParamVal)
				]));
	});
var $cmditch$elm_ethereum$Eth$Sentry$Tx$Signing = function (a) {
	return {$: 0, a: a};
};
var $cmditch$elm_ethereum$Eth$Sentry$Tx$newTxState = F2(
	function (txParams, _v0) {
		var onSign = _v0.bq;
		var onBroadcast = _v0.cR;
		var onMined = _v0.cS;
		return {
			aH: onBroadcast,
			H: onMined,
			br: onSign,
			b: txParams,
			a9: $cmditch$elm_ethereum$Eth$Sentry$Tx$Signing(txParams)
		};
	});
var $cmditch$elm_ethereum$Eth$Sentry$Tx$send_ = F3(
	function (_v0, customSendParams, txParams) {
		var sentry = _v0;
		var txParamsVal = $cmditch$elm_ethereum$Eth$encodeSend(txParams);
		var newTxs = A3(
			$elm$core$Dict$insert,
			sentry.o,
			A2($cmditch$elm_ethereum$Eth$Sentry$Tx$newTxState, txParams, customSendParams),
			sentry.m);
		return _Utils_Tuple2(
			_Utils_update(
				sentry,
				{o: sentry.o + 1, m: newTxs}),
			A2(
				$elm$core$Platform$Cmd$map,
				sentry.y,
				sentry.cU(
					A2($cmditch$elm_ethereum$Eth$Sentry$Tx$encodeTxData, sentry.o, txParamsVal))));
	});
var $cmditch$elm_ethereum$Eth$Sentry$Tx$sendWithReceipt = F4(
	function (onBroadcast, onMined, sentry, txParams) {
		return A3(
			$cmditch$elm_ethereum$Eth$Sentry$Tx$send_,
			sentry,
			{
				cR: $elm$core$Maybe$Just(onBroadcast),
				cS: $elm$core$Maybe$Just(
					_Utils_Tuple2(onMined, $elm$core$Maybe$Nothing)),
				bq: $elm$core$Maybe$Nothing
			},
			txParams);
	});
var $author$project$Contracts$burn = function (_v0) {
	var net = _v0.cO;
	var onBroadcast = _v0.cR;
	var onMined = _v0.cS;
	var sentry = _v0.dd;
	var id = _v0.d6;
	var toToken = _v0.dp;
	var orders = _v0.cT;
	return A2(
		$elm$core$Task$map,
		A3($cmditch$elm_ethereum$Eth$Sentry$Tx$sendWithReceipt, onBroadcast, onMined, sentry),
		$author$project$Utils$toTask(
			A2(
				$author$project$Utils$mapErr,
				$elm$http$Http$BadBody,
				A4($author$project$Contracts$burnCall_, net, id, toToken, orders))));
};
var $author$project$Contracts$ZeroEx = 0;
var $author$project$Utils$addressesEq = F2(
	function (a1, a2) {
		return _Utils_eq(
			$elm$core$String$toLower(
				$cmditch$elm_ethereum$Eth$Utils$addressToString(a1)),
			$elm$core$String$toLower(
				$cmditch$elm_ethereum$Eth$Utils$addressToString(a2)));
	});
var $author$project$Contracts$Flat = 1;
var $cmditch$elm_ethereum$Eth$Abi$Encode$abiEncodeList = A2(
	$elm$core$Basics$composeR,
	$cmditch$elm_ethereum$Eth$Abi$Encode$abiEncodeList_,
	$elm$core$Result$map($elm$core$Basics$identity));
var $author$project$Contracts$encodeFlatOperatorData = function (asset) {
	return A2(
		$elm$core$Result$map,
		function (x) {
			return {
				bh: x,
				bA: true,
				bW: 1,
				b3: $cmditch$elm_ethereum$Eth$Utils$unsafeToAddress('0x0'),
				dq: asset.dq
			};
		},
		$cmditch$elm_ethereum$Eth$Abi$Encode$abiEncodeList(
			_List_fromArray(
				[
					$cmditch$elm_ethereum$Eth$Abi$Encode$address(asset.dq),
					$cmditch$elm_ethereum$Eth$Abi$Encode$uint(asset.ew),
					$cmditch$elm_ethereum$Eth$Abi$Encode$bool(true)
				])));
};
var $author$project$Liquidator$PreparedSwap = F2(
	function (data, swapTarget) {
		return {bH: data, b3: swapTarget};
	});
var $author$project$Liquidator$decodeData = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Liquidator$PreparedSwap,
	A2(
		$elm$json$Json$Decode$map,
		$cmditch$elm_ethereum$Eth$Utils$unsafeToHex,
		A2($elm$json$Json$Decode$field, 'data', $elm$json$Json$Decode$string)),
	A2(
		$elm$json$Json$Decode$map,
		$cmditch$elm_ethereum$Eth$Utils$unsafeToAddress,
		A2($elm$json$Json$Decode$field, 'to', $elm$json$Json$Decode$string)));
var $choonkeat$elm_retry$Retry$Policy = $elm$core$Basics$identity;
var $elm$random$Random$Seed = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$random$Random$next = function (_v0) {
	var state0 = _v0.a;
	var incr = _v0.b;
	return A2($elm$random$Random$Seed, ((state0 * 1664525) + incr) >>> 0, incr);
};
var $elm$random$Random$initialSeed = function (x) {
	var _v0 = $elm$random$Random$next(
		A2($elm$random$Random$Seed, 0, 1013904223));
	var state1 = _v0.a;
	var incr = _v0.b;
	var state2 = (state1 + x) >>> 0;
	return $elm$random$Random$next(
		A2($elm$random$Random$Seed, state2, incr));
};
var $elm$random$Random$Generator = $elm$core$Basics$identity;
var $elm$random$Random$peel = function (_v0) {
	var state = _v0.a;
	var word = (state ^ (state >>> ((state >>> 28) + 4))) * 277803737;
	return ((word >>> 22) ^ word) >>> 0;
};
var $elm$random$Random$float = F2(
	function (a, b) {
		return function (seed0) {
			var seed1 = $elm$random$Random$next(seed0);
			var range = $elm$core$Basics$abs(b - a);
			var n1 = $elm$random$Random$peel(seed1);
			var n0 = $elm$random$Random$peel(seed0);
			var lo = (134217727 & n1) * 1.0;
			var hi = (67108863 & n0) * 1.0;
			var val = ((hi * 134217728.0) + lo) / 9007199254740992.0;
			var scaled = (val * range) + a;
			return _Utils_Tuple2(
				scaled,
				$elm$random$Random$next(seed1));
		};
	});
var $elm$random$Random$map = F2(
	function (func, _v0) {
		var genA = _v0;
		return function (seed0) {
			var _v1 = genA(seed0);
			var a = _v1.a;
			var seed1 = _v1.b;
			return _Utils_Tuple2(
				func(a),
				seed1);
		};
	});
var $choonkeat$elm_retry$Retry$nextIntervalGenerator = function (_v0) {
	var randomizationFactor = _v0.c4;
	var multiplier = _v0.cM;
	var interval = _v0.eb;
	var minInterval = interval * randomizationFactor;
	var maxInterval = interval * (1 + randomizationFactor);
	return A2(
		$elm$random$Random$map,
		function (randf) {
			return multiplier * (minInterval + (randf * ((maxInterval - minInterval) + 1)));
		},
		A2($elm$random$Random$float, 0, 1));
};
var $elm$core$Process$sleep = _Process_sleep;
var $elm$random$Random$step = F2(
	function (_v0, seed) {
		var generator = _v0;
		return generator(seed);
	});
var $choonkeat$elm_retry$Retry$exponentialBackoff = function (_v0) {
	var interval = _v0.eb;
	var maxInterval = _v0.ei;
	var backoffWith = F2(
		function (seed, currInterval) {
			var _v1 = A2(
				$elm$random$Random$step,
				$choonkeat$elm_retry$Retry$nextIntervalGenerator(
					{eb: currInterval, cM: 1.5, c4: 0.5}),
				seed);
			var calcInterval = _v1.a;
			var nextSeed = _v1.b;
			var nextPolicy = F3(
				function (_v3, _v4, err) {
					return A2(
						$elm$core$Task$andThen,
						function (_v2) {
							return $elm$core$Task$succeed(
								A2(
									backoffWith,
									nextSeed,
									A2($elm$core$Basics$min, calcInterval, maxInterval)));
						},
						$elm$core$Process$sleep(currInterval));
				});
			return nextPolicy;
		});
	return A2(
		backoffWith,
		$elm$random$Random$initialSeed(0),
		interval);
};
var $author$project$Utils$handleJsonResponse = F2(
	function (decoder, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 3:
				var statusCode = response.a.eI;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(statusCode));
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			default:
				var body = response.b;
				var _v1 = A2($elm$json$Json$Decode$decodeString, decoder, body);
				if (_v1.$ === 1) {
					return $elm$core$Result$Err(
						$elm$http$Http$BadBody(body));
				} else {
					var result = _v1.a;
					return $elm$core$Result$Ok(result);
				}
		}
	});
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $choonkeat$elm_retry$Retry$maxDuration = function (duration) {
	var nextPolicy = F3(
		function (startTime, sameTask, err) {
			return A2(
				$elm$core$Task$andThen,
				function (now) {
					return (_Utils_cmp(now - startTime, duration) > -1) ? $elm$core$Task$fail(err) : $elm$core$Task$succeed(sameTask);
				},
				A2($elm$core$Task$map, $elm$time$Time$posixToMillis, $elm$time$Time$now));
		});
	return nextPolicy;
};
var $cmditch$elm_bigint$BigInt$fillZeroes = A2(
	$elm$core$Basics$composeL,
	A2($elm$core$String$padLeft, $cmditch$elm_bigint$Constants$maxDigitMagnitude, '0'),
	$elm$core$String$fromInt);
var $cmditch$elm_bigint$BigInt$revMagnitudeToString = function (_v0) {
	var digits = _v0;
	var _v1 = $elm$core$List$reverse(digits);
	if (!_v1.b) {
		return '0';
	} else {
		var x = _v1.a;
		var xs = _v1.b;
		return $elm$core$String$concat(
			A2(
				$elm$core$List$cons,
				$elm$core$String$fromInt(x),
				A2($elm$core$List$map, $cmditch$elm_bigint$BigInt$fillZeroes, xs)));
	}
};
var $cmditch$elm_bigint$BigInt$toString = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return '0';
		case 0:
			var mag = bigInt.a;
			return $cmditch$elm_bigint$BigInt$revMagnitudeToString(mag);
		default:
			var mag = bigInt.a;
			return '-' + $cmditch$elm_bigint$BigInt$revMagnitudeToString(mag);
	}
};
var $choonkeat$elm_retry$Retry$with = F2(
	function (errTasks, originalTask) {
		var onError = F3(
			function (startTime, currPolicies, err) {
				return A2(
					$elm$core$Task$andThen,
					function (nextPolicies) {
						return A2(
							$elm$core$Task$onError,
							A2(onError, startTime, nextPolicies),
							originalTask);
					},
					$elm$core$Task$sequence(
						A2(
							$elm$core$List$map,
							function (cfg) {
								var nextPolicy = cfg;
								return A3(nextPolicy, startTime, cfg, err);
							},
							currPolicies)));
			});
		return A2(
			$elm$core$Task$andThen,
			function (nowMillis) {
				return A2(
					$elm$core$Task$onError,
					A2(onError, nowMillis, errTasks),
					originalTask);
			},
			A2($elm$core$Task$map, $elm$time$Time$posixToMillis, $elm$time$Time$now));
	});
var $author$project$Liquidator$zeroExSwap = F5(
	function (m, fromToken, toToken, sellQty, slippage) {
		var srv = function () {
			var _v0 = m.a4.a.d6;
			switch (_v0) {
				case 0:
					return 'https://bsc.api.0x.org/';
				case 1:
					return 'https://avalanche.api.0x.org/';
				default:
					return 'https://polygon.api.0x.org/';
			}
		}();
		var args = _List_fromArray(
			[
				'sellToken=' + $cmditch$elm_ethereum$Eth$Utils$addressToString(fromToken),
				'buyToken=' + $cmditch$elm_ethereum$Eth$Utils$addressToString(toToken),
				'slippagePercentage=' + $elm$core$String$fromFloat(slippage),
				'sellAmount=' + $cmditch$elm_bigint$BigInt$toString(sellQty)
			]);
		var url = srv + ('swap/v1/quote?' + A2($elm$core$String$join, '&', args));
		var task = $elm$http$Http$task(
			{
				dG: $elm$http$Http$emptyBody,
				d2: _List_Nil,
				ej: 'GET',
				ez: $elm$http$Http$stringResolver(
					$author$project$Utils$handleJsonResponse($author$project$Liquidator$decodeData)),
				eM: $elm$core$Maybe$Nothing,
				eW: url
			});
		return A2(
			$choonkeat$elm_retry$Retry$with,
			_List_fromArray(
				[
					$choonkeat$elm_retry$Retry$maxDuration(30000),
					$choonkeat$elm_retry$Retry$exponentialBackoff(
					{eb: 300, ei: 2000})
				]),
			task);
	});
var $author$project$Liquidator$burnSwap = F3(
	function (m, toToken, asset) {
		return A2($author$project$Utils$addressesEq, toToken, asset.dq) ? $author$project$Utils$toTask(
			A2(
				$author$project$Utils$mapErr,
				$elm$http$Http$BadBody,
				$author$project$Contracts$encodeFlatOperatorData(asset))) : A2(
			$elm$core$Task$map,
			function (h) {
				return {bh: h.bH, bA: true, bW: 0, b3: h.b3, dq: asset.dq};
			},
			A5($author$project$Liquidator$zeroExSwap, m, asset.dq, toToken, asset.ew, 0.3));
	});
var $author$project$Utils$errorToString = function (e) {
	switch (e.$) {
		case 0:
			return 'Bad URL';
		case 1:
			return 'Timeout';
		case 2:
			return 'Network error';
		case 3:
			return 'Server error';
		default:
			var str = e.a;
			return str;
	}
};
var $cmditch$elm_ethereum$Eth$Sentry$Tx$TrackTx = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $cmditch$elm_ethereum$Eth$Sentry$Tx$TxMined = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $cmditch$elm_ethereum$Eth$Sentry$Tx$TxSent = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $cmditch$elm_ethereum$Eth$Decode$hexInt = $cmditch$elm_ethereum$Eth$Decode$resultToDecoder(
	A2($elm$core$Basics$composeR, $cmditch$elm_ethereum$Eth$Utils$remove0x, $rtfeldman$elm_hex$Hex$fromString));
var $cmditch$elm_ethereum$Eth$getBlockNumber = function (ethNode) {
	return $cmditch$elm_ethereum$Eth$RPC$toTask(
		{dV: $cmditch$elm_ethereum$Eth$Decode$hexInt, ej: 'eth_blockNumber', b: _List_Nil, eW: ethNode});
};
var $cmditch$elm_ethereum$Eth$Utils$txHashToString = function (_v0) {
	var txHash = _v0;
	return $cmditch$elm_ethereum$Eth$Utils$add0x(txHash);
};
var $cmditch$elm_ethereum$Eth$Encode$txHash = A2($elm$core$Basics$composeR, $cmditch$elm_ethereum$Eth$Utils$txHashToString, $elm$json$Json$Encode$string);
var $cmditch$elm_ethereum$Eth$Types$TxReceipt = function (hash) {
	return function (index) {
		return function (blockHash) {
			return function (blockNumber) {
				return function (gasUsed) {
					return function (cumulativeGasUsed) {
						return function (contractAddress) {
							return function (logs) {
								return function (logsBloom) {
									return function (root) {
										return function (status) {
											return {cf: blockHash, cg: blockNumber, dR: contractAddress, dS: cumulativeGasUsed, bO: gasUsed, d1: hash, d8: index, eg: logs, bR: logsBloom, eD: root, a9: status};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $cmditch$elm_ethereum$Eth$Decode$address = $cmditch$elm_ethereum$Eth$Decode$resultToDecoder($cmditch$elm_ethereum$Eth$Utils$toAddress);
var $cmditch$elm_ethereum$Eth$Decode$bigInt = $cmditch$elm_ethereum$Eth$Decode$resultToDecoder(
	A2(
		$elm$core$Basics$composeR,
		$cmditch$elm_ethereum$Eth$Utils$add0x,
		A2(
			$elm$core$Basics$composeR,
			$cmditch$elm_bigint$BigInt$fromHexString,
			$elm$core$Result$fromMaybe('Error decoding hex to BigInt'))));
var $cmditch$elm_ethereum$Internal$Types$BlockHash = $elm$core$Basics$identity;
var $cmditch$elm_ethereum$Eth$Utils$toBlockHash = function (str) {
	return $cmditch$elm_ethereum$Eth$Utils$isSha256(str) ? $elm$core$Result$Ok(
		$cmditch$elm_ethereum$Eth$Utils$remove0x(str)) : $elm$core$Result$Err(
		'Given blockHash ' + ($cmditch$elm_ethereum$Eth$Utils$quote(str) + ' is not valid.'));
};
var $cmditch$elm_ethereum$Eth$Decode$blockHash = $cmditch$elm_ethereum$Eth$Decode$resultToDecoder($cmditch$elm_ethereum$Eth$Utils$toBlockHash);
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $cmditch$elm_ethereum$Eth$Decode$hexBool = function () {
	var isBool = function (n) {
		switch (n) {
			case '0x0':
				return $elm$core$Result$Ok(false);
			case '0x1':
				return $elm$core$Result$Ok(true);
			default:
				return $elm$core$Result$Err('Error decoding ' + (n + 'as bool.'));
		}
	};
	return $cmditch$elm_ethereum$Eth$Decode$resultToDecoder(isBool);
}();
var $cmditch$elm_ethereum$Eth$Types$Log = F9(
	function (address, data, topics, removed, logIndex, transactionIndex, transactionHash, blockHash, blockNumber) {
		return {dD: address, cf: blockHash, cg: blockNumber, bH: data, cH: logIndex, c6: removed, b7: topics, dt: transactionHash, du: transactionIndex};
	});
var $cmditch$elm_ethereum$Eth$Utils$isHex = $elm$regex$Regex$contains(
	A2(
		$elm$core$Maybe$withDefault,
		$elm$regex$Regex$never,
		$elm$regex$Regex$fromString('^((0[Xx]){1})?[0-9a-fA-F]+$')));
var $cmditch$elm_ethereum$Eth$Utils$toHex = function (str) {
	return $cmditch$elm_ethereum$Eth$Utils$isHex(str) ? $elm$core$Result$Ok(
		$cmditch$elm_ethereum$Eth$Utils$remove0x(str)) : $elm$core$Result$Err(
		'Something in here is not very hexy: ' + $cmditch$elm_ethereum$Eth$Utils$quote(str));
};
var $cmditch$elm_ethereum$Eth$Decode$hex = $cmditch$elm_ethereum$Eth$Decode$resultToDecoder($cmditch$elm_ethereum$Eth$Utils$toHex);
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder = F3(
	function (path, valDecoder, fallback) {
		var nullOr = function (decoder) {
			return $elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						decoder,
						$elm$json$Json$Decode$null(fallback)
					]));
		};
		var handleResult = function (input) {
			var _v0 = A2(
				$elm$json$Json$Decode$decodeValue,
				A2($elm$json$Json$Decode$at, path, $elm$json$Json$Decode$value),
				input);
			if (!_v0.$) {
				var rawValue = _v0.a;
				var _v1 = A2(
					$elm$json$Json$Decode$decodeValue,
					nullOr(valDecoder),
					rawValue);
				if (!_v1.$) {
					var finalResult = _v1.a;
					return $elm$json$Json$Decode$succeed(finalResult);
				} else {
					return A2(
						$elm$json$Json$Decode$at,
						path,
						nullOr(valDecoder));
				}
			} else {
				return $elm$json$Json$Decode$succeed(fallback);
			}
		};
		return A2($elm$json$Json$Decode$andThen, handleResult, $elm$json$Json$Decode$value);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional = F4(
	function (key, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				_List_fromArray(
					[key]),
				valDecoder,
				fallback),
			decoder);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $cmditch$elm_ethereum$Eth$Decode$log = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'blockNumber',
	$cmditch$elm_ethereum$Eth$Decode$hexInt,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'blockHash',
		$cmditch$elm_ethereum$Eth$Decode$blockHash,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'transactionHash',
			$cmditch$elm_ethereum$Eth$Decode$txHash,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'transactionIndex',
				$cmditch$elm_ethereum$Eth$Decode$hexInt,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'logIndex',
					$cmditch$elm_ethereum$Eth$Decode$hexInt,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'removed',
						$elm$json$Json$Decode$bool,
						false,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'topics',
							$elm$json$Json$Decode$list($cmditch$elm_ethereum$Eth$Decode$hex),
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'data',
								$elm$json$Json$Decode$string,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'address',
									$cmditch$elm_ethereum$Eth$Decode$address,
									$elm$json$Json$Decode$succeed($cmditch$elm_ethereum$Eth$Types$Log))))))))));
var $cmditch$elm_ethereum$Eth$Decode$txReceipt = A2(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'status', $cmditch$elm_ethereum$Eth$Decode$hexBool)),
	A2(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
		$elm$json$Json$Decode$maybe(
			A2($elm$json$Json$Decode$field, 'root', $elm$json$Json$Decode$string)),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'logsBloom',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'logs',
				$elm$json$Json$Decode$list($cmditch$elm_ethereum$Eth$Decode$log),
				A2(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
					$elm$json$Json$Decode$maybe(
						A2($elm$json$Json$Decode$field, 'contractAddress', $cmditch$elm_ethereum$Eth$Decode$address)),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'cumulativeGasUsed',
						$cmditch$elm_ethereum$Eth$Decode$bigInt,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'gasUsed',
							$cmditch$elm_ethereum$Eth$Decode$bigInt,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'blockNumber',
								$cmditch$elm_ethereum$Eth$Decode$hexInt,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'blockHash',
									$cmditch$elm_ethereum$Eth$Decode$blockHash,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'transactionIndex',
										$cmditch$elm_ethereum$Eth$Decode$hexInt,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'transactionHash',
											$cmditch$elm_ethereum$Eth$Decode$txHash,
											$elm$json$Json$Decode$succeed($cmditch$elm_ethereum$Eth$Types$TxReceipt))))))))))));
var $cmditch$elm_ethereum$Eth$getTxReceipt = F2(
	function (ethNode, txHash) {
		return $cmditch$elm_ethereum$Eth$RPC$toTask(
			{
				dV: $cmditch$elm_ethereum$Eth$Decode$txReceipt,
				ej: 'eth_getTransactionReceipt',
				b: _List_fromArray(
					[
						$cmditch$elm_ethereum$Eth$Encode$txHash(txHash)
					]),
				eW: ethNode
			});
	});
var $cmditch$elm_ethereum$Eth$Sentry$Tx$getTxTrackerToMsg = F2(
	function (txs, ref) {
		return A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.$7;
			},
			A2(
				$elm$core$Maybe$andThen,
				function (onMined) {
					return onMined.b;
				},
				A2(
					$elm$core$Maybe$andThen,
					function (txState) {
						return txState.H;
					},
					A2($elm$core$Dict$get, ref, txs))));
	});
var $elm_community$maybe_extra$Maybe$Extra$isJust = function (m) {
	if (m.$ === 1) {
		return false;
	} else {
		return true;
	}
};
var $cmditch$elm_ethereum$Eth$Types$Tx = function (hash) {
	return function (nonce) {
		return function (blockHash) {
			return function (blockNumber) {
				return function (transactionIndex) {
					return function (from) {
						return function (to) {
							return function (value) {
								return function (gasPrice) {
									return function (gas) {
										return function (input) {
											return {cf: blockHash, cg: blockNumber, bK: from, bM: gas, bN: gasPrice, d1: hash, ea: input, bU: nonce, b5: to, du: transactionIndex, b9: value};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $cmditch$elm_ethereum$Eth$Decode$nonZero = function (decoder) {
	var checkZero = function (str) {
		return ((str === '0x') || (str === '0x0')) ? $elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing) : (A2(
			$elm$core$String$all,
			function (s) {
				return s === '0';
			},
			$cmditch$elm_ethereum$Eth$Utils$remove0x(str)) ? $elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing) : A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder));
	};
	return A2($elm$json$Json$Decode$andThen, checkZero, $elm$json$Json$Decode$string);
};
var $cmditch$elm_ethereum$Eth$Decode$tx = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'input',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'gas',
		$cmditch$elm_ethereum$Eth$Decode$hexInt,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'gasPrice',
			$cmditch$elm_ethereum$Eth$Decode$bigInt,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'value',
				$cmditch$elm_ethereum$Eth$Decode$bigInt,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'to',
					$elm$json$Json$Decode$nullable($cmditch$elm_ethereum$Eth$Decode$address),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'from',
						$cmditch$elm_ethereum$Eth$Decode$address,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'transactionIndex',
							$cmditch$elm_ethereum$Eth$Decode$hexInt,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'blockNumber',
								$elm$json$Json$Decode$nullable($cmditch$elm_ethereum$Eth$Decode$hexInt),
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'blockHash',
									$cmditch$elm_ethereum$Eth$Decode$nonZero($cmditch$elm_ethereum$Eth$Decode$blockHash),
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'nonce',
										$cmditch$elm_ethereum$Eth$Decode$hexInt,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'hash',
											$cmditch$elm_ethereum$Eth$Decode$txHash,
											$elm$json$Json$Decode$succeed($cmditch$elm_ethereum$Eth$Types$Tx))))))))))));
var $cmditch$elm_ethereum$Eth$getTx = F2(
	function (ethNode, txHash) {
		return $cmditch$elm_ethereum$Eth$RPC$toTask(
			{
				dV: $cmditch$elm_ethereum$Eth$Decode$tx,
				ej: 'eth_getTransactionByHash',
				b: _List_fromArray(
					[
						$cmditch$elm_ethereum$Eth$Encode$txHash(txHash)
					]),
				eW: ethNode
			});
	});
var $cmditch$elm_ethereum$Eth$Utils$Retry = F2(
	function (attempts, sleep) {
		return {cd: attempts, dh: sleep};
	});
var $cmditch$elm_ethereum$Eth$Utils$retry = F2(
	function (_v0, myTask) {
		var attempts = _v0.cd;
		var sleep = _v0.dh;
		var remaining = attempts - 1;
		return A2(
			$elm$core$Task$onError,
			function (x) {
				return (remaining > 0) ? A2(
					$elm$core$Task$andThen,
					function (_v1) {
						return A2(
							$cmditch$elm_ethereum$Eth$Utils$retry,
							A2($cmditch$elm_ethereum$Eth$Utils$Retry, remaining, sleep),
							myTask);
					},
					$elm$core$Process$sleep(sleep * 1000)) : $elm$core$Task$fail(x);
			},
			myTask);
	});
var $cmditch$elm_ethereum$Eth$Sentry$Tx$pollTxBroadcast = F2(
	function (nodePath, txHash) {
		return A2(
			$elm$core$Task$andThen,
			function (_v0) {
				return A2(
					$cmditch$elm_ethereum$Eth$Utils$retry,
					{cd: 30, dh: 1},
					A2($cmditch$elm_ethereum$Eth$getTx, nodePath, txHash));
			},
			$elm$core$Process$sleep(250));
	});
var $cmditch$elm_ethereum$Eth$Sentry$Tx$pollTxReceipt = F2(
	function (nodePath, txHash) {
		return A2(
			$cmditch$elm_ethereum$Eth$Utils$retry,
			{cd: 60, dh: 5},
			A2($cmditch$elm_ethereum$Eth$getTxReceipt, nodePath, txHash));
	});
var $cmditch$elm_ethereum$Eth$Sentry$Tx$Mined = function (a) {
	return {$: 3, a: a};
};
var $cmditch$elm_ethereum$Eth$Sentry$Tx$txStatusMined = function (txReceipt) {
	return $elm$core$Maybe$map(
		function (txState) {
			return _Utils_update(
				txState,
				{
					a9: $cmditch$elm_ethereum$Eth$Sentry$Tx$Mined(txReceipt)
				});
		});
};
var $cmditch$elm_ethereum$Eth$Sentry$Tx$Sent = function (a) {
	return {$: 2, a: a};
};
var $cmditch$elm_ethereum$Eth$Sentry$Tx$txStatusSent = function (tx) {
	return $elm$core$Maybe$map(
		function (txState) {
			return _Utils_update(
				txState,
				{
					a9: $cmditch$elm_ethereum$Eth$Sentry$Tx$Sent(tx)
				});
		});
};
var $cmditch$elm_ethereum$Eth$Sentry$Tx$Signed = function (a) {
	return {$: 1, a: a};
};
var $cmditch$elm_ethereum$Eth$Sentry$Tx$txStatusSigned = function (txHash) {
	return $elm$core$Maybe$map(
		function (txState) {
			return _Utils_update(
				txState,
				{
					a9: $cmditch$elm_ethereum$Eth$Sentry$Tx$Signed(txHash)
				});
		});
};
var $cmditch$elm_ethereum$Eth$Sentry$Tx$update = F2(
	function (msg, _v0) {
		var sentry = _v0;
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(sentry, $elm$core$Platform$Cmd$none);
			case 1:
				var ref = msg.a;
				var txHashResult = msg.b;
				var _v2 = A2($elm$core$Dict$get, ref, sentry.m);
				if (!_v2.$) {
					var txState = _v2.a;
					var txSignedCmd = function () {
						var _v9 = txState.br;
						if (!_v9.$) {
							var txHashToMsg = _v9.a;
							return A2(
								$elm$core$Task$perform,
								txHashToMsg,
								$elm$core$Task$succeed(txHashResult));
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}();
					var failOtherCallbacks = function (error) {
						var _v4 = _Utils_Tuple3(txState.br, txState.aH, txState.H);
						if (!_v4.a.$) {
							return $elm$core$Platform$Cmd$none;
						} else {
							if (!_v4.b.$) {
								var txToMsg = _v4.b.a;
								return A2(
									$elm$core$Task$perform,
									txToMsg,
									$elm$core$Task$succeed(
										$elm$core$Result$Err(error)));
							} else {
								if (!_v4.c.$) {
									var _v5 = _v4.c.a;
									var txReceiptToMsg = _v5.a;
									return A2(
										$elm$core$Task$perform,
										txReceiptToMsg,
										$elm$core$Task$succeed(
											$elm$core$Result$Err(error)));
								} else {
									var _v6 = _v4.a;
									var _v7 = _v4.b;
									var _v8 = _v4.c;
									return $elm$core$Platform$Cmd$none;
								}
							}
						}
					};
					if (!txHashResult.$) {
						var txHash = txHashResult.a;
						var txBroadcastCmd = ($elm_community$maybe_extra$Maybe$Extra$isJust(txState.aH) || $elm_community$maybe_extra$Maybe$Extra$isJust(txState.H)) ? A2(
							$elm$core$Platform$Cmd$map,
							sentry.y,
							A2(
								$elm$core$Task$attempt,
								$cmditch$elm_ethereum$Eth$Sentry$Tx$TxSent(ref),
								A2($cmditch$elm_ethereum$Eth$Sentry$Tx$pollTxBroadcast, sentry.Y, txHash))) : $elm$core$Platform$Cmd$none;
						return _Utils_Tuple2(
							_Utils_update(
								sentry,
								{
									m: A3(
										$elm$core$Dict$update,
										ref,
										$cmditch$elm_ethereum$Eth$Sentry$Tx$txStatusSigned(txHash),
										sentry.m)
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[txSignedCmd, txBroadcastCmd])));
					} else {
						var error = txHashResult.a;
						return _Utils_Tuple2(
							sentry,
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										txSignedCmd,
										failOtherCallbacks(error)
									])));
					}
				} else {
					return _Utils_Tuple2(sentry, $elm$core$Platform$Cmd$none);
				}
			case 2:
				var ref = msg.a;
				var txResult = msg.b;
				var _v10 = A2($elm$core$Dict$get, ref, sentry.m);
				if (!_v10.$) {
					var txState = _v10.a;
					if (!txResult.$) {
						var tx = txResult.a;
						var txMinedCmd = function () {
							var _v13 = txState.H;
							if (!_v13.$) {
								return A2(
									$elm$core$Platform$Cmd$map,
									sentry.y,
									A2(
										$elm$core$Task$attempt,
										$cmditch$elm_ethereum$Eth$Sentry$Tx$TxMined(ref),
										A2($cmditch$elm_ethereum$Eth$Sentry$Tx$pollTxReceipt, sentry.Y, tx.d1)));
							} else {
								return $elm$core$Platform$Cmd$none;
							}
						}();
						var txBroadcastCmd = function () {
							var _v12 = txState.aH;
							if (!_v12.$) {
								var txToMsg = _v12.a;
								return A2(
									$elm$core$Task$perform,
									txToMsg,
									$elm$core$Task$succeed(
										$elm$core$Result$Ok(tx)));
							} else {
								return $elm$core$Platform$Cmd$none;
							}
						}();
						return _Utils_Tuple2(
							_Utils_update(
								sentry,
								{
									m: A3(
										$elm$core$Dict$update,
										ref,
										$cmditch$elm_ethereum$Eth$Sentry$Tx$txStatusSent(tx),
										sentry.m)
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[txBroadcastCmd, txMinedCmd])));
					} else {
						var error = txResult.a;
						var failOtherCallbacks = function () {
							var _v14 = _Utils_Tuple2(txState.aH, txState.H);
							if (!_v14.a.$) {
								var txToMsg = _v14.a.a;
								return A2(
									$elm$core$Task$perform,
									txToMsg,
									$elm$core$Task$succeed(
										$elm$core$Result$Err('Error with TxSent stuff')));
							} else {
								if (!_v14.b.$) {
									var _v15 = _v14.b.a;
									var txReceiptToMsg = _v15.a;
									return A2(
										$elm$core$Task$perform,
										txReceiptToMsg,
										$elm$core$Task$succeed(
											$elm$core$Result$Err('Error with TxSent stuff')));
								} else {
									var _v16 = _v14.a;
									var _v17 = _v14.b;
									return $elm$core$Platform$Cmd$none;
								}
							}
						}();
						return _Utils_Tuple2(sentry, failOtherCallbacks);
					}
				} else {
					return _Utils_Tuple2(sentry, $elm$core$Platform$Cmd$none);
				}
			case 3:
				var ref = msg.a;
				var txReceiptResult = msg.b;
				var _v18 = A2($elm$core$Dict$get, ref, sentry.m);
				if (!_v18.$) {
					var txState = _v18.a;
					if (!txReceiptResult.$) {
						var txReceipt = txReceiptResult.a;
						var cmdIfMined = function () {
							var _v20 = txState.H;
							if (!_v20.$) {
								if (_v20.a.b.$ === 1) {
									var _v21 = _v20.a;
									var txReceiptToMsg = _v21.a;
									var _v22 = _v21.b;
									return A2(
										$elm$core$Task$perform,
										txReceiptToMsg,
										$elm$core$Task$succeed(
											$elm$core$Result$Ok(txReceipt)));
								} else {
									var _v23 = _v20.a;
									var txReceiptToMsg = _v23.a;
									var tracker = _v23.b.a;
									var txTracker = {bB: 1, bl: false, bn: txReceipt.cg, bT: txReceipt.cg, bZ: false, b2: txReceipt.cg + (tracker.dO - 1), be: txReceipt.d1};
									return $elm$core$Platform$Cmd$batch(
										_List_fromArray(
											[
												A2(
												$elm$core$Platform$Cmd$map,
												sentry.y,
												A2(
													$elm$core$Task$attempt,
													A2($cmditch$elm_ethereum$Eth$Sentry$Tx$TrackTx, ref, txTracker),
													$cmditch$elm_ethereum$Eth$getBlockNumber(sentry.Y))),
												A2(
												$elm$core$Task$perform,
												txReceiptToMsg,
												$elm$core$Task$succeed(
													$elm$core$Result$Ok(txReceipt))),
												A2(
												$elm$core$Task$perform,
												tracker.$7,
												$elm$core$Task$succeed(txTracker))
											]));
								}
							} else {
								return $elm$core$Platform$Cmd$none;
							}
						}();
						return _Utils_Tuple2(
							_Utils_update(
								sentry,
								{
									m: A3(
										$elm$core$Dict$update,
										ref,
										$cmditch$elm_ethereum$Eth$Sentry$Tx$txStatusMined(txReceipt),
										sentry.m)
								}),
							cmdIfMined);
					} else {
						var error = txReceiptResult.a;
						var cmdIfMinedFail = function () {
							var _v24 = txState.H;
							if (!_v24.$) {
								var _v25 = _v24.a;
								var txReceiptToMsg = _v25.a;
								return A2(
									$elm$core$Task$perform,
									txReceiptToMsg,
									$elm$core$Task$succeed(
										$elm$core$Result$Err('TxReceipt decoding failure')));
							} else {
								return $elm$core$Platform$Cmd$none;
							}
						}();
						return _Utils_Tuple2(sentry, cmdIfMinedFail);
					}
				} else {
					return _Utils_Tuple2(sentry, $elm$core$Platform$Cmd$none);
				}
			case 4:
				if (!msg.c.$) {
					var ref = msg.a;
					var txTracker = msg.b;
					var newBlockNum = msg.c.a;
					var newTxTracker = _Utils_update(
						txTracker,
						{bB: (newBlockNum - txTracker.bT) + 1, bn: newBlockNum});
					if (_Utils_eq(newBlockNum, txTracker.b2)) {
						var _v26 = A2($cmditch$elm_ethereum$Eth$Sentry$Tx$getTxTrackerToMsg, sentry.m, ref);
						if (!_v26.$) {
							var blockDepthToMsg = _v26.a;
							return _Utils_Tuple2(
								sentry,
								A2(
									$elm$core$Task$perform,
									blockDepthToMsg,
									A2(
										$elm$core$Task$onError,
										function (_v28) {
											return $elm$core$Task$succeed(
												_Utils_update(
													newTxTracker,
													{bl: true, bZ: true}));
										},
										A2(
											$elm$core$Task$andThen,
											function (_v27) {
												return $elm$core$Task$succeed(
													_Utils_update(
														newTxTracker,
														{bl: true}));
											},
											A2($cmditch$elm_ethereum$Eth$getTxReceipt, sentry.Y, txTracker.be)))));
						} else {
							return _Utils_Tuple2(sentry, $elm$core$Platform$Cmd$none);
						}
					} else {
						if (_Utils_eq(newBlockNum, txTracker.bn)) {
							return _Utils_Tuple2(
								sentry,
								A2(
									$elm$core$Platform$Cmd$map,
									sentry.y,
									A2(
										$elm$core$Task$attempt,
										A2($cmditch$elm_ethereum$Eth$Sentry$Tx$TrackTx, ref, txTracker),
										A2(
											$elm$core$Task$andThen,
											function (_v29) {
												return $cmditch$elm_ethereum$Eth$getBlockNumber(sentry.Y);
											},
											$elm$core$Process$sleep(2000)))));
						} else {
							var _v30 = A2($cmditch$elm_ethereum$Eth$Sentry$Tx$getTxTrackerToMsg, sentry.m, ref);
							if (!_v30.$) {
								var blockDepthToMsg = _v30.a;
								return _Utils_Tuple2(
									sentry,
									$elm$core$Platform$Cmd$batch(
										_List_fromArray(
											[
												A2(
												$elm$core$Platform$Cmd$map,
												sentry.y,
												A2(
													$elm$core$Task$attempt,
													A2($cmditch$elm_ethereum$Eth$Sentry$Tx$TrackTx, ref, newTxTracker),
													A2(
														$elm$core$Task$andThen,
														function (_v31) {
															return $cmditch$elm_ethereum$Eth$getBlockNumber(sentry.Y);
														},
														$elm$core$Process$sleep(2000)))),
												A2(
												$elm$core$Task$perform,
												blockDepthToMsg,
												$elm$core$Task$succeed(newTxTracker))
											])));
							} else {
								return _Utils_Tuple2(sentry, $elm$core$Platform$Cmd$none);
							}
						}
					}
				} else {
					var ref = msg.a;
					var error = msg.c.a;
					return _Utils_Tuple2(sentry, $elm$core$Platform$Cmd$none);
				}
			default:
				var error = msg.a;
				return _Utils_Tuple2(sentry, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Liquidator$updateOpState = F3(
	function (model, state, id) {
		return _Utils_update(
			model,
			{
				a5: A3(
					$elm$core$Dict$insert,
					$cmditch$elm_bigint$BigInt$toHexString(id),
					{b0: state},
					model.a5)
			});
	});
var $author$project$Liquidator$doUpdate_ = F2(
	function (model, msg) {
		switch (msg.$) {
			case 1:
				if (msg.b.$ === 1) {
					var nft = msg.a;
					var e = msg.b.a;
					return _Utils_Tuple2(
						A3(
							$author$project$Liquidator$updateOpState,
							model,
							$author$project$Liquidator$LiquidationError(
								$author$project$Utils$errorToString(e)),
							nft.d6),
						$elm$core$Platform$Cmd$none);
				} else {
					var _v1 = msg.b.a;
					var sentry = _v1.a;
					var cmd = _v1.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ad: sentry}),
						cmd);
				}
			case 2:
				if (msg.b.$ === 1) {
					var nft = msg.a;
					var e = msg.b.a;
					return _Utils_Tuple2(
						A3(
							$author$project$Liquidator$updateOpState,
							model,
							$author$project$Liquidator$LiquidationError(e),
							nft.d6),
						$elm$core$Platform$Cmd$none);
				} else {
					var nft = msg.a;
					var res = msg.b.a;
					return _Utils_Tuple2(
						A3(
							$author$project$Liquidator$updateOpState,
							model,
							$author$project$Liquidator$TxMining(res),
							nft.d6),
						$elm$core$Platform$Cmd$none);
				}
			case 3:
				if (msg.b.$ === 1) {
					var nft = msg.a;
					var e = msg.b.a;
					return _Utils_Tuple2(
						A3(
							$author$project$Liquidator$updateOpState,
							model,
							$author$project$Liquidator$LiquidationError(e),
							nft.d6),
						$elm$core$Platform$Cmd$none);
				} else {
					var nft = msg.a;
					var res = msg.b.a;
					return _Utils_Tuple2(
						A3(
							$author$project$Liquidator$updateOpState,
							model,
							$author$project$Liquidator$Liquidated(res.d1),
							nft.d6),
						$elm$core$Platform$Cmd$none);
				}
			case 0:
				var nft = msg.a;
				var toToken = msg.b;
				var burnTask = A2(
					$elm$core$Task$andThen,
					function (o) {
						return $author$project$Contracts$burn(
							{
								d6: nft.d6,
								cO: _Utils_Tuple2(model.a4.a.d6, model.a4.b),
								cR: $author$project$Liquidator$LiquidationSentToBlockchain(nft),
								cS: $author$project$Liquidator$LiquidationMined(nft),
								cT: o,
								dd: model.ad,
								dp: toToken
							});
					},
					$elm$core$Task$sequence(
						A2(
							$elm$core$List$map,
							A2($author$project$Liquidator$burnSwap, model, toToken),
							nft.L)));
				return _Utils_Tuple2(
					A3($author$project$Liquidator$updateOpState, model, $author$project$Liquidator$Started, nft.d6),
					A2(
						$elm$core$Task$attempt,
						$author$project$Liquidator$SendLiquidationToBlockchain(nft),
						burnTask));
			default:
				var subMsg = msg.a;
				var _v2 = A2($cmditch$elm_ethereum$Eth$Sentry$Tx$update, subMsg, model.ad);
				var newTxSentry = _v2.a;
				var newMsg = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ad: newTxSentry}),
					newMsg);
		}
	});
var $author$project$Liquidator$update = F5(
	function (getModel, setModel, mapCmd, outerMsg, outerModel) {
		var thisModel = getModel(outerModel);
		var _v0 = A2($author$project$Liquidator$doUpdate_, thisModel, outerMsg);
		var innerModel = _v0.a;
		var innerMsg = _v0.b;
		return _Utils_Tuple2(
			A2(setModel, outerModel, innerModel),
			A2($elm$core$Platform$Cmd$map, mapCmd, innerMsg));
	});
var $author$project$Connected$liquidatorUpdate = A3(
	$author$project$Liquidator$update,
	function ($) {
		return $.ax;
	},
	F2(
		function (m, l) {
			return _Utils_update(
				m,
				{ax: l});
		}),
	$author$project$Connected$LiquidatorCmd);
var $author$project$Utils$Error = function (a) {
	return {$: 1, a: a};
};
var $author$project$Utils$Finished = function (a) {
	return {$: 2, a: a};
};
var $author$project$Utils$gqlErrorToString = function (e) {
	if (!e.$) {
		return 'Graphql error';
	} else {
		switch (e.a.$) {
			case 0:
				return 'Bad URL';
			case 1:
				var _v1 = e.a;
				return 'Timeout';
			case 2:
				var _v2 = e.a;
				return 'Network error';
			default:
				return 'Server error';
		}
	}
};
var $author$project$Asset$update = F2(
	function (msg, model) {
		if (!msg.$) {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		} else {
			switch (msg.a.$) {
				case 2:
					var e = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bc: $author$project$Utils$Error(
									$author$project$Utils$gqlErrorToString(e))
							}),
						$elm$core$Platform$Cmd$none);
				case 3:
					var data = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bc: $author$project$Utils$Finished(data)
							}),
						$elm$core$Platform$Cmd$none);
				default:
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			}
		}
	});
var $author$project$Connected$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var response = msg.a;
				var nftList = A2(
					$elm$core$Result$map,
					$elm$core$Dict$fromList,
					A2(
						$elm$core$Result$map,
						$elm$core$List$map(
							function (nft) {
								return _Utils_Tuple2(
									$cmditch$elm_bigint$BigInt$toHexString(nft.d6),
									A2(
										$author$project$Connected$NftModel,
										nft,
										$elm$core$Dict$fromList(
											A2(
												$elm$core$List$map,
												function (a) {
													return _Utils_Tuple2(
														$cmditch$elm_ethereum$Eth$Utils$addressToString(a.dq),
														A2($author$project$Asset$init, model.aO.d6, a).a);
												},
												nft.L))));
							}),
						response));
				var cmd = $elm$core$Platform$Cmd$batch(
					A2(
						$author$project$Utils$coalesce,
						_List_Nil,
						$elm$core$Result$toMaybe(
							A2(
								$elm$core$Result$map,
								A2($elm$core$List$foldl, $elm$core$Basics$append, _List_Nil),
								A2(
									$elm$core$Result$map,
									$elm$core$List$map(
										function (nft) {
											return A2(
												$elm$core$List$map,
												function (_v1) {
													var m = _v1.a;
													var c = _v1.b;
													return A2(
														$elm$core$Platform$Cmd$map,
														A2($author$project$Connected$AssetMsg, nft.d6, m.bt.dq),
														c);
												},
												A2(
													$elm$core$List$map,
													$author$project$Asset$init(model.aO.d6),
													nft.L));
										}),
									response)))));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							X: $elm$core$Maybe$Just(nftList)
						}),
					cmd);
			case 1:
				var subMsg = msg.a;
				return A2($author$project$Connected$liquidatorUpdate, subMsg, model);
			case 2:
				var nftId = msg.a;
				var token = msg.b;
				var subMsg = msg.c;
				var _v2 = model.X;
				if ((!_v2.$) && (!_v2.a.$)) {
					var nftById = _v2.a.a;
					var _v3 = A2(
						$elm$core$Dict$get,
						$cmditch$elm_bigint$BigInt$toHexString(nftId),
						nftById);
					if (!_v3.$) {
						var nModel = _v3.a;
						return A2(
							$author$project$Utils$coalesce,
							_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
							A2(
								$elm$core$Maybe$map,
								function (_v4) {
									var aModel = _v4.a;
									var aCmd = _v4.b;
									var nNft = A3(
										$elm$core$Dict$insert,
										$cmditch$elm_ethereum$Eth$Utils$addressToString(aModel.bt.dq),
										aModel,
										nModel.L);
									var newNfts = A3(
										$elm$core$Dict$insert,
										$cmditch$elm_bigint$BigInt$toHexString(nftId),
										_Utils_update(
											nModel,
											{L: nNft}),
										nftById);
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{
												X: $elm$core$Maybe$Just(
													$elm$core$Result$Ok(newNfts))
											}),
										A2(
											$elm$core$Platform$Cmd$map,
											A2($author$project$Connected$AssetMsg, nftId, token),
											aCmd));
								},
								A2(
									$elm$core$Maybe$map,
									$author$project$Asset$update(subMsg),
									A2(
										$elm$core$Dict$get,
										$cmditch$elm_ethereum$Eth$Utils$addressToString(token),
										nModel.L))));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Main$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 1:
				if (msg.a === 1) {
					var _v1 = msg.a;
					var subMsg = msg.b;
					var _v2 = model.B;
					if (!_v2.$) {
						var legoV1 = _v2.a.W;
						var puzzle = _v2.a._;
						var _v3 = A2($author$project$Connected$update, subMsg, legoV1);
						var innerModel = _v3.a;
						var innerCmd = _v3.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									B: $elm$core$Maybe$Just(
										{W: innerModel, _: puzzle})
								}),
							A2(
								$elm$core$Platform$Cmd$map,
								$author$project$Main$ConnectedMsg(1),
								innerCmd));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					var _v4 = msg.a;
					var subMsg = msg.b;
					var _v5 = model.B;
					if (!_v5.$) {
						var legoV1 = _v5.a.W;
						var puzzle = _v5.a._;
						var _v6 = A2($author$project$Connected$update, subMsg, puzzle);
						var innerModel = _v6.a;
						var innerCmd = _v6.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									B: $elm$core$Maybe$Just(
										{W: legoV1, _: innerModel})
								}),
							A2(
								$elm$core$Platform$Cmd$map,
								$author$project$Main$ConnectedMsg(0),
								innerCmd));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				}
			case 0:
				if (msg.a.$ === 1) {
					var _v7 = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{B: $elm$core$Maybe$Nothing}),
						$elm$core$Platform$Cmd$none);
				} else {
					var con = msg.a.a;
					var _v8 = _Utils_Tuple2(
						A2($author$project$Connected$init, con, 0),
						A2($author$project$Connected$init, con, 1));
					if ((!_v8.a.$) && (!_v8.b.$)) {
						var _v9 = _v8.a.a;
						var pModel = _v9.a;
						var pCmd = _v9.b;
						var _v10 = _v8.b.a;
						var lModel = _v10.a;
						var lCmd = _v10.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									B: $elm$core$Maybe$Just(
										{W: lModel, _: pModel})
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2(
										$elm$core$Platform$Cmd$map,
										$author$project$Main$ConnectedMsg(1),
										lCmd),
										A2(
										$elm$core$Platform$Cmd$map,
										$author$project$Main$ConnectedMsg(0),
										pCmd)
									])));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				}
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$div = _VirtualDom_node('div');
var $elm$html$Html$img = _VirtualDom_node('img');
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $elm$html$Html$h1 = _VirtualDom_node('h1');
var $elm$html$Html$i = _VirtualDom_node('i');
var $author$project$Constants$nameOf = function (networkId) {
	switch (networkId) {
		case 0:
			return 'BSC';
		case 1:
			return 'Avalanche';
		default:
			return 'Polygon';
	}
};
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $author$project$Connected$showError = function (e) {
	if (e.$ === 4) {
		var desc = e.a;
		return $elm$html$Html$text('Error while fetching NFT list: ' + desc);
	} else {
		return $elm$html$Html$text('Error while fetching NFT list');
	}
};
var $author$project$Utils$Blue = 0;
var $author$project$Utils$Grey = 2;
var $elm$html$Html$button = _VirtualDom_node('button');
var $author$project$Utils$btn = F2(
	function (color, attrs) {
		var cls = function () {
			switch (color) {
				case 0:
					return 'bg-mainblue text-white';
				case 1:
					return 'bg-darkblue text-white';
				default:
					return 'text-greytext hover:text-darkblue';
			}
		}();
		return $elm$html$Html$button(
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class(cls + ' border border-2 border-mainblue px-3 items-center focus:outline-none h-[42px] md:h-[45px] rounded-md justify-center font-light md:font-normal my-1 mx-2'),
				attrs));
	});
var $author$project$Constants$currencyOf = function (networkId) {
	switch (networkId) {
		case 0:
			return {
				dQ: $cmditch$elm_ethereum$Eth$Utils$unsafeToAddress('0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'),
				cN: 'BNB'
			};
		case 1:
			return {
				dQ: $cmditch$elm_ethereum$Eth$Utils$unsafeToAddress('0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7'),
				cN: 'Avax'
			};
		default:
			return {
				dQ: $cmditch$elm_ethereum$Eth$Utils$unsafeToAddress('0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270'),
				cN: 'MATIC'
			};
	}
};
var $elm$html$Html$h2 = _VirtualDom_node('h2');
var $elm$html$Html$hr = _VirtualDom_node('hr');
var $elm$html$Html$li = _VirtualDom_node('li');
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Liquidator$StartLiquidation = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Liquidator$start = $author$project$Liquidator$StartLiquidation;
var $author$project$Liquidator$None = {$: 4};
var $author$project$Liquidator$stateOf = F2(
	function (m, id) {
		return A2(
			$author$project$Utils$coalesce,
			$author$project$Liquidator$None,
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.b0;
				},
				A2(
					$elm$core$Dict$get,
					$cmditch$elm_bigint$BigInt$toHexString(id),
					m.a5)));
	});
var $elm$core$Basics$round = _Basics_round;
var $elm$core$List$sum = function (numbers) {
	return A3($elm$core$List$foldl, $elm$core$Basics$add, 0, numbers);
};
var $elm$core$String$toFloat = _String_toFloat;
var $author$project$Utils$toNumStr = F2(
	function (n, digits) {
		var full = $cmditch$elm_bigint$BigInt$toString(n);
		var len = $elm$core$String$length(full);
		return (_Utils_cmp(len, digits) > 0) ? (A2($elm$core$String$left, len - digits, full) + ('.' + A2(
			$elm$core$String$left,
			2,
			A2($elm$core$String$dropLeft, len - digits, full)))) : ('0.' + (A2($elm$core$String$repeat, digits - len, '0') + A2($elm$core$String$left, 4, full)));
	});
var $author$project$Utils$toNum = F2(
	function (n, digits) {
		return A2(
			$author$project$Utils$coalesce,
			0.0,
			$elm$core$String$toFloat(
				A2($author$project$Utils$toNumStr, n, digits)));
	});
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $author$project$Connected$totalQuote = function (_v0) {
	var nftList = _v0.X;
	if ((!nftList.$) && (!nftList.a.$)) {
		var list = nftList.a.a;
		var tot = $elm$core$String$fromFloat(
			function (v) {
				return $elm$core$Basics$round(v * 100) / 100.0;
			}(
				$elm$core$List$sum(
					A2(
						$elm$core$List$map,
						function (a) {
							var _v2 = a.bc;
							if ((_v2.$ === 2) && (!_v2.a.$)) {
								var i = _v2.a.a;
								return i.ex * A2($author$project$Utils$toNum, a.bt.ew, i.cn);
							} else {
								return 0;
							}
						},
						A3(
							$elm$core$List$foldl,
							$elm$core$Basics$append,
							_List_Nil,
							A2(
								$elm$core$List$map,
								$elm$core$Dict$values,
								A2(
									$elm$core$List$map,
									function ($) {
										return $.L;
									},
									$elm$core$Dict$values(list))))))));
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('👉 Value ≈ $ ' + tot)
				]));
	} else {
		return A2($elm$html$Html$div, _List_Nil, _List_Nil);
	}
};
var $elm$html$Html$b = _VirtualDom_node('b');
var $author$project$Asset$tokenLogo = function (logo) {
	if (!logo.$) {
		var url = logo.a;
		return A2(
			$elm$html$Html$img,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$src(url),
					$elm$html$Html$Attributes$class('w-5 h-5 inline mx-2')
				]),
			_List_Nil);
	} else {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('w-5 h-5 !border !border-darkblue !border-2 !text-darkblue rounded-full !bg-white !font-bold !text-lg !flex !items-center !justify-center')
				]),
			_List_Nil);
	}
};
var $author$project$Asset$view = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('text-left inline-block mx-3')
			]),
		_List_fromArray(
			[
				function () {
				var _v0 = model.bc;
				switch (_v0.$) {
					case 0:
						return $elm$html$Html$text('🔃 Loading token...');
					case 2:
						if (!_v0.a.$) {
							var t = _v0.a.a;
							return A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[
										$author$project$Asset$tokenLogo(t.cI),
										$elm$html$Html$text(
										A2($author$project$Utils$toNumStr, model.bt.ew, t.cn)),
										A2(
										$elm$html$Html$b,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('mx-2')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(t.cN)
											])),
										$elm$html$Html$text('(' + (t.dl + ')'))
									]));
						} else {
							var _v1 = _v0.a;
							return $elm$html$Html$text(
								'Unkown token' + $cmditch$elm_ethereum$Eth$Utils$addressToString(model.bt.dq));
						}
					default:
						var e = _v0.a;
						return $elm$html$Html$text(e);
				}
			}()
			]));
};
var $author$project$Connected$showNft = F2(
	function (model, nft) {
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('m-5')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h2,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('pt-5 pb-2')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							'NFT #' + $cmditch$elm_bigint$BigInt$toString(nft.bt.d6))
						])),
					A2($elm$html$Html$hr, _List_Nil, _List_Nil),
					A2(
					$elm$html$Html$div,
					_List_Nil,
					A2(
						$elm$core$List$map,
						function (a) {
							return A2(
								$elm$html$Html$map,
								A2($author$project$Connected$AssetMsg, nft.bt.d6, a.bt.dq),
								$author$project$Asset$view(a));
						},
						$elm$core$Dict$values(nft.L))),
					$author$project$Connected$totalQuote(model),
					function () {
					var _v0 = A2($author$project$Liquidator$stateOf, model.ax, nft.bt.d6);
					switch (_v0.$) {
						case 4:
							var cur = $author$project$Constants$currencyOf(model.aO.d6);
							return A3(
								$author$project$Utils$btn,
								0,
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick(
										$author$project$Connected$LiquidatorCmd(
											A2($author$project$Liquidator$start, nft.bt, cur.dQ)))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Liquidate to ' + cur.cN)
									]));
						case 0:
							return A3(
								$author$project$Utils$btn,
								2,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('🔃 Liquidating...')
									]));
						case 2:
							return A3(
								$author$project$Utils$btn,
								2,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('🔃 Transaction being mined...')
									]));
						case 1:
							var e = _v0.a;
							return A3(
								$author$project$Utils$btn,
								2,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('🚫 Liquidation failed: ' + e)
									]));
						default:
							var hash = _v0.a;
							return $elm$html$Html$text(
								'🎉 Liquidation successful: ' + $cmditch$elm_ethereum$Eth$Utils$txHashToString(hash));
					}
				}()
				]));
	});
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $author$project$Connected$showNftList = F2(
	function (model, nfts) {
		return A2(
			$elm$html$Html$ul,
			_List_Nil,
			A2(
				$elm$core$List$map,
				$author$project$Connected$showNft(model),
				nfts));
	});
var $author$project$Connected$view = function (model) {
	var name = function () {
		var _v1 = model.b8;
		if (!_v1) {
			return 'Nested Alpha';
		} else {
			return 'Nested Beta';
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h1,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						'My ' + (name + (' porfolios on ' + $author$project$Constants$nameOf(model.aO.d6))))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mt-3')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$i,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								'(account ' + ($cmditch$elm_ethereum$Eth$Utils$addressToString(model.bw) + ')'))
							]))
					])),
				function () {
				var _v0 = model.X;
				if (_v0.$ === 1) {
					return $elm$html$Html$text('Loading your porfolios...');
				} else {
					if (_v0.a.$ === 1) {
						var e = _v0.a.a;
						return $author$project$Connected$showError(e);
					} else {
						var nftList = _v0.a.a;
						var vals = $elm$core$Dict$values(nftList);
						return (!$elm$core$List$length(vals)) ? A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('m-8')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('You don\'t have any porfolio')
										]))
								])) : A2($author$project$Connected$showNftList, model, vals);
					}
				}
			}()
			]));
};
var $author$project$Main$viewLoggedOut = A2(
	$elm$html$Html$div,
	_List_Nil,
	_List_fromArray(
		[
			A2(
			$elm$html$Html$h1,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Burn your Beta porfolios')
				])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('m-8')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Connect Metamask on a supported network to continue')
				]))
		]));
var $author$project$Main$view = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('m-10 text-center')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src('nested.svg'),
						$elm$html$Html$Attributes$class('inline h-12')
					]),
				_List_Nil),
				function () {
				var _v0 = model.B;
				if (!_v0.$) {
					var puzzle = _v0.a._;
					var legoV1 = _v0.a.W;
					return A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$map,
								$author$project$Main$ConnectedMsg(0),
								$author$project$Connected$view(puzzle)),
								A2(
								$elm$html$Html$map,
								$author$project$Main$ConnectedMsg(1),
								$author$project$Connected$view(legoV1))
							]));
				} else {
					return $author$project$Main$viewLoggedOut;
				}
			}()
			]));
};
var $author$project$Main$main = $elm$browser$Browser$element(
	{d9: $author$project$Main$init, eL: $author$project$Main$subscriptions, eV: $author$project$Main$update, eX: $author$project$Main$view});
_Platform_export({'Main':{'init':$author$project$Main$main($elm$json$Json$Decode$int)(0)}});}(this));